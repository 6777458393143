import useFormatting from '@/hooks/useFormatting';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import { Button, Checkbox, Divider, Tooltip, useTheme } from '@mui/material';
import React, { FunctionComponent, useRef, useState } from 'react';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { PreOptimizationInfo } from '../hooks/useOptimizationWarnings';
import CampaignBulkCreateDropdownButton from './CampaignBulkCreateDropdownButton';
import CampaignBulkEditPopover from './CampaignBulkEditPopover';
import GroupsDropdownButton from './GroupsDropdownButton';
import { OptimizationParams } from './optimization/OptimizerConfig';

interface OptimizerSelectionActionsBarProps {
  optimizationParams: OptimizationParams;
  onOptimizationParamsChanged: (optimizationParams: OptimizationParams) => void;
  totalCampaignsCount: number;
  onOptimizeClicked: () => void;
  onDiscardClicked: () => void;
  selectedCampaigns: SelectedCampaignDTO[];
  setSelectedCampaigns: React.Dispatch<React.SetStateAction<SelectedCampaignDTO[]>>;
  preOptimizationInfo: PreOptimizationInfo;
}

const OptimizerSelectionActionsBar: FunctionComponent<OptimizerSelectionActionsBarProps> = ({
  selectedCampaigns,
  setSelectedCampaigns,
  totalCampaignsCount,
  onDiscardClicked,
  onOptimizeClicked,
  preOptimizationInfo,
}) => {
  const theme = useTheme();

  const { formatWithThousandsSeparator } = useFormatting();

  // Bulk Edit
  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isBulkEditPopoverOpen, setIsBulkEditPopoverOpen] = useState(false);
  const handleBulkEditPopoverClose = () => setIsBulkEditPopoverOpen(false);

  const onBulkEditClicked = () => {
    setIsBulkEditPopoverOpen(true);
  };

  const warningTooltipContent = preOptimizationInfo.warnings ? (
    <>
      {preOptimizationInfo.warnings.map((warning, index) => (
        <React.Fragment key={index}>
          {'\u24D8'} {warning}
          <br />
        </React.Fragment>
      ))}
    </>
  ) : null;

  const canOptimizeSomeCampaigns = preOptimizationInfo.stats.cantOptimizeCampaignsCount < selectedCampaigns.length;
  const optimizeButtonTooltip = canOptimizeSomeCampaigns
    ? 'Proceed to optimization configuration. No changes will be applied yet'
    : 'None of the selected campaigns can be optimized';

  return (
    <>
      <OppositeModeOverlayBar visible={selectedCampaigns.length > 0}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row text-sm font-bold items-center hover:cursor-pointer min-w-28">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedCampaigns.length)} of {formatWithThousandsSeparator(totalCampaignsCount)}
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-2 items-center">
            <Button
              ref={bulkEditButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={onBulkEditClicked}
              variant={isBulkEditPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AssignmentOutlinedIcon />}
            >
              Bulk Actions
            </Button>

            <CampaignBulkCreateDropdownButton selectedCampaigns={selectedCampaigns} />

            <GroupsDropdownButton selectedCampaigns={selectedCampaigns} setSelectedCampaigns={setSelectedCampaigns} />
          </div>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-4 whitespace-nowrap items-center">
            {preOptimizationInfo.warnings.length > 0 && (
              <Tooltip title={warningTooltipContent}>
                <span className="flex items-center font-medium">
                  <InfoIcon fontSize="small" style={{ marginRight: '6px', color: theme.palette.info.light }} />
                  {preOptimizationInfo.warnings.length} {preOptimizationInfo.warnings.length == 1 ? 'Notice' : 'Notices'}
                </span>
              </Tooltip>
            )}
            <Tooltip title={optimizeButtonTooltip}>
              <span>
                <Button onClick={onOptimizeClicked} color="primary" size="small" endIcon={<TuneIcon />} disabled={!canOptimizeSomeCampaigns}>
                  Optimize Bids
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </OppositeModeOverlayBar>

      <CampaignBulkEditPopover
        buttonRef={bulkEditButtonRef}
        isOpen={isBulkEditPopoverOpen}
        onClose={handleBulkEditPopoverClose}
        selectedCampaigns={selectedCampaigns}
      />
    </>
  );
};

export default OptimizerSelectionActionsBar;
