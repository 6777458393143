import { FormControlLabel, FormGroup, FormHelperText, Radio } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { METRICS } from './MetricsConfig';
import { MetricField } from './types/MetricField';

interface MetricsRadioListProps {
  availableMetrics: MetricField[];
  value: MetricField;
  onChange: (selectedMetric: MetricField) => void;
}

const MetricsRadioList: FunctionComponent<MetricsRadioListProps> = ({ value, onChange, availableMetrics }) => {
  const [showHelperText, setShowHelperText] = useState(false);

  const handleRadioChange = (metric: MetricField) => {
    setShowHelperText(false);
    onChange(metric);
  };

  const availableMetricConfigs = Object.values(METRICS).filter((config) => availableMetrics.includes(config.key));

  return (
    <FormGroup>
      <div className="grid grid-cols-2">
        {availableMetricConfigs.map((metric) => (
          <FormControlLabel
            key={metric.key}
            control={<Radio checked={value === metric.key} onChange={() => handleRadioChange(metric.key)} />}
            label={metric.title}
          />
        ))}
      </div>
      {showHelperText && <FormHelperText error>Error message if needed</FormHelperText>}
    </FormGroup>
  );
};

export default MetricsRadioList;
