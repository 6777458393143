import { TextField, Typography } from '@mui/material';
import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import EntityTypeSelect, { EntityType } from '../../forms/EntityTypeSelect';
import GroupByTimeSelect, { GroupByTimeSelectOption } from '../../forms/GroupByTimeSelect';
import { LineGraphWidgetConfiguration } from './LineGraphWidgetConfiguration';
import { CommonMetricField, CommonWithSellerMetricField, MetricField, SellerMetricField } from '@/components/metrics/types/MetricField';
import { Environment } from '@/config/Environment';
import MetricsRadioList from '@/components/metrics/MetricsRadioList';
import { cloneDeep } from 'lodash-es';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import LineGraphWidgetFilters from './LineGraphWidgetFilters';
import ProfileSelect from '../../forms/ProfileSelect';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';

interface LineGraphWidgetConfigurationFormProps {
  configuration: LineGraphWidgetConfiguration;
  id: string;
}

const LineGraphWidgetConfigurationForm: FunctionComponent<LineGraphWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);

  const [localConfiguration, setLocalConfiguration] = useState<LineGraphWidgetConfiguration>(cloneDeep(configuration));

  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.title = e.currentTarget.value;
      return newConfig;
    });
  };

  const handleGroupByTimeChange = (groupByTime: GroupByTimeSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.groupByTime = groupByTime.value;
      return newConfig;
    });
  };

  const handleSelectedMetricsChange = (selectedMetric: MetricField) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.selectedMetrics = [selectedMetric as CommonWithSellerMetricField];
      return newConfig;
    });
  };

  const handleEntityTypeChange = (entityType: EntityType | '') => {
    if (entityType === '') return;
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.entityType = entityType;
      return newConfig;
    });
  };

  const availableMetricKeys = [
    ...Object.values(CommonMetricField),
    ...(Environment.isDev() ? Object.values(SellerMetricField) : []),
  ] as MetricField[];

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  return (
    <>
      <TextField label="Name" value={localConfiguration.title} onChange={handleTitleChange} />

      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}

      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
        disabledEntityTypes={[EntityType.SEARCH_TERM, EntityType.PRODUCT]}
      />

      <div>
        <div className="text-sm">Time Grouping</div>
        <Typography variant="caption">How the data will be grouped on the x-axis</Typography>
        <div className="mt-1">
          <GroupByTimeSelect value={localConfiguration.groupByTime} onChange={handleGroupByTimeChange} />
        </div>
      </div>

      <div>
        <div className="text-sm">Metrics</div>
        <Typography variant="caption">Select the metrics to display</Typography>

        <MetricsRadioList
          availableMetrics={availableMetricKeys}
          value={localConfiguration.selectedMetrics[0]}
          onChange={handleSelectedMetricsChange}
        />
      </div>

      <LineGraphWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default LineGraphWidgetConfigurationForm;
