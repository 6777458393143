import { FunctionComponent, useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { IconButton } from '@mui/material';
import { SettingsRounded } from '@mui/icons-material';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';

interface DashboardWidgetConfigurationButtonProps {
  isMouseOnWidget: boolean;
}

const DashboardWidgetConfigurationButton: FunctionComponent<DashboardWidgetConfigurationButtonProps> = ({ isMouseOnWidget }) => {
  const { isConfiguring, setIsConfiguring, widgetId } = useContext(DashboardWidgetContext);
  const { setEditingWidgetId, editingWidgetId, isEditingDashboard } = useDashboardContextValue((context) => ({
    setEditingWidgetId: context.setEditingWidgetId,
    editingWidgetId: context.editingWidgetId,
    isEditingDashboard: context.isEditingDashboard,
  }));

  const isEditMode = isMouseOnWidget || isEditingDashboard || editingWidgetId === widgetId;

  return (
    isEditMode && (
      <div
        className={`absolute right-1 top-1 z-50  opacity-100`}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.stopPropagation();
          e.bubbles = false;

          // If clicked and it's the cogwheel of a different widget
          // Open the drawer for this widget
          if (editingWidgetId !== widgetId || !isConfiguring) {
            setIsConfiguring(true);
            setEditingWidgetId(widgetId ?? '');
          } else {
            setIsConfiguring(false);
            setEditingWidgetId('');
          }
        }}
      >
        <IconButton size="small" color="primary">
          <SettingsRounded></SettingsRounded>
        </IconButton>
      </div>
    )
  );
};

export default DashboardWidgetConfigurationButton;
