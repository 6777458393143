import AlGrid from '@/components/grid/AlGrid';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { useQueryClient } from '@tanstack/react-query';
import { ColDef, GridOptions, GridReadyEvent, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { dashboardService } from '../../api/dashboard/dashboard.service';
import { DASHBOARDS_QUERY_KEY } from '../../constants/query-keys';
import { DashboardModel } from '../../types/DashboardModel';
import ManageDashboardCollaboratorsModal from './ManageDashboardCollaboratorsModal';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import ChipArrayCellRenderer, { IChipArrayCellRendererParams, ChipArrayChip } from '@/components/grid/cells/ChipArrayCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import { GroupAdd, Delete, CopyAll } from '@mui/icons-material';
import { ProfileState } from '@/modules/users/types/ProfileState';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { generateDashboardDefaultColumnState } from '../../configuration/dashboards.default-column-state';

interface DashboardsTableProps {
  dashboards?: DashboardModel[];
  isLoading: boolean;
}

const DashboardsTable: FunctionComponent<DashboardsTableProps> = ({ dashboards, isLoading }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { user } = useUserContext();
  const [isManageCollaboratorsModalOpen, setIsManageCollaboratorsModalOpen] = useState(false);
  const [dashboardIdToManageCollaborators, setDashboardIdToManageCollaborators] = useState<number>();

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.DASHBOARDS_TABLE_COLUMN_STATE,
    generateDashboardDefaultColumnState(),
  );

  const customGridOptions: GridOptions<DashboardModel> = {
    reactiveCustomComponents: true,

    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
  };

  function isUserOwnerOfDashboard(userId?: number) {
    return userId === user?.id;
  }

  const [isLoadingDeleteDashboard, setIsLoadingDeleteDashboard] = useState(false);
  async function onDeleteDashboardClicked(dashboardId: number | undefined) {
    if (!dashboardId) return;

    setIsLoadingDeleteDashboard(true);
    const result = await dashboardService.delete(dashboardId);
    if (result.isSuccess) {
      toast.success('Dashboard deleted');

      queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEY],
      });
    }

    setIsLoadingDeleteDashboard(false);
  }

  function onEditCollaboratorsClicked(dashboard: DashboardModel | undefined) {
    if (!dashboard) return;

    setIsManageCollaboratorsModalOpen(true);
    setDashboardIdToManageCollaborators(dashboard.dto.id);
  }
  const columnDefs: ColDef<DashboardModel>[] = useMemo(() => {
    const colDefs: ColDef<DashboardModel>[] = [
      {
        colId: ColumnId.NAME,
        field: 'dto.name',
        headerName: 'Name',
        cellRenderer: LinkCallbackCellRenderer,
        cellRendererParams: (params: ICellRendererParams<DashboardModel>): ILinkCallbackCellRendererParams<DashboardModel> => {
          return {
            buttonText: params.data?.dto.name || '',
            tooltip: 'View this Dashboard',
            callback: (db) => navigate(`/dashboards/${db.dto.id}`),
          };
        },
      },
      {
        colId: ColumnId.WIDGETS,
        headerName: 'Widgets',
        valueGetter: (params: ValueGetterParams<DashboardModel>) => {
          return params.data?.dto.widgets?.length || 0;
        },
      },
      {
        colId: ColumnId.CURRENCY,
        field: 'dto.currency',
        headerName: 'Currency',
      },
      {
        colId: ColumnId.PROFILES,
        headerName: 'Profiles',
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<DashboardModel>): IChipArrayCellRendererParams => {
          let chipArrayChips: ChipArrayChip[] = [];
          if (params.data?.dto.profiles) {
            chipArrayChips = params.data.dto.profiles.map((profile) => {
              return {
                value: profile.profile_name + ' (' + profile.country_code + ')',
                color: profile.state === ProfileState.ACTIVE ? 'primary' : 'warning',
              };
            });
          }
          return {
            chipArrayChips: chipArrayChips,
            maxChipsToShow: 3,
          };
        },
        flex: 1,
      },
      {
        colId: ColumnId.OWNER,
        headerName: 'Owner',
        field: 'dto.user_name',
      },
      {
        colId: ColumnId.COLLABORATORS,
        headerName: 'Collaborators',
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<DashboardModel>): IChipArrayCellRendererParams => {
          let chipArrayChips: ChipArrayChip[] = [];
          if (params.data?.dto.collaborators) {
            chipArrayChips = params.data.dto.collaborators.map((collaborator) => {
              return {
                value: collaborator.name,
                color: 'secondary',
              };
            });
          }
          return {
            chipArrayChips: chipArrayChips,
            maxChipsToShow: 3,
          };
        },
        flex: 1,
      },
      {
        colId: ColumnId.ACTIONS,
        headerName: 'Actions',
        width: 255,
        pinned: 'right',
        cellRenderer: ButtonGroupCellRenderer,
        cellRendererParams: (params: ICellRendererParams<DashboardModel>): IButtonGroupCellRendererParams => {
          if (!isUserOwnerOfDashboard(params.data?.dto.user_id)) {
            return { buttons: [] };
          }

          return {
            buttons: [
              <RowActionButton
                color="default"
                key="edit"
                text="Collaborators"
                tooltipText={`Edit '${params.data?.dto.name}' Collaborators`}
                onClick={() => onEditCollaboratorsClicked(params.data)}
                icon={<GroupAdd />}
              ></RowActionButton>,

              <RowActionButton
                key="duplicate"
                text="Duplicate"
                color="default"
                tooltipText={`Duplicate '${params.data?.dto.name}' Dashboard`}
                onClick={() => {
                  onDuplicateDashboardClicked(params.data?.dto.id);
                }}
                icon={<CopyAll />}
              ></RowActionButton>,

              <RowActionButton
                key="delete"
                text="Delete"
                isLoadingText="Deleting..."
                color="red"
                isDisabled={isLoadingDeleteDashboard}
                tooltipText={`Delete '${params.data?.dto.name}'`}
                isLoading={isLoadingDeleteDashboard}
                onClick={() => onDeleteDashboardClicked(params.data?.dto.id)}
                icon={<Delete />}
              ></RowActionButton>,
            ],
          };
        },
      },
    ];

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, [isUserOwnerOfDashboard, navigate, onEditCollaboratorsClicked, onDeleteDashboardClicked]);

  const dashboardToManageCollaborators = dashboards?.find((db) => db.dto.id === dashboardIdToManageCollaborators);

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  const onGridReady = (params: GridReadyEvent<DashboardModel>) => {
    setColumnStateGridApi(params.api);
    setIsAutoSaveEnabled(true);
  };

  const onDuplicateDashboardClicked = async (dashboardId: number | undefined) => {
    if (!dashboardId) return;

    const result = await dashboardService.duplicateDashboard(dashboardId);
    if (result.isSuccess) {
      toast.success('Dashboard duplicated');

      queryClient.invalidateQueries({
        queryKey: [DASHBOARDS_QUERY_KEY],
      });
    }
  };

  return (
    <>
      <AlGrid
        rowData={dashboards}
        isLoading={isLoading}
        gridOptions={customGridOptions}
        colDefs={columnDefs}
        onGridReadyCallback={onGridReady}
      />
      {dashboardToManageCollaborators && (
        <ManageDashboardCollaboratorsModal
          isOpen={isManageCollaboratorsModalOpen}
          dashboard={dashboardToManageCollaborators}
          onClose={() => {
            setIsManageCollaboratorsModalOpen(false);
            setDashboardIdToManageCollaborators(undefined);
          }}
          onCollaboratorsUpdated={() => {
            queryClient.invalidateQueries({
              queryKey: [DASHBOARDS_QUERY_KEY],
            });
          }}
        />
      )}
    </>
  );
};
export default DashboardsTable;
