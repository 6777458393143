import { DateRange } from '@mui/x-date-pickers-pro';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import dayjs, { Dayjs } from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
dayjs.extend(dayOfYear);

export const getCalenderShortcutsItems = (minDate?: Dayjs): PickersShortcutsItem<DateRange<Dayjs>>[] => {
  const getValidMinDate = (desiredStartDate: Dayjs) => {
    return minDate && minDate.isAfter(desiredStartDate) ? minDate : desiredStartDate;
  };

  return [
    {
      label: 'Last 7 days',
      getValue: () => {
        const yesterday = dayjs().subtract(1, 'day');
        const startDate = getValidMinDate(yesterday.subtract(6, 'day'));
        return [startDate, yesterday];
      },
    },
    {
      label: 'Last 14 days',
      getValue: () => {
        const yesterday = dayjs().subtract(1, 'day');
        const startDate = getValidMinDate(yesterday.subtract(13, 'day'));
        return [startDate, yesterday];
      },
    },
    {
      label: 'Last 30 days',
      getValue: () => {
        const yesterday = dayjs().subtract(1, 'day');
        const startDate = getValidMinDate(yesterday.subtract(29, 'day'));
        return [startDate, yesterday];
      },
    },
    {
      label: 'Last 60 days',
      getValue: () => {
        const yesterday = dayjs().subtract(1, 'day');
        const startDate = getValidMinDate(yesterday.subtract(59, 'day'));
        return [startDate, yesterday];
      },
    },
    {
      label: 'Last 90 days',
      getValue: () => {
        const yesterday = dayjs().subtract(1, 'day');
        const startDate = getValidMinDate(yesterday.subtract(89, 'day'));
        return [startDate, yesterday];
      },
    },
    {
      label: 'Last Month',
      getValue: () => {
        const startOfLastMonth = dayjs().subtract(1, 'month').startOf('month');
        const endOfLastMonth = dayjs().subtract(1, 'month').endOf('month');
        const startDate = getValidMinDate(startOfLastMonth);
        return [startDate, endOfLastMonth];
      },
    },
    {
      label: 'Month-to-Date',
      getValue: () => {
        const today = dayjs();
        if (today.date() === 1) {
          return [today, today];
        }
        const startDate = getValidMinDate(today.startOf('month'));
        return [startDate, today.subtract(1, 'day')];
      },
    },
    {
      label: 'Year-to-Date',
      getValue: () => {
        const today = dayjs();
        if (today.dayOfYear() === 1) {
          return [today.startOf('year'), today];
        }
        const startDate = getValidMinDate(today.startOf('year'));
        return [startDate, today.subtract(1, 'day')];
      },
    },
    { label: 'Reset', getValue: () => [null, null] },
  ];
};

export const comparisonCalenderDefaultShortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  { label: 'Custom', getValue: () => [null, null] },
];
