import React, { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '@/router/router-paths';
import { PaywallModal } from '../components/PaywallModal';
import { VideoUrl } from '@/config/urls';

export interface ShowPaywallParams {
  message: string;
  videoUrl?: VideoUrl;
  delayInSeconds?: number;
}

interface PaywallContextProps {
  showPaywallWithDelay: (params: ShowPaywallParams) => void;
  closePaywall: () => void;
}

const PaywallContext = createContext<PaywallContextProps | undefined>(undefined);

export const PaywallProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [videoUrl, setVideoUrl] = useState<VideoUrl | undefined>(undefined);
  const navigate = useNavigate();

  const showPaywallWithDelay = useCallback(({ message, videoUrl = VideoUrl.PAYWALL_DEFAULT, delayInSeconds = 0 }: ShowPaywallParams) => {
    setMessage(message);
    setVideoUrl(videoUrl);

    const timer = setTimeout(() => {
      navigate(Routes.PROFILES);
      setIsOpen(true);
    }, delayInSeconds * 1000);

    return () => clearTimeout(timer);
  }, []);

  const closePaywall = useCallback(() => {
    setIsOpen(false);
  }, [navigate]);

  return (
    <PaywallContext.Provider value={{ showPaywallWithDelay, closePaywall }}>
      {children}
      <PaywallModal isOpen={isOpen} onClose={closePaywall} returnURLPath={Routes.PROFILES} videoUrl={videoUrl}>
        {message}
      </PaywallModal>
    </PaywallContext.Provider>
  );
};

export const usePaywall = (): PaywallContextProps => {
  const context = useContext(PaywallContext);
  if (!context) {
    throw new Error('usePaywall must be used within a PaywallProvider');
  }
  return context;
};
