import { AlMultiSelectOptionModel } from '@/components/filter-builder/models/AlMultiSelectOptionModel';
import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { FunctionComponent } from 'react';
import { TeamProfile } from '../../types/TeamProfile';

interface ProfileSelectProps {
  profiles: TeamProfile[];
  selectedProfileIds: string[];
  setSelectedProfileIds: (ids: string[]) => void;
}

const ProfileSelect: FunctionComponent<ProfileSelectProps> = ({ profiles, selectedProfileIds, setSelectedProfileIds }) => {
  const profileSelectOptions = profiles.map((profile) => {
    return {
      id: profile.profileId,
      name: profile.profileName,
    } as AlMultiSelectOptionModel;
  });

  return (
    <AlMultiSelect
      width={'100%'}
      options={profileSelectOptions}
      selectedOptionIds={selectedProfileIds?.length > 0 ? selectedProfileIds : profiles.map((profile) => profile.profileId)}
      setSelectedOptionIds={setSelectedProfileIds}
      label="Profiles"
      placeholderText="Select Profiles"
    />
  );
};

export default ProfileSelect;
