import { Routes } from '@/router/router-paths';
import { Check, DeleteSweep } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import { FunctionComponent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CampaignAdType, ChangeCampaignGroupDTO } from '../../../api/campaign/campaign-contracts';
import { CampaignGroupModel } from '../../../api/campaign/models/CampaignGroupModel';
import { SelectedCampaignDTO } from '../../../api/campaign/models/CampaignModel';

interface UpdateCampaignGroupsProps {
  selectedCampaigns: SelectedCampaignDTO[];
  existingGroups: CampaignGroupModel[];
  onClose: () => void;
  onCreateClicked: () => void;
  applyChanges: (changeGroups: ChangeCampaignGroupDTO[]) => void;
  isApplyPending: boolean;
}

export const UpdateCampaignGroups: FunctionComponent<UpdateCampaignGroupsProps> = ({
  selectedCampaigns,
  existingGroups,
  onClose,
  onCreateClicked,
  applyChanges,
  isApplyPending,
}) => {
  const [selectedOptimizationGroupId, setSelectedOptimizationGroupId] = useState<number | undefined>(undefined);
  const navigate = useNavigate();

  function onApplyClicked() {
    onClose();

    if (!selectedOptimizationGroupId) {
      // Should not happen
      Sentry.captureMessage(`UpdateGroups: onApplyClicked when no groups were selected. ${selectedOptimizationGroupId}`, 'info');
      return;
    }

    const changeGroups: ChangeCampaignGroupDTO[] = selectedCampaigns.map((campaign) => ({
      campaign_id: campaign.id,
      group_id: selectedOptimizationGroupId,
    }));

    applyChanges(changeGroups);
  }

  function onManageGroupsClicked() {
    navigate(Routes.CAMPAIGN_GROUPS);
  }

  function handleRemoveAll() {
    onClose();

    const changeGroups: ChangeCampaignGroupDTO[] = selectedCampaigns.map((campaign) => ({
      campaign_id: campaign.id,
      group_id: 0,
    }));

    applyChanges(changeGroups);
  }

  const getApplyButtonText = () => {
    if (selectedCampaigns.length == 0) {
      return 'No Campaigns Selected';
    } else if (!selectedOptimizationGroupId) {
      return 'No Group Selected';
    }

    return `Assign Group to ${selectedCampaigns.length} Campaign` + (selectedCampaigns.length > 1 ? 's' : '');
  };

  const campaignsWithAssignedGroupCount = selectedCampaigns.map((campaign) => campaign.groupId).filter((id) => id !== 0).length;
  const selectedCampaignsHaveMixedAdType = useMemo(() => {
    const hasSPCampaigns = selectedCampaigns.some((campaign) => campaign.adType === CampaignAdType.PRODUCTS);
    const hasSBCampaigns = selectedCampaigns.some((campaign) => campaign.adType === CampaignAdType.BRANDS);
    const hasSDCampaigns = selectedCampaigns.some((campaign) => campaign.adType === CampaignAdType.DISPLAY);

    return (hasSPCampaigns && hasSBCampaigns) || (hasSPCampaigns && hasSDCampaigns) || (hasSBCampaigns && hasSDCampaigns);
  }, [selectedCampaigns]);

  return (
    <>
      <DialogTitle className="text-md">Assign/Remove Optimization Groups</DialogTitle>
      <DialogContent>
        <>
          {selectedCampaigns.length > 0 && (
            <div className="flex flex-row items-center justify-between font-bold mb-2">
              <div>{selectedCampaigns.length} Campaigns Selected</div>
              {campaignsWithAssignedGroupCount > 0 && (
                <Button variant="text" startIcon={<DeleteSweep />} onClick={handleRemoveAll} color="error">
                  Remove Groups from {campaignsWithAssignedGroupCount} Campaigns
                </Button>
              )}
            </div>
          )}
        </>
        {selectedCampaignsHaveMixedAdType && (
          <Alert severity="info" className="mb-2">
            You cannot group campaigns with different ad types
          </Alert>
        )}
        {!selectedCampaignsHaveMixedAdType && existingGroups && existingGroups.length > 0 && selectedCampaigns.length > 0 && (
          <Autocomplete
            id="select-campaign-groups"
            disabled={selectedCampaignsHaveMixedAdType}
            defaultValue={undefined}
            onChange={(event, newValue) => {
              setSelectedOptimizationGroupId(newValue ? newValue.id : 0);
            }}
            options={existingGroups.filter((group) => group.adType === selectedCampaigns[0].adType)}
            getOptionLabel={(option) => option.name} // Do not truncate for accurate searching
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Group to Assign"
                slotProps={{
                  inputLabel: { shrink: true },
                }}
              />
            )}
          />
        )}
        {!selectedCampaignsHaveMixedAdType && (
          <div className="flex flex-row justify-between">
            <Button autoFocus variant="text" startIcon={<AddIcon />} onClick={onCreateClicked}>
              Create & Assign New Group
            </Button>
            <Button variant="text" startIcon={<AutoAwesomeMotionIcon />} onClick={onManageGroupsClicked}>
              Manage Groups
            </Button>
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant={selectedCampaignsHaveMixedAdType ? 'outlined' : 'text'}>
          Cancel
        </Button>

        {!selectedCampaignsHaveMixedAdType && (
          <LoadingButton
            onClick={onApplyClicked}
            loading={isApplyPending}
            loadingPosition="start"
            startIcon={<Check />}
            variant="contained"
            disabled={selectedCampaigns.length == 0 || existingGroups.length == 0 || selectedOptimizationGroupId === undefined}
          >
            <span>{getApplyButtonText()}</span>
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};
