import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { MetricWidgetConfiguration } from './MetricWidgetConfiguration';

import MetricWidgetConfigurationForm from './MetricWidgetConfigurationForm';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetProvider from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';

import useFormatting from '@/hooks/useFormatting';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import { Typography } from '@mui/material';
import { getColorClassBySentimentCurrentPrevious } from '@/types/colors.enum';

import { METRICS } from '@/components/metrics/MetricsConfig';
import { MetricWidgetDataDTO } from '@/modules/dashboards/api/dashboard/data/dashboard-data.contracts';
import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import DashboardWidgetFilterIndicator from '../../dashboard-widget/DashboardWidgetFilterIndicator';
import DashboardWidgetTopBar from '../../dashboard-widget/DashboardWidgetTopBar';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { EntityType } from '../../forms/EntityTypeSelect';
import { isEmpty } from 'lodash-es';

interface MetricWidgetProps extends IDashboardWidget<MetricWidgetConfiguration> {
  data?: MetricWidgetDataDTO;
  isFetchingDataForWidget?: boolean;
  configurationId: string; // TODO: is it needed?
}

const MetricWidget: FunctionComponent<MetricWidgetProps> = ({ configuration, id, data, isFetchingDataForWidget, configurationId }) => {
  const { getShortFormatterForMetricField, formatChangeInUnsignedPercentWithArrow } = useFormatting();

  const dashboardCurrency = useDashboardContextValue((context) => context.dashboard?.dto.currency);

  // Memoize configurations and formatters
  const config = useMemo(() => METRICS[configuration.metricField], [configuration.metricField]);
  const colorClass = useMemo(
    () => (config ? getColorClassBySentimentCurrentPrevious(config.sentimentDirection, data?.data[0] ?? 0, data?.data[1] ?? 0) : ''),
    [config, data, configuration.metricField],
  );
  const formattedChange = useMemo(
    () => formatChangeInUnsignedPercentWithArrow(data?.data[0] ?? 0, data?.data[1] ?? 0),
    [data, formatChangeInUnsignedPercentWithArrow, configuration.metricField],
  );

  const valueFormatter = useCallback(
    (value: number | undefined): string => {
      if (!config?.key) return '';
      return getShortFormatterForMetricField(config?.key)(value, {
        customCurrencyCode: dashboardCurrency,
      });
    },
    [config, getShortFormatterForMetricField, configuration.metricField],
  );

  const hasFilters =
    (!isEmpty(configuration.campaignFilters) &&
      (configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE)) ||
    (!isEmpty(configuration.targetsFilters) && configuration.entityType === EntityType.TARGET);

  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isFetchingDataForWidget} isConfigured={configuration.isConfigured}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget} key={configurationId}>
        <DashboardWidgetTopBar>
          <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
          <DashboardWidgetFilterIndicator visible={hasFilters && !isMouseOnWidget} widgetId={id} />
        </DashboardWidgetTopBar>
        <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>
        <div className="flex h-full flex-col text-left items-start justify-start px-2 pt-0.5  ">
          {data && (
            <>
              {' '}
              <div className="text-xl font-bold  ">{valueFormatter(data.data[0])}</div>
              <Typography className="whitespace-no-wrap flex justify-between w-full text-xs font-medium mt-1">
                <span className="self-start opacity-90">{valueFormatter(data.data[1])}</span>
                <span className={`self-end text-green ${colorClass}`}>{formattedChange}</span>
              </Typography>
            </>
          )}
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <MetricWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(MetricWidget, isBaseWidgetConfigurationEqual);
