import { ColumnId } from '@/components/grid/columns/columns.enum';
import { UnitType } from '@/components/metrics/MetricsConfig';
import {
  PerformanceMetricsDTO,
  PerformanceTargetEstimate,
  ProfileCommonMetricsDTO,
  ProfileSellerMetricsDTO,
} from '@/modules/profiles/api/profile.contracts';
import { ProfileCreateArguments } from '@/modules/profiles/types/ProfileModel';
import { AlGradient, getColorOnGradientByValue, gradientAbove1Good, gradientAround1Good, gradientBelow1Good } from '@/types/colors.enum';

export class PerformanceMetricsModel {
  public acos: PerformanceTargetEstimate;
  public acots: PerformanceTargetEstimate;
  public adSpend: PerformanceTargetEstimate;
  public adSales: PerformanceTargetEstimate;
  public totalSales: PerformanceTargetEstimate;

  constructor(dto: PerformanceMetricsDTO, isLowData: boolean) {
    // If metric value is null and lowData is true then
    this.acos = PerformanceMetricsModel.convertNullOrTargetEstimateToTargetEstimate(dto.acos, isLowData);
    this.acots = PerformanceMetricsModel.convertNullOrTargetEstimateToTargetEstimate(dto.acots, isLowData);
    this.adSpend = PerformanceMetricsModel.convertNullOrTargetEstimateToTargetEstimate(dto.ad_spend, isLowData);
    this.adSales = PerformanceMetricsModel.convertNullOrTargetEstimateToTargetEstimate(dto.ad_sales, isLowData);
    this.totalSales = PerformanceMetricsModel.convertNullOrTargetEstimateToTargetEstimate(dto.total_sales, isLowData);
  }

  static convertNullOrTargetEstimateToTargetEstimate(
    metric: PerformanceTargetEstimate | null,
    isLowData: boolean,
  ): PerformanceTargetEstimate {
    // Back returns null when no target
    // Target not set (no target, no estimate)
    if (metric == null) {
      return [null, null];
    }

    // If low data, back returns 0 as estimate, set it to null
    if (isLowData) {
      return [metric[0], null];
    }

    return metric;
  }

  public static calculateEstimateToTarget(targetEstimate: PerformanceTargetEstimate): number | null {
    if (targetEstimate[0] == null || targetEstimate[1] == null || targetEstimate[0] === 0) {
      return null;
    }
    return targetEstimate[1] / targetEstimate[0];
  }

  public static getUnitTypeForColId(colId: ColumnId | undefined): string {
    if (!PerformanceMetricsModel.isPerformanceMetricColId(colId))
      console.error('getUnitTypeForColId: ColId not valid performance metric col: ', colId);
    if (!colId) return UnitType.NONE;

    switch (colId) {
      case ColumnId.PERFORMANCE_ACOS:
        return UnitType.PERCENTAGE;
      case ColumnId.PERFORMANCE_ACOTS:
        return UnitType.PERCENTAGE;
      case ColumnId.PERFORMANCE_AD_SPEND:
        return UnitType.CURRENCY;
      case ColumnId.PERFORMANCE_AD_SALES:
        return UnitType.CURRENCY;
      case ColumnId.PERFORMANCE_TOTAL_SALES:
        return UnitType.CURRENCY;
      default:
        return UnitType.NONE;
    }
  }

  public static getIdealEstimateToTargetForMetric(metric: keyof PerformanceMetricsModel): number {
    const keyToIdealValue: Record<keyof PerformanceMetricsModel, number> = {
      acos: 0,
      acots: 0,
      adSpend: 1,
      adSales: Infinity,
      totalSales: Infinity,
    };
    return keyToIdealValue[metric];
  }

  public static isPerformanceMetricColId(colId: ColumnId | undefined): boolean {
    if (!colId) return false;

    // Use record to make sure all keys are used
    const fieldToColId: Record<keyof PerformanceMetricsModel, ColumnId> = {
      acos: ColumnId.PERFORMANCE_ACOS,
      acots: ColumnId.PERFORMANCE_ACOTS,
      adSpend: ColumnId.PERFORMANCE_AD_SPEND,
      adSales: ColumnId.PERFORMANCE_AD_SALES,
      totalSales: ColumnId.PERFORMANCE_TOTAL_SALES,
    };
    return Object.values(fieldToColId).includes(colId);
  }

  public static getPerformanceMetricColor(metric: ColumnId, value: number): string | null {
    const gradient = PerformanceMetricsModel.getGradientByColId(metric);
    return getColorOnGradientByValue(gradient, value);
  }

  private static getGradientByColId(colId: ColumnId): AlGradient {
    if (!PerformanceMetricsModel.isPerformanceMetricColId(colId))
      console.error('getGradientByColId: ColId not valid performance metric col: ', colId);

    switch (colId) {
      case ColumnId.PERFORMANCE_ACOS:
        return gradientBelow1Good;
      case ColumnId.PERFORMANCE_ACOTS:
        return gradientBelow1Good;
      case ColumnId.PERFORMANCE_AD_SPEND:
        return gradientAround1Good;
      case ColumnId.PERFORMANCE_AD_SALES:
        return gradientAbove1Good;
      case ColumnId.PERFORMANCE_TOTAL_SALES:
        return gradientAbove1Good;
      default:
        return gradientAround1Good;
    }
  }
}
export interface ProfileMetricsArguments extends ProfileCreateArguments {
  teamId: number;
  teamName: string;
  metrics: ProfileCommonMetricsDTO;
  sellerMetrics: ProfileSellerMetricsDTO;
  comparisonMissing: boolean;
  performanceLowData: boolean;
  performance: PerformanceMetricsDTO;
}
