import { useHelperComponents } from '@/hooks/useHelperComponents';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';

export interface IImageWithTitleCellRendererParams<T> {
  extractImageUrl: (data: T) => string;
  extractTitle: (data: T) => string;
  extractUrl?: (data: T) => string | undefined;
}

interface IImageWithTitleCellRendererProps<T> extends ICellRendererParams, IImageWithTitleCellRendererParams<T> {}

const ImageWithTitleCellRenderer: FunctionComponent<IImageWithTitleCellRendererProps<unknown>> = ({
  node,
  extractImageUrl,
  extractTitle,
  extractUrl,
}) => {
  const { getExternalLinkComponent } = useHelperComponents();

  if (node.group) {
    const childNodes = node.allLeafChildren;
    if (!childNodes || childNodes.length === 0) {
      return null;
    }
    const firstChildData = childNodes[0]?.data;
    const imageUrl = extractImageUrl(firstChildData);
    const title = extractTitle(firstChildData);

    return (
      <div className="flex items-center space-x-3 h-full">
        {imageUrl && <img src={imageUrl} alt={title} className="object-cover rounded-md" />}
        <p className="line-clamp-2 text-wrap leading-normal font-medium">{title}</p>
      </div>
    );
  }

  const imageUrl = extractImageUrl(node.data);
  const title = extractTitle(node.data);
  const url = extractUrl ? extractUrl(node.data) : undefined;

  return (
    <div className="flex items-center space-x-3 h-full">
      {imageUrl && <img src={imageUrl} alt={title} className="object-cover rounded-md" />}
      <p className="line-clamp-2 text-wrap leading-normal font-medium ">{url ? getExternalLinkComponent(url, title, true) : title}</p>
    </div>
  );
};

export default ImageWithTitleCellRenderer;
