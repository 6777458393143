import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { MetricField } from './types/MetricField';
import { METRICS } from './MetricsConfig';

interface MetricsCheckboxListProps {
  availableMetrics: MetricField[];
  selectedMetrics: MetricField[];
  onChange: (selectedMetrics: MetricField[]) => void;
  maxSelectedItems?: number;
}

const MetricsCheckboxList: FunctionComponent<MetricsCheckboxListProps> = ({
  availableMetrics,
  selectedMetrics,
  onChange,
  maxSelectedItems,
}) => {
  const [showHelperText, setShowHelperText] = useState(false);

  selectedMetrics = selectedMetrics.filter((metric) => availableMetrics.includes(metric));

  const handleCheckboxChange = (metric: MetricField) => {
    const isSelected = selectedMetrics.includes(metric);
    let updatedValue: MetricField[];

    if (isSelected) {
      updatedValue = selectedMetrics.filter((selectedMetric) => selectedMetric !== metric);
    } else {
      if (!maxSelectedItems || selectedMetrics.length < maxSelectedItems) {
        updatedValue = [...selectedMetrics, metric];
      } else {
        setShowHelperText(true);
        return;
      }
    }

    setShowHelperText(false);
    onChange(updatedValue);
  };

  if (!selectedMetrics) {
    selectedMetrics = [];
  }

  const availableMetricConfigs = Object.values(METRICS).filter((config) => availableMetrics.includes(config.key));

  return (
    <FormGroup>
      <div className="grid grid-cols-2">
        {availableMetricConfigs.map((metric) => (
          <FormControlLabel
            key={metric.key}
            control={<Checkbox checked={selectedMetrics.includes(metric.key)} onChange={() => handleCheckboxChange(metric.key)} />}
            label={metric.title}
          />
        ))}
      </div>
      {showHelperText && maxSelectedItems && <FormHelperText error>You can only select up to {maxSelectedItems} items.</FormHelperText>}
    </FormGroup>
  );
};

export default MetricsCheckboxList;
