import { ButtonBase } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem } from '../types/MenuItem';

interface SecondaryMenuItemProps {
  menuItem: MenuItem;
}

export const SecondaryMenuItem: FunctionComponent<SecondaryMenuItemProps> = ({ menuItem }) => {
  const [isRouteActive, setIsRouteActive] = useState(false);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  useEffect(() => {
    if (currentLocation.pathname) {
      setIsRouteActive(menuItem.activeRouteIds.some((r) => currentLocation.pathname.startsWith(r)));
    }
  }, [currentLocation.pathname, menuItem.activeRouteIds]);

  function navigateToPath() {
    navigate(menuItem.url);
  }
  return (
    <ButtonBase
      sx={{
        justifyContent: 'start',
      }}
      onClick={navigateToPath}
      component="div"
      className={`rounded-md px-2  py-1 hover:cursor-pointer
${isRouteActive ? 'text-primary-300 font-medium dark:text-primary-600' : 'hover:bg-slate-800 dark:hover:bg-gray-700'} `}
    >
      <span className="truncate">{menuItem.label}</span>
    </ButtonBase>
  );
};
