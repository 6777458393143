import { AlMultiSelectOptionModel } from '@/components/filter-builder/models/AlMultiSelectOptionModel';
import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import AlSelect from '@/components/form/AlSelect';
import { NegativeMatchType } from '@/modules/negative-targets/api/negative-targets-contracts';
import { Button, DialogActions, DialogContent, DialogTitle, Popover, SelectChangeEvent, TextField } from '@mui/material';
import { FunctionComponent, RefObject, useMemo, useState } from 'react';
import { KeywordHarvestingPreviewDataRow } from '../models/KeywordHarvestingPreviewDataRow';

export enum BidUpdateType {
  SET_BID_TO_AMOUNT = 'SET_BID_TO_AMOUNT',
  INCREASE_BID_BY_AMOUNT = 'INCREASE_BID_BY_AMOUNT',
  DECREASE_BID_BY_AMOUNT = 'DECREASE_BID_BY_AMOUNT',
  INCREASE_BID_BY_PERCENTAGE = 'INCREASE_BID_BY_PERCENTAGE',
  DECREASE_BID_BY_PERCENTAGE = 'DECREASE_BID_BY_PERCENTAGE',
  SET_NEGATIVES = 'SET_NEGATIVES',

  NO_CHANGE = 'NO_CHANGE',
}

export interface BidUpdateData {
  bidUpdateType: BidUpdateType;
  newBidValue: number;
  selectedNegatives: NegativeMatchType[];
}

const BID_UPDATE_OPTIONS = [
  { value: BidUpdateType.NO_CHANGE, label: 'No change' },
  { value: BidUpdateType.INCREASE_BID_BY_AMOUNT, label: 'Increase bid by ($)' },
  { value: BidUpdateType.INCREASE_BID_BY_PERCENTAGE, label: 'Increase bid by (%)' },
  { value: BidUpdateType.SET_BID_TO_AMOUNT, label: 'Set bid to ($)' },
  { value: BidUpdateType.DECREASE_BID_BY_AMOUNT, label: 'Decrease bid by ($)' },
  { value: BidUpdateType.DECREASE_BID_BY_PERCENTAGE, label: 'Decrease bid by (%)' },
  { value: BidUpdateType.SET_NEGATIVES, label: 'Set negatives' },
];

interface KeywordHarvestingBulkEditPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedItems: KeywordHarvestingPreviewDataRow[];
  isOpen: boolean;
  onClose: () => void;
  onApply: (updateData: BidUpdateData) => void;
}

const KeywordHarvestingBulkEditPopover: FunctionComponent<KeywordHarvestingBulkEditPopoverProps> = ({
  selectedItems,
  isOpen,
  onClose,
  onApply,
  buttonRef,
}) => {
  const [bidUpdateData, setBidUpdateData] = useState<BidUpdateData>({
    bidUpdateType: BidUpdateType.NO_CHANGE,
    newBidValue: 0,
    selectedNegatives: [],
  });

  // TODO: here and campaign mapping bulk edit: get rid of separate separate state and store in bidUpdateData. For this AlMultiSelect needs to be updated
  const [selectedNegatives, setSelectedNegatives] = useState<NegativeMatchType[]>([]);

  const handleBidUpdateTypeChange = (event: SelectChangeEvent<BidUpdateType>) => {
    setBidUpdateData({ ...bidUpdateData, bidUpdateType: event.target.value as BidUpdateType });
  };

  const [newBidValue, setNewBidValue] = useState<string>('');
  const onNewBidValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only numeric input with decimals
    if (!value || /^\d*\.?\d*$/.test(value)) {
      // Update your state or handle the change here
      setNewBidValue(value);
      setBidUpdateData({ ...bidUpdateData, newBidValue: parseFloat(value) });
    }
  };

  const handleApplyChanges = () => {
    onApply({
      ...bidUpdateData,
      selectedNegatives: selectedNegatives,
    });

    // Close popover
    onClose();
  };

  // NEGATIVES
  const NEGATIVES_OPTIONS = useMemo(
    () => [
      new AlMultiSelectOptionModel('Ad Group Negative Exact', NegativeMatchType.AD_GROUP_NEGATIVE_EXACT),
      new AlMultiSelectOptionModel('Ad Group Negative Phrase', NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE),
      new AlMultiSelectOptionModel('Ad Group Negative Product Target', NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET),
      new AlMultiSelectOptionModel('Campaign Negative Exact', NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT),
      new AlMultiSelectOptionModel('Campaign Negative Phrase', NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE),
      new AlMultiSelectOptionModel('Campaign Negative Product Target', NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET),
    ],
    [],
  );

  return (
    <Popover
      id={'keyword-harvesting-bulk-edit-popover'}
      open={isOpen}
      anchorEl={buttonRef.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      slotProps={{ paper: { style: { width: 500 } } }}
      aria-labelledby="edit-selection-popover-title"
    >
      <DialogTitle id="edit-selection-popover-title">Edit selected items</DialogTitle>

      <DialogContent className="min-w-[500px]">
        <div className="flex max-w-96 ">You have selected {selectedItems.length} items to edit.</div>
        <div className="flex flex-col gap-y-6 mt-8 mb-4 min-w-100 ">
          {selectedItems.length > 0 && (
            <div>
              <div className="mb-2 flex items-center font-medium text-sm leading-none pb-0 ">Edit Items</div>

              <div className="flex gap-x-4">
                <AlSelect
                  label={'Update action'}
                  value={bidUpdateData.bidUpdateType}
                  options={BID_UPDATE_OPTIONS}
                  onChange={handleBidUpdateTypeChange}
                  renderOption={(item) => item.label}
                  valueExtractor={(item) => item.value}
                />
                {[
                  BidUpdateType.SET_BID_TO_AMOUNT,
                  BidUpdateType.INCREASE_BID_BY_AMOUNT,
                  BidUpdateType.DECREASE_BID_BY_AMOUNT,
                  BidUpdateType.INCREASE_BID_BY_PERCENTAGE,
                  BidUpdateType.DECREASE_BID_BY_PERCENTAGE,
                ].includes(bidUpdateData.bidUpdateType) && (
                  <TextField
                    fullWidth
                    label={
                      bidUpdateData.bidUpdateType === BidUpdateType.SET_BID_TO_AMOUNT
                        ? 'New Bid ($)'
                        : bidUpdateData.bidUpdateType === BidUpdateType.INCREASE_BID_BY_AMOUNT ||
                            bidUpdateData.bidUpdateType === BidUpdateType.DECREASE_BID_BY_AMOUNT
                          ? 'Bid adjustment ($)'
                          : 'Bid adjustment (%) points'
                    }
                    value={newBidValue}
                    onChange={onNewBidValueChange}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <div className="text-sm flex flex-nowrap whitespace-nowrap">
                            {bidUpdateData.bidUpdateType === BidUpdateType.SET_BID_TO_AMOUNT ||
                            bidUpdateData.bidUpdateType === BidUpdateType.INCREASE_BID_BY_AMOUNT ||
                            bidUpdateData.bidUpdateType === BidUpdateType.DECREASE_BID_BY_AMOUNT
                              ? '$'
                              : '%'}
                          </div>
                        ),
                      },

                      htmlInput: { pattern: '[0-9]*' },
                    }}
                  />
                )}

                {bidUpdateData.bidUpdateType == BidUpdateType.SET_NEGATIVES && (
                  <AlMultiSelect<NegativeMatchType>
                    options={NEGATIVES_OPTIONS}
                    selectedOptionIds={selectedNegatives}
                    setSelectedOptionIds={setSelectedNegatives}
                    label="Negatives"
                    placeholderText="Select Negatives"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={handleApplyChanges}>Apply Changes</Button>
      </DialogActions>
    </Popover>
  );
};

export default KeywordHarvestingBulkEditPopover;
