import useFormatting from '@/hooks/useFormatting';
import { BUTTON_WIDTH } from '@/modules/application/pages/GettingStartedPage';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { teamService } from '../api/teams.service';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
const SYNC_DATE_FORMAT = 'ddd, MMM D, YYYY HH:mm';

interface PreferredTimePickerProps {
  onSuccess: () => void;
  isEmbeddedInTopBar?: boolean;
}

export const PreferredTimePicker: FunctionComponent<PreferredTimePickerProps> = ({ onSuccess, isEmbeddedInTopBar = false }) => {
  const { activeTeam } = useActiveTeamContext();
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const { formatDateAsTimeWithTimezoneNoSeconds } = useFormatting();

  const setPreferredTimeToUpdate = async (value: dayjs.Dayjs | null) => {
    if (!isNil(value)) {
      try {
        const res = await teamService.updatePreferredUpdateTime(value.toISOString());
        if (res.isSuccess) {
          // value is only time
          const hours = value.hour();
          const minutes = value.minute();
          const seconds = value.second();
          const milliseconds = value.millisecond();
          const newPreferredUpdateTime = dayjs().hour(hours).minute(minutes).second(seconds).millisecond(milliseconds);
          const lastDone = dayjs(activeTeam?.preferredLastDone);
          const diffInSeconds = newPreferredUpdateTime.diff(lastDone, 'second');

          let nextSync = newPreferredUpdateTime;
          // If selected time is less than 2 hours from last update or time is before now then +1 day
          if (diffInSeconds < 7200 || newPreferredUpdateTime.isBefore(dayjs())) {
            nextSync = newPreferredUpdateTime.add(1, 'day');
          }

          toast.success(`Daily data sync set to ${newPreferredUpdateTime.format('HH:mm')}. Next sync ${nextSync.format(SYNC_DATE_FORMAT)}`);
          if (onSuccess) {
            onSuccess();
          }
        } else {
          toast.error('Cannot update daily time to update');
        }
      } catch (error) {
        console.log(error);
        toast.error('Cannot update daily time to update');
      }
    }
  };

  const [isTimePickerVisible, setIsTimePickerVisible] = useState<boolean>(false);
  useEffect(() => {
    if (activeTeam?.preferredUpdateTime) {
      setIsTimePickerVisible(true);
    } else {
      setIsTimePickerVisible(false);
    }
  }, [activeTeam]);

  const onSetTimeButtonClicked = () => {
    setIsTimePickerOpen(true);
    setIsTimePickerVisible(true);
  };

  const dateStringPieces = formatDateAsTimeWithTimezoneNoSeconds(new Date().toString()).split(' ');
  let timeZoneName = '';
  if (dateStringPieces.length > 1) {
    timeZoneName = dateStringPieces[dateStringPieces.length - 1];
  }

  return (
    <>
      {activeTeam && (
        <Tooltip
          title={
            !isTimePickerOpen
              ? 'Displayed time is your local time. Set your reports to update ~30min before your day starts to ensure you have access to the latest data.'
              : ''
          }
        >
          <div className="flex items-center">
            {isTimePickerVisible && activeTeam ? (
              <TimePicker
                key={`preferred-time-picker-${isTimePickerOpen ? 'open' : 'closed'}`} // fixes flickering issue
                className="mt-1 "
                value={!isNil(activeTeam?.preferredUpdateTime) ? dayjs(activeTeam?.preferredUpdateTime) : null}
                open={isTimePickerOpen}
                onClose={() => setIsTimePickerOpen(false)}
                onOpen={() => setIsTimePickerOpen(true)}
                onAccept={(newValue) => setPreferredTimeToUpdate(newValue)}
                format="HH:mm"
                ampm={false}
                ampmInClock={false}
                slots={{
                  textField: (textFieldProps) => (
                    <TextField
                      {...textFieldProps}
                      onClick={() => {
                        setIsTimePickerOpen(true);
                      }}
                      slotProps={{
                        input: {
                          ...textFieldProps.InputProps,
                          className: 'text-gray-800 font-bold leading-4',
                          style: {
                            width: '180px',
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccessTimeIcon className="text-gray-800" fontSize="small" />
                              <Typography variant="body2" className="ml-2 text-gray-800 font-bold leading-4">
                                Sync
                              </Typography>
                            </InputAdornment>
                          ),

                          endAdornment: (
                            <InputAdornment position="end" className="ml-0 mr-0">
                              <Typography variant="body2" className="text-gray-800 font-bold leading-4">
                                {timeZoneName}
                              </Typography>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  ),
                }}
              />
            ) : (
              <Button
                startIcon={<ScheduleIcon />}
                color="primary"
                onClick={onSetTimeButtonClicked}
                style={{ width: BUTTON_WIDTH }}
                className={
                  isEmbeddedInTopBar
                    ? `h-9 flex cursor-pointer justify-center rounded-md p-2 font-semibold text-gray-850 bg-white hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700`
                    : ''
                }
              >
                Set Daily Sync
              </Button>
            )}
          </div>
        </Tooltip>
      )}
    </>
  );
};
