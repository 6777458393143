import { useUserContext } from '@/modules/users';
import { FunctionComponent } from 'react';
import { AdminModeToggleButton } from './AdminModeToggleButton';
import { AffiliateButton } from './AffiliateButton';
import { BugButton } from './BugButton';
import { ColorModeToggleButton } from './ColorModeToggleButton';
import { ConfigurationButton } from './ConfigurationButton';
import { LogOutButton } from './LogOutButton';
import { ManageProfilesButton } from './ManageProfilesButton';
import { ManageTeamsButton } from './ManageTeamsButton';

interface MainMenuBottomProps {
  isExpanded: boolean;
}

export const MainMenuBottom: FunctionComponent<MainMenuBottomProps> = ({ isExpanded }) => {
  const { user } = useUserContext();
  const isUserAdmin = user?.isAdmin;

  return (
    <div className="flex w-full flex-col items-center gap-y-2">
      {isExpanded && (
        <>
          <div className="flex flex-col w-full border-b border-gray-600 pb-2">
            <div>
              <BugButton isExpanded={isExpanded} />
            </div>
            {/* <div>
              <SupportButton isExpanded={isExpanded} />
            </div> */}
          </div>
          {isUserAdmin && (
            <div className="flex flex-col w-full border-b border-gray-600 pb-2">
              <ColorModeToggleButton isExpanded={isExpanded} />
              <AdminModeToggleButton isExpanded={isExpanded} />
            </div>
          )}
          <div className="flex flex-col w-full border-b border-gray-600 pb-2">
            <AffiliateButton isExpanded={isExpanded} />
            <ManageProfilesButton isExpanded={isExpanded} />
            <ManageTeamsButton isExpanded={isExpanded} />
            <ConfigurationButton isExpanded={isExpanded} />
          </div>
        </>
      )}
      <LogOutButton isExpanded={isExpanded} />
    </div>
  );
};
