import UnitToggleButton from '@/components/buttons/UnitToggleButton';
import InfoIconWithTooltip from '@/components/feedback/InfoIconWithTooltip';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import { ComparisonUnit } from '@/components/grid/types';
import { useTranslation } from '@/lib';
import { PageLayoutBody } from '@/modules/application';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import { Button, Dialog } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { FilterBuilderDialog } from '../../../../components/filter-builder/FilterBuilderDialog';
import { FilterChip } from '../../../../components/filter-builder/FilterChip';
import { AlFilterModel, createOptimizerFilters } from '../../../../components/filter-builder/models/AlFilterModel';
import { IdNameDTO } from '../../api/campaign/campaign-contracts';
import { campaignService, createCampaignGroupsQueryKey } from '../../api/campaign/campaign-service';
import { CampaignGroupModel, emptyUnAssignedCampaignGroup } from '../../api/campaign/models/CampaignGroupModel';
import { SelectedCampaignDTO } from '../../api/campaign/models/CampaignModel';
import OptimizerPreviewActionsBar from '../OptimizerPreviewActionsBar';
import OptimizationTable from './OptimizationTable';
import { OptimizationApplyData } from './OptimizerConfig';
import { OptimizationModel } from './models/OptimizationModel';
import { PreviewDataRow } from './models/PreviewDataRow';

interface OptimizationsPreviewModalProps {
  optimizationResults: OptimizationModel;
  isOpen: boolean;
  onClose: () => void;
  onApplyOptimization: () => void;
  optimizationPreviewSelection: OptimizationApplyData[];
  setOptimizationPreviewSelection: (optimizationApplyData: OptimizationApplyData[]) => void;
  selectedCampaigns: SelectedCampaignDTO[];
  filtersUsedForOptimizing: AlFilterModel[];
  onPreviewOptimizationsClicked: (filtersModified: AlFilterModel[]) => void;
}

export const OptimizationsPreviewModal: FunctionComponent<OptimizationsPreviewModalProps> = ({
  optimizationResults,
  isOpen,
  onClose,
  onApplyOptimization: onApplyChanges,
  optimizationPreviewSelection,
  setOptimizationPreviewSelection,
  selectedCampaigns,
  filtersUsedForOptimizing,
  onPreviewOptimizationsClicked,
}) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const [optimizationTableApi, setOptimizationTableApi] = useState<GridApi<PreviewDataRow> | null>(null);

  const { t } = useTranslation();

  const onApply = () => {
    setIsApplyLoading(true);
    onApplyChanges();
    closeAndClearFilters();
  };

  const closeAndClearFilters = () => {
    onClose();
    setIsApplyLoading(false);
    setFilters([]);
  };

  // FILTERS
  const [filters, setFilters] = useState<AlFilterModel[]>([]);
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false); // state to control the popover
  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const [visibleRowCount, setVisibleRowCount] = useState<number>(0);

  const onFiltersButtonClicked = () => {
    setIsFilterPopoverOpen((previousValue) => !previousValue);
  };

  const onFilterDeleteButtonClicked = (filter: AlFilterModel) => {
    setFilters((previousFilters) => previousFilters.filter((f) => f.key !== filter.key));
  };

  // Get unique ad group names
  const uniqueAdGroupNames = Array.from(
    new Set(optimizationResults.previewData.map((optimizationResult) => optimizationResult.adGroup).filter((adGroup) => adGroup !== '')),
  ).sort();

  const adGroups: IdNameDTO[] = uniqueAdGroupNames.map((adGroupName) => ({ id: adGroupName, name: adGroupName }));

  const { data: campaignGroups } = useQuery({
    queryKey: createCampaignGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading groups');
      }
    },
    select: (campaignGroups): CampaignGroupModel[] => {
      if (!campaignGroups.find((group) => group.id === 0)) {
        campaignGroups.unshift(emptyUnAssignedCampaignGroup);
      }

      return campaignGroups;
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const campaignIdsWithNames = useMemo(() => {
    return selectedCampaigns.map((campaign) => {
      return { id: campaign.id, name: campaign.name };
    });
  }, [selectedCampaigns]);

  const OPTIMIZER_FILTERS = createOptimizerFilters(adGroups, campaignGroups || [], campaignIdsWithNames || []);

  const onFiltersChanged = (filters: AlFilterModel[]) => {
    setFilters(filters);
  };

  function onOptimizationTableGridReady(api: GridApi<PreviewDataRow>) {
    setOptimizationTableApi(api);
  }

  // Comparison unit
  const [comparisonUnit, setComparisonUnit] = useState<ComparisonUnit>('percent');
  const handleComparisonUnitChange = (event: React.MouseEvent<HTMLElement>, newAlignment: ComparisonUnit) => {
    setComparisonUnit(newAlignment);
  };

  // Updating modal with new dates
  function setFilterValues(filters: AlFilterModel[]) {
    onPreviewOptimizationsClicked(filters);
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={closeAndClearFilters} fullWidth={true}>
      <PageLayoutBody suppressBottomPadding>
        <div className="flex w-full flex-row justify-between mt-4 mb-2">
          <div className="flex gap-x-4">
            <Button className="flex-shrink-0" onClick={closeAndClearFilters} variant="text" startIcon={<WestRoundedIcon />}>
              Back to Campaigns
            </Button>
            <Button
              variant="outlined"
              onClick={onFiltersButtonClicked}
              startIcon={<FilterListRoundedIcon />}
              ref={filtersButtonRef}
              className="mr-2 flex-shrink-0"
            >
              Adjust Filters
            </Button>

            <FilterBuilderDialog
              buttonRef={filtersButtonRef}
              setIsOpen={setIsFilterPopoverOpen}
              isOpen={isFilterPopoverOpen}
              appliedFilters={filters}
              onFiltersChanged={onFiltersChanged}
              availableFilters={OPTIMIZER_FILTERS}
              defaultFilterKey={FilterKey.CAMPAIGN_ID}
            />

            <div className="flex flex-row flex-wrap gap-2">
              {filters
                .filter((f) => f.isFilterBuilderFilter)
                .map((filter, index) => {
                  return (
                    <FilterChip
                      key={filter.key + index}
                      filter={filter}
                      handleClick={onFiltersButtonClicked}
                      handleDelete={onFilterDeleteButtonClicked}
                    />
                  );
                })}
            </div>
          </div>
          <div className="flex text-sm gap-8 items-center">
            <InfoIconWithTooltip text={t('optimizer_page.optimization_table_disclamer')} />
            <UnitToggleButton handleComparisonUnitChange={handleComparisonUnitChange} comparisonUnit={comparisonUnit} />
            <DateRangeButton
              title="Optimization Range"
              filters={filtersUsedForOptimizing}
              setFilterValues={setFilterValues}
              tooltip="Date range used to calculate bid optimizations"
              hideComparison
            />
          </div>
        </div>

        <OptimizationTable
          externalFilters={filters}
          optimizationResults={optimizationResults}
          setOptimizationPreviewSelection={setOptimizationPreviewSelection}
          setVisibleRowCount={setVisibleRowCount}
          onInternalGridReady={onOptimizationTableGridReady}
          comparisonUnit={comparisonUnit}
        />

        <OptimizerPreviewActionsBar
          optimizationPreviewSelection={optimizationPreviewSelection}
          visibleRowCount={visibleRowCount}
          onApply={onApply}
          isApplyLoading={isApplyLoading}
          optimizationResults={optimizationResults}
          optimizationTableApi={optimizationTableApi}
          setOptimizationPreviewSelection={setOptimizationPreviewSelection}
        />
      </PageLayoutBody>
    </Dialog>
  );
};
