import { SortByUnit } from '@/components/buttons/SortByToggleButton';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FormatterType, GridToggles } from '@/components/grid/types';
import { MetricSelectionColorIndex } from '@/components/metrics/MetricsConfig';
import { PropsWithChildren } from 'react';

// INTERFACES
export interface ContextWithFilters {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
  setFilterValue: (filter: AlFilterModel) => void;
  setFilterValues: (filters: AlFilterModel[]) => void;
}

interface ContextWithMetricSelection {
  visibleMetrics: MetricSelectionColorIndex[];
  setVisibleMetrics: React.Dispatch<React.SetStateAction<MetricSelectionColorIndex[]>>;
}

export interface ContextWithGridToggles {
  setGridToggles: React.Dispatch<React.SetStateAction<GridToggles>>;
  gridToggles: GridToggles;
}

export interface MetricsGraphTablePageContext extends ContextWithFilters, ContextWithMetricSelection, ContextWithGridToggles {}

// DEFAULT VALUES
// ContextWithFilters default values
export const defaultFiltersContext: ContextWithFilters = {
  filters: [],
  setFilters: () => {},
  setFilterValue: () => {},
  setFilterValues: () => {},
};

// ContextWithMetricSelection default values
const defaultMetricSelectionContext: ContextWithMetricSelection = {
  visibleMetrics: [],
  setVisibleMetrics: () => {},
};

// ContextWithTableToggles default values
export const DEFAULT_GRID_TOGGLES: GridToggles = {
  comparisonUnit: 'nominal',
  sortByUnit: SortByUnit.Count,
  formatterType: FormatterType.LONG,
};

export const defaultTableTogglesContext: ContextWithGridToggles = {
  gridToggles: DEFAULT_GRID_TOGGLES,
  setGridToggles: () => {},
};

// Combined
export const defaultMetricsGraphTablePageContext: MetricsGraphTablePageContext = {
  ...defaultFiltersContext,
  ...defaultMetricSelectionContext,
  ...defaultTableTogglesContext,
};

// PROPS
export interface FiltersPropsWithChildren extends PropsWithChildren {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
}
