import { createAdvertisedProductFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useDataGroups } from '@/hooks/useDataGroups';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { campaignService, createAdGroupsQueryKey, createCampaignIdsQueryKey } from '@/modules/optimizer/api/campaign/campaign-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { toast } from 'react-toastify';

const useAvailableAdvertisedProductFilters = () => {
  const { dataGroupTypeToDataGroupMap } = useDataGroups([DataGroupType.PRODUCT]);
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const {
    data: adGroups,
    isLoading: isLoadingAdGroups,
    error: errorAdGroups,
  } = useQuery({
    queryKey: createAdGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getAdGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading ad groups');
        return []; // Return empty array to prevent crashes
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const {
    data: campaigns,
    isLoading: isLoadingCampaigns,
    error: errorCampaigns,
  } = useQuery({
    queryKey: createCampaignIdsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getNonArchivedCampaignIds();
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading campaigns');
        return []; // Return empty array to prevent crashes
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const availableAdvertisedProductFilters = createAdvertisedProductFilters(
    adGroups || [],
    dataGroupTypeToDataGroupMap[DataGroupType.PRODUCT] ?? [],
    campaigns ?? [],
    activeProfile?.isSeller ?? false,
  );

  const availableAdvertisedProductFilterBuilderFilters = availableAdvertisedProductFilters.filter((filter) => filter.isFilterBuilderFilter);

  return {
    availableAdvertisedProductFilters,
    availableAdvertisedProductFilterBuilderFilters,
    isLoading: isLoadingAdGroups || isLoadingCampaigns, // TODO: also include data groups?
    error: errorAdGroups || errorCampaigns, // TODO: also include data groups?
  };
};

export default useAvailableAdvertisedProductFilters;
