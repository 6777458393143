import { sleep } from '@/lib/api/api-utils';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { planService } from '@/modules/plans/api/plan.service';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';

import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import {
  createProPlan,
  FREE_PLAN,
  LEGACY_PRO_PLAN,
  PRO_BRANDS_250_PLAN,
  PRO_PROFILES_120_PLAN,
  PRO_PROFILES_250_PLAN,
  PRO_PROFILES_80_PLAN,
} from '@/modules/plans/configuration/plans';
import { PlanType } from '@/modules/plans/types/SubscriptionPlan';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import PricingCard from '../configuration/PricingCard';
import { LoadingButton } from '@mui/lab';

export const BillingSettingsPage: FunctionComponent = () => {
  const { activeTeam } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const { ModalComponent, handleOpenModal } = useConfirmationModal({
    questionText: (
      <p>
        Upgrading to AdLabs Pro gives access to Search Term Reports, Negative Targeting, and also includes all future releases (keyword
        harvesting, dayparting, organic sales, etc.).
        <br />
        <br />
        Sign up today to get <b>50% off for life</b>!
        <br />
        <br />
        You cannot downgrade to AdLabs Legacy after upgrading.
      </p>
    ),

    onConfirm: onUpgradeFromLegacyPlanClicked,
    questionTitle: 'Upgrade to the New Pro Plan',
    confirmButtonText: 'Upgrade',
    cancelButtonText: 'Cancel',
  });

  async function onUpgradeFromLegacyPlanClicked() {
    try {
      setIsLoading(true);

      const upgradeFromLegacyResponse = await planService.upgradeFromLegacyPlan();
      if (upgradeFromLegacyResponse.isSuccess) {
        await refetchUser();
        toast.success('You have successfully upgraded to the new plan');
      } else {
        toast.error(`Something went wrong. Please try again later. ${upgradeFromLegacyResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  async function onDowngradeClicked() {
    try {
      setIsLoading(true);

      const downgradeResponse = await planService.getCancellationLinkForPlan(Routes.BILLING);
      if (downgradeResponse.isSuccess) {
        // Navigate to checkout page
        window.location.href = downgradeResponse.payload.session_url;
      } else {
        toast.error(`Something went wrong. Please try again later. ${downgradeResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  async function onUpgradeClicked(planToUpgradeTo: PlanType) {
    try {
      if (activeTeam?.isOnLegacyProfilesBasedProPlan) {
        handleOpenModal();
        return;
      }
      setIsLoading(true);

      const upgradeResponse = await planService.getCheckoutLinkForPlan(planToUpgradeTo, Routes.BILLING);
      if (upgradeResponse.isSuccess) {
        // Navigate to checkout page
        window.location.href = upgradeResponse.payload.session_url;
      } else {
        toast.error(`Something went wrong. Please try again later. ${upgradeResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  async function onViewInvoiceHistoryClicked() {
    await redirectToBillingPortal();
  }

  async function onManagePaymentMethodClicked() {
    await redirectToBillingPortal();
  }

  async function onManageBillingAddressClicked() {
    await redirectToBillingPortal();
  }

  async function redirectToBillingPortal() {
    try {
      setIsLoading(true);

      const billingPortalLinkResponse = await planService.getBillingLinkForTeam(Routes.BILLING);
      if (billingPortalLinkResponse.isSuccess) {
        // Navigate to billing portal
        window.location.href = billingPortalLinkResponse.payload.session_url;
      } else {
        toast.error(`Something went wrong. Please try again later. ${billingPortalLinkResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  async function onResubscribeClicked() {
    try {
      setIsLoading(true);

      const resubscribeResponse = await planService.cancelCancellation();
      if (resubscribeResponse.isSuccess) {
        await sleep(300); // Wait for cancellation to be processed
        await refetchUser();
        toast.success('Your subscription has been resubscribed');
      } else {
        toast.error(`Something went wrong. Please try again later. ${resubscribeResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  const onResolveButtonClicked = () => {
    redirectToBillingPortal();
  };

  const isTeamOnProfilesBasedPlan = activeTeam?.isOnProfilesBasedPlan;

  return (
    <PageLayout>
      <PageLayoutTopBar
        header="Manage your Subscription"
        actions={
          <div className="flex flex-row gap-2">
            <TeamSelect />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <section className="max-w-screen-lg mt-6">
          <div className="grid grid-cols-2 gap-4 justify-start">
            {/* Pricing Cards */}
            {activeTeam?.isPastDue ? (
              <div className="flex flex-col gap-y-4 p-6 bg-white rounded-md">
                <div className="text-2xl font-semibold text-black">
                  PRO - <span className="text-orange-600">Past due</span>
                </div>
                <div className="text-gray-700">
                  Please update your payment information to resolve the payment issue and continue using all the Pro features.
                </div>
                <LoadingButton variant="contained" className="" size="large" onClick={onResolveButtonClicked} loading={isLoading}>
                  <div className="py-1 ">{isLoading ? 'Redirecting...' : 'Resolve'}</div>
                </LoadingButton>
              </div>
            ) : (
              <>
                {activeTeam?.hasPriceLookupKey && activeTeam?.isProProfileBased80 ? (
                  <PricingCard
                    onUpgrade={onUpgradeClicked}
                    onDowngrade={onDowngradeClicked}
                    onResubscribe={onResubscribeClicked}
                    onAddPaymentMethod={onManagePaymentMethodClicked}
                    plan={PRO_PROFILES_80_PLAN}
                    isCurrentPlan={true}
                    renewDate={activeTeam?.stripeSubscriptionCurrentPeriodEnd}
                    isCancelled={activeTeam?.stripeSubscriptionCancelAtPeriodEnd}
                    trialPeriodEnd={activeTeam?.stripeSubscriptionTrialEnd}
                    isTrialConvertedToSubscriptionAtPeriodEnd={activeTeam?.isTrialConvertedToSubscriptionAtPeriodEnd}
                    isLoading={isLoading}
                  />
                ) : activeTeam?.hasPriceLookupKey && activeTeam?.isProBrandBased250 ? (
                  <PricingCard
                    onUpgrade={onUpgradeClicked}
                    onDowngrade={onDowngradeClicked}
                    onResubscribe={onResubscribeClicked}
                    onAddPaymentMethod={onManagePaymentMethodClicked}
                    plan={PRO_BRANDS_250_PLAN}
                    isCurrentPlan={true}
                    renewDate={activeTeam?.stripeSubscriptionCurrentPeriodEnd}
                    isCancelled={activeTeam?.stripeSubscriptionCancelAtPeriodEnd}
                    trialPeriodEnd={activeTeam?.stripeSubscriptionTrialEnd}
                    isTrialConvertedToSubscriptionAtPeriodEnd={activeTeam?.isTrialConvertedToSubscriptionAtPeriodEnd}
                    isLoading={isLoading}
                  />
                ) : activeTeam?.hasPriceLookupKey && activeTeam?.isProProfileBased120 ? (
                  <PricingCard
                    onUpgrade={onUpgradeClicked}
                    onDowngrade={onDowngradeClicked}
                    onResubscribe={onResubscribeClicked}
                    onAddPaymentMethod={onManagePaymentMethodClicked}
                    plan={PRO_PROFILES_120_PLAN}
                    isCurrentPlan={true}
                    renewDate={activeTeam?.stripeSubscriptionCurrentPeriodEnd}
                    isCancelled={activeTeam?.stripeSubscriptionCancelAtPeriodEnd}
                    trialPeriodEnd={activeTeam?.stripeSubscriptionTrialEnd}
                    isTrialConvertedToSubscriptionAtPeriodEnd={activeTeam?.isTrialConvertedToSubscriptionAtPeriodEnd}
                    isLoading={isLoading}
                  />
                ) : activeTeam?.hasPriceLookupKey && activeTeam?.isProProfileBased250 ? (
                  <PricingCard
                    onUpgrade={onUpgradeClicked}
                    onDowngrade={onDowngradeClicked}
                    onResubscribe={onResubscribeClicked}
                    onAddPaymentMethod={onManagePaymentMethodClicked}
                    plan={PRO_PROFILES_250_PLAN}
                    isCurrentPlan={true}
                    renewDate={activeTeam?.stripeSubscriptionCurrentPeriodEnd}
                    isCancelled={activeTeam?.stripeSubscriptionCancelAtPeriodEnd}
                    trialPeriodEnd={activeTeam?.stripeSubscriptionTrialEnd}
                    isTrialConvertedToSubscriptionAtPeriodEnd={activeTeam?.isTrialConvertedToSubscriptionAtPeriodEnd}
                    isLoading={isLoading}
                  />
                ) : (
                  activeTeam?.isOnLegacyProfilesBasedProPlan && (
                    <PricingCard
                      onUpgrade={onUpgradeClicked}
                      onDowngrade={onDowngradeClicked}
                      onResubscribe={onResubscribeClicked}
                      onAddPaymentMethod={onManagePaymentMethodClicked}
                      plan={LEGACY_PRO_PLAN}
                      isCurrentPlan={activeTeam?.currentValidPlan === PlanType.PRO_PLAN}
                      renewDate={activeTeam?.stripeSubscriptionCurrentPeriodEnd}
                      isCancelled={activeTeam?.stripeSubscriptionCancelAtPeriodEnd}
                      trialPeriodEnd={activeTeam?.stripeSubscriptionTrialEnd}
                      isTrialConvertedToSubscriptionAtPeriodEnd={activeTeam?.isTrialConvertedToSubscriptionAtPeriodEnd}
                      isLoading={isLoading}
                    />
                  )
                )}

                <PricingCard
                  onUpgrade={onUpgradeClicked}
                  onDowngrade={onDowngradeClicked}
                  onResubscribe={onResubscribeClicked}
                  onAddPaymentMethod={onManagePaymentMethodClicked}
                  plan={createProPlan({
                    adSpendPercentage: activeTeam?.actualAdSpendPercentagePaid ?? 1,
                    discountRate: ((2 - (activeTeam?.actualAdSpendPercentagePaid ?? 1)) / 2) * 100,
                    pricePerTeam: 40,
                  })}
                  isCurrentPlan={activeTeam?.currentValidPlan === PlanType.PRO_PLAN && !isTeamOnProfilesBasedPlan}
                  renewDate={isTeamOnProfilesBasedPlan ? undefined : activeTeam?.stripeSubscriptionCurrentPeriodEnd}
                  isCancelled={isTeamOnProfilesBasedPlan ? false : activeTeam?.stripeSubscriptionCancelAtPeriodEnd}
                  trialPeriodEnd={isTeamOnProfilesBasedPlan ? undefined : activeTeam?.stripeSubscriptionTrialEnd}
                  isTrialConvertedToSubscriptionAtPeriodEnd={
                    isTeamOnProfilesBasedPlan ? false : activeTeam?.isTrialConvertedToSubscriptionAtPeriodEnd
                  }
                  isLoading={isLoading}
                />
              </>
            )}
            <PricingCard
              onUpgrade={onUpgradeClicked}
              onDowngrade={onDowngradeClicked}
              plan={FREE_PLAN}
              isCurrentPlan={activeTeam?.currentValidPlan === PlanType.FREE_PLAN}
              isLoading={isLoading}
            />
          </div>
        </section>
        {activeTeam?.isCustomer && (
          <div>
            <div className="items-start mt-2 flex flex-col">
              <Button variant="text" onClick={onViewInvoiceHistoryClicked}>
                View Invoice History
              </Button>
              <Button variant="text" onClick={onManagePaymentMethodClicked}>
                Manage Payment Method
              </Button>
              <Button variant="text" onClick={onManageBillingAddressClicked}>
                Manage Billing Address
              </Button>
            </div>
          </div>
        )}
        {ModalComponent}
      </PageLayoutBody>
    </PageLayout>
  );
};
