import AlSelect from '@/components/form/AlSelect';
import { campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogTitle, Popover, SelectChangeEvent, TextField } from '@mui/material';
import { FunctionComponent, RefObject, useState } from 'react';
import { toast } from 'react-toastify';

enum TacosUpdateType {
  NO_CHANGE = 'NO_CHANGE',
  NOT_SET = 'NOT SET',
  SET_TACOS_PERCENTAGE = 'SET_TACOS_PERCENTAGE',
}

interface BulkEditCampaignGroupSelectionPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedItems: CampaignGroupModel[];
  isOpen: boolean;
  onClose: () => void;
  onApplySucceeded: () => void;
}

const BulkEditCampaignGroupSelectionPopover: FunctionComponent<BulkEditCampaignGroupSelectionPopoverProps> = ({
  buttonRef,
  selectedItems,
  isOpen,
  onClose,
  onApplySucceeded,
}) => {
  const PRIORITIZATION_OPTIONS = [
    { value: 'NO_CHANGE', label: 'No change' },
    { value: OptimizationPreset.NOT_SET, label: 'Not set' },
    { value: OptimizationPreset.BALANCED, label: 'Balanced' },
    { value: OptimizationPreset.INCREASE_SALES, label: 'Increase sales' },
    { value: OptimizationPreset.REDUCE_ACOS, label: 'Reduce ACOS' },
  ];
  const TACOS_OPTIONS = [
    { value: TacosUpdateType.NO_CHANGE, label: 'No change' },
    { value: TacosUpdateType.NOT_SET, label: 'Not set' },
    { value: TacosUpdateType.SET_TACOS_PERCENTAGE, label: 'Target ACOS (%)' },
  ];

  const [priority, setPriority] = useState<string>('NO_CHANGE');
  const [tacosUpdateType, setTacosUpdateType] = useState(TacosUpdateType.NO_CHANGE);
  const [newTacosValue, setNewTacosValue] = useState<string>('');
  const [isApplyInProgress, setIsApplyInProgress] = useState<boolean>(false);

  function onNewTacosValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setNewTacosValue(value);
    }
  }

  async function handleApplyChanges() {
    setIsApplyInProgress(true);
    const updatedCampaignGroups = selectedItems.map<CampaignGroupModel>((campaignGroup) => {
      if (tacosUpdateType === TacosUpdateType.SET_TACOS_PERCENTAGE) {
        campaignGroup.tacos = +newTacosValue / 100;
      } else if (tacosUpdateType === TacosUpdateType.NOT_SET) {
        campaignGroup.tacos = undefined; // Clear the TACOS value
      }

      return {
        ...campaignGroup,
        preset: priority === 'NO_CHANGE' ? campaignGroup.preset : (priority as OptimizationPreset),
      };
    });

    const applyResponse = await campaignService.updateGroups(updatedCampaignGroups);

    if (applyResponse.isSuccess === false) {
      setIsApplyInProgress(false);
      toast.error(applyResponse.message);
      onClose();
      return;
    }

    // Reset all fields
    setPriority('NO_CHANGE');
    setTacosUpdateType(TacosUpdateType.NO_CHANGE);
    setNewTacosValue('');

    setIsApplyInProgress(false);
    onClose();
    onApplySucceeded();
  }

  function handlePriorityChange(event: SelectChangeEvent<string>) {
    setPriority(event.target.value);
  }

  function onTargetAcosUpdateTypeChange(event: SelectChangeEvent<TacosUpdateType>) {
    setTacosUpdateType(event.target.value as TacosUpdateType);
  }

  return (
    <Popover
      id={'negative-targets-bulk-edit-popover'}
      open={isOpen}
      anchorEl={buttonRef.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      disablePortal={true} // Allow scrolling bg scrolling when popover is open
      slotProps={{ paper: { style: { width: 300 } } }}
    >
      <DialogTitle id="edit-selection-modal-title">Edit selected Groups</DialogTitle>

      <DialogContent>
        <div className="flex">
          You have selected {selectedItems.length} Group{selectedItems.length > 1 ? 's' : ''} to edit. Please select the fields you want to
          edit and enter the new values.
        </div>
        <div className="flex flex-col gap-y-6 mt-8 mb-4">
          <div className="flex gap-x-4">
            <AlSelect
              label={'Prioritization'}
              value={priority}
              options={PRIORITIZATION_OPTIONS}
              onChange={handlePriorityChange}
              renderOption={(item) => item.label}
              valueExtractor={(item) => item.value}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-6 mt-8 mb-4">
          <div className="flex gap-x-4">
            <AlSelect
              label={'Target ACOS'}
              value={tacosUpdateType}
              options={TACOS_OPTIONS}
              onChange={onTargetAcosUpdateTypeChange}
              renderOption={(item) => item.label}
              valueExtractor={(item) => item.value}
            />

            {tacosUpdateType == TacosUpdateType.SET_TACOS_PERCENTAGE && (
              <TextField
                fullWidth
                label={'New Target ACOS (%)'}
                value={newTacosValue}
                onChange={onNewTacosValueChange}
                slotProps={{
                  input: {
                    endAdornment: <div className="text-sm flex flex-nowrap whitespace-nowrap">%</div>,
                  },

                  htmlInput: { pattern: '[0-9]*' },
                }}
              />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isApplyInProgress}
          onClick={handleApplyChanges}
          disabled={priority === 'NO_CHANGE' && tacosUpdateType === TacosUpdateType.NO_CHANGE}
        >
          Apply Changes
        </LoadingButton>
      </DialogActions>
    </Popover>
  );
};

export default BulkEditCampaignGroupSelectionPopover;
