import { FunctionComponent, useState } from 'react';
import LoginLayout from '../layouts/LoginLayout';
import LoginLayoutBody from '../components/LoginLayoutBody';
import { TextField } from '@mui/material';
import { userService } from '@/modules/users';
import { useAuthUserContext } from '@/modules/auth';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useLogin } from '../hooks/useLogin';

const ImpersonationPage: FunctionComponent = () => {
  const [firebaseId, setFirebaseId] = useState('');
  const [errorText, setErrorText] = useState('');
  const { signInWithToken } = useAuthUserContext();
  const { authUser } = useAuthUserContext();
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);
  const { logIn } = useLogin();

  const onImpersonateClicked = async () => {
    if (!firebaseId) {
      setErrorText('Firebase ID is required');
      return;
    }

    await impersonateLogin(firebaseId);
  };

  async function impersonateLogin(firebaseIdToImpersonate: string) {
    try {
      setIsImpersonating(true);
      if (firebaseIdToImpersonate.length !== 28) {
        setErrorText(`Firebase ID must be 28 characters long. Currently ${firebaseIdToImpersonate.length} characters.`);
        return;
      }
      const userTokenResponse = await userService.getCustomTokenForFirebaseId(firebaseIdToImpersonate);
      if (!userTokenResponse.isSuccess) {
        setErrorText(userTokenResponse.message ?? 'Error getting user token');
        return;
      }

      const loggedInUser = await signInWithToken(userTokenResponse.payload);
      if (loggedInUser) {
        await logIn(loggedInUser.uid, true);
      }
    } catch (error) {
      toast.error('Error Impersonating');
      console.error(error);
    } finally {
      setIsImpersonating(false);
    }
  }

  async function onSkipClicked() {
    if (!authUser?.uid) {
      toast.error('Error loading user');
      return;
    }

    try {
      setIsSkipping(true);
      await impersonateLogin(authUser.uid);
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong when logging in. Please contact us if the problem persists.');
    } finally {
      setIsSkipping(false);
    }
  }

  return (
    <LoginLayout>
      <LoginLayoutBody>
        <div>Select Identity to Impersonate</div>
        <TextField
          label="Firebase ID"
          className="w-full max-w-md"
          variant="outlined"
          value={firebaseId}
          onChange={(e) => {
            setFirebaseId(e.target.value);
            setErrorText('');
          }}
          error={Boolean(errorText)}
          helperText={errorText}
        />
        <div className="flex gap-x-4">
          {/* allow skipping of impersonation */}
          <LoadingButton
            loading={isSkipping}
            disabled={isImpersonating}
            size="large"
            variant="outlined"
            color="primary"
            onClick={onSkipClicked}
          >
            Skip
          </LoadingButton>
          <LoadingButton
            loading={isImpersonating}
            disabled={isSkipping || !firebaseId}
            size="large"
            variant="contained"
            color="primary"
            onClick={onImpersonateClicked}
          >
            Impersonate
          </LoadingButton>
        </div>
      </LoginLayoutBody>
    </LoginLayout>
  );
};

export default ImpersonationPage;
