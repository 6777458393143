import { FunctionComponent } from 'react';
import { planService } from '../api/plan.service';
import { Routes } from '@/router/router-paths';
import { toast } from 'react-toastify';

interface TrialEndingBannerProps {
  daysBeforeTrialEnds: number;
}

const TrialEndingBanner: FunctionComponent<TrialEndingBannerProps> = ({ daysBeforeTrialEnds }) => {
  const onBannerTextClicked = () => {
    redirectToBillingPortal();
  };

  async function redirectToBillingPortal() {
    try {
      const billingPortalLinkResponse = await planService.getBillingLinkForTeam(Routes.BILLING);
      if (billingPortalLinkResponse.isSuccess) {
        // Navigate to billing portal
        window.location.href = billingPortalLinkResponse.payload.session_url;
      } else {
        toast.error(`Something went wrong. Please try again later. ${billingPortalLinkResponse.message}`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
    }
  }

  return (
    <div
      className="fixed top-0 left-1/2 transform -translate-x-1/2 w-full flex items-center justify-between py-1 bg-gray-800 text-white  
    "
    >
      <div className="pl-2 flex items-center justify-center text-center w-full font-bold ">
        <div className="text-orange-400 ">Your AdLabs Pro Trial ends in {daysBeforeTrialEnds} days.</div>

        <div className="ml-2 hover:underline cursor-pointer  " onClick={onBannerTextClicked}>
          {' '}
          Enter payment info to lock in 1% ad spend (50% OFF) for Life! →
        </div>
      </div>
    </div>
  );
};

export default TrialEndingBanner;
