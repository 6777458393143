import ExportGridButton from '@/components/buttons/ExportGridButton';
import FormatterToggleButton from '@/components/buttons/FormatterToggleButton';
import SortByToggleButton, { SortByUnit } from '@/components/buttons/SortByToggleButton';
import UnitToggleButton from '@/components/buttons/UnitToggleButton';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';
import { ComparisonUnit, FormatterType, GridToggles } from '../types';

interface GridToolsProps {
  gridToggles: GridToggles;
  setGridToggles: React.Dispatch<React.SetStateAction<GridToggles>>;
  gridApiRef: React.MutableRefObject<GridApi<unknown> | null>;
}

const GridTools: FunctionComponent<GridToolsProps> = ({ gridToggles, setGridToggles, gridApiRef }) => {
  const handleComparisonUnitChange = (event: React.MouseEvent<HTMLElement>, newValue: ComparisonUnit) => {
    setGridToggles((previous) => ({ ...previous, comparisonUnit: newValue }));
  };

  const handleSortChange = (newValue: SortByUnit) => {
    setGridToggles((previous) => ({ ...previous, sortByUnit: newValue }));
  };

  const handleFormatterChange = (newValue: FormatterType) => {
    setGridToggles((previous) => ({ ...previous, formatterType: newValue }));
  };
  return (
    <div className="absolute -bottom-[39px] right-2 z-10 flex gap-x-4 ">
      {gridToggles.comparisonUnit && (
        <UnitToggleButton handleComparisonUnitChange={handleComparisonUnitChange} comparisonUnit={gridToggles.comparisonUnit} />
      )}
      {gridToggles.sortByUnit && gridToggles.comparisonUnit && (
        <SortByToggleButton handleSortChange={handleSortChange} sortBy={gridToggles.sortByUnit} comparisonUnit={gridToggles.comparisonUnit} />
      )}
      {gridToggles.formatterType && (
        <FormatterToggleButton handleFormatterChange={handleFormatterChange} formatterType={gridToggles.formatterType} />
      )}
      <ExportGridButton gridApiRef={gridApiRef} />
    </div>
  );
};

export default GridTools;
