import { Button } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import OppositeModeHeaderActionBar from '@/components/OppositeModeHeaderActionBar';
import PopoverLikePopper from '@/components/PopoverLikePopper';
import { WIDGET_CATALOG } from '../widgets/widget-catalog';
import DashboardWidgetCatalogItem from '../widget-catalog/DashboardWidgetCatalogItem';
import { Add, ExitToApp } from '@mui/icons-material';

interface DashboardHeaderActionBarProps {
  onLeaveEditMode: () => void;
  isEditingDashboard: boolean;
  setPlaceholderSize: (width: number, height: number) => void;
}

const DashboardHeaderActionBar: FunctionComponent<DashboardHeaderActionBarProps> = ({
  onLeaveEditMode,
  isEditingDashboard,
  setPlaceholderSize,
}) => {
  const addWidgetAnchorEl = useRef<HTMLButtonElement | null>(null);
  const [isAddWidgetPopoverOpen, setIsAddWidgetPopoverOpen] = useState(false);

  const onAddWidgetPopoverClose = () => setIsAddWidgetPopoverOpen(false);

  function onAddWidgetClicked() {
    setIsAddWidgetPopoverOpen(true);
  }

  return (
    <>
      {isEditingDashboard && (
        <OppositeModeHeaderActionBar>
          <div className="w-full flex justify-between items-center">
            {/* Left content */}
            <div className="flex-1"></div>

            {/* Center content */}
            <div className="flex-none flex">
              <Button
                variant={isAddWidgetPopoverOpen ? 'contained' : 'outlined'}
                onClick={onAddWidgetClicked}
                ref={addWidgetAnchorEl}
                startIcon={<Add />}
              >
                Add a Widget
              </Button>
            </div>

            {/* Right content */}
            <div className="flex-1 flex justify-end pr-6">
              <Button variant="outlined" onClick={onLeaveEditMode} startIcon={<ExitToApp />}>
                Leave Edit Mode
              </Button>
            </div>
          </div>
        </OppositeModeHeaderActionBar>
      )}

      <PopoverLikePopper
        anchorEl={addWidgetAnchorEl.current}
        open={isAddWidgetPopoverOpen}
        onClose={onAddWidgetPopoverClose}
        transformOrigin="center top"
      >
        {/* Loop over widget catalog items */}
        <div className="flex flex-col gap-y-2 p-2">
          <div className="text-xs pr-4 pt-0.5 pb-1">Drag one of the widgets below onto the dashboard to add it</div>
          {WIDGET_CATALOG.map((widget) => (
            <div
              key={widget.id}
              draggable={true}
              className="select-none"
              onDragStart={(e) => {
                setIsAddWidgetPopoverOpen(false);
                e.dataTransfer.setData('text/plain', '');
                e.dataTransfer.setData('droppableWidget', JSON.stringify(widget));
                setPlaceholderSize(widget.layout.w, widget.layout.h);
              }}
            >
              <DashboardWidgetCatalogItem widget={widget} />
            </div>
          ))}
        </div>
      </PopoverLikePopper>
    </>
  );
};

export default DashboardHeaderActionBar;
