import {
  AdTypeReportStatusDTO,
  ProfileReportsStatusDTO,
  REPORT_STATUSES_ORDERED_BY_SEVERITY,
  ReportingStatusType,
} from '@/modules/profiles/api/profile.contracts';
import { QUERY_SELLER_CENTRAL_STATUS_REPORT_KEY, QUERY_STATUS_REPORT_KEY, profileService } from '@/modules/profiles/api/profile.service';
import { Routes } from '@/router/router-paths';
import * as Sentry from '@sentry/react';
import { useQueries, UseQueryResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isEmpty, isEqual, isNil } from 'lodash-es';
import { FunctionComponent, PropsWithChildren, createContext, useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useActiveTeamContext } from './ActiveTeamContext';
import { defaultProfileReportsStatusInfo, ProfileReportsStatus, SyncStatus, SyncStatusReason } from '@/modules/profiles/types/ProfileStatus';
import { sellerCentralService } from '@/modules/seller-central/api/seller-central-service';
import { SellerCentralReportsStatusDTO } from '@/modules/seller-central/api/seller-central-contracts';
import { useUserContext } from '@/modules/users/contexts/UserContext';
import { useTranslation } from '@/lib';

// Wrapper that adds profileId to result. Queries don't have queryKeys. "data" is unreliable because on error it's undefined
interface ReportStatusQueryResult {
  profileId: string;
  data: ProfileReportsStatusDTO | null;
}

interface SellerCentralReportStatusQueryResult {
  profileId: string;
  data: SellerCentralReportsStatusDTO | null;
}

interface ReportsContextType {
  updateProfileData(profileId: string): Promise<void>;
  profileSyncStatus: (profileId: string | undefined) => SyncStatus;
  activeProfileSyncStatus: SyncStatus;
  activeProfileReportsStatusInfo: ProfileReportsStatus;
  isActiveProfileReportsBeingProcessed: boolean;
  activeProfileHasProcessedReports: boolean;
  refetchProfileStatus: (profileId: string) => void;
  getReportStatusInfoByProfileId: (profileId: string | undefined) => ProfileReportsStatus | undefined;
  getSellerCentralReportStatusInfoByProfileId: (profileId: string | undefined) => ProfileReportsStatus | undefined;
  getCombinedUpdatedAtByProfileId: (profileId: string | undefined) => string;
}

// Default values
const ReportsContext = createContext<ReportsContextType>({
  updateProfileData: () => Promise.resolve(),
  profileSyncStatus: () => ({ canClickSync: true, reason: null, isLoading: false }),
  activeProfileSyncStatus: { canClickSync: true, reason: null, isLoading: false },
  activeProfileReportsStatusInfo: defaultProfileReportsStatusInfo,
  isActiveProfileReportsBeingProcessed: false,
  activeProfileHasProcessedReports: false,
  refetchProfileStatus: () => null,
  getReportStatusInfoByProfileId: () => undefined,
  getSellerCentralReportStatusInfoByProfileId: () => undefined,
  getCombinedUpdatedAtByProfileId: () => '',
});

export const ReportsProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const locationsWhereFetchAll = new Set([Routes.PROFILES]);
  const currentPage = useLocation().pathname;
  const isFetchReportsForAllProfiles = locationsWhereFetchAll.has(currentPage as Routes);
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const [localProfilesInLoadingState, setLocalProfilesInLoadingState] = useState<Set<string>>(new Set());

  function addProfileToLocalLoadingState(profileId: string) {
    setLocalProfilesInLoadingState((prevSet) => new Set(prevSet).add(profileId));
  }

  function removeProfileFromLocalLoadingState(profileId: string) {
    setLocalProfilesInLoadingState((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(profileId);
      return newSet;
    });
  }

  const [localProfilesSellerCentralInLoadingState, setLocalProfilesSellerCentralInLoadingState] = useState<Set<string>>(new Set());
  function addProfileToLocalSellerCentralLoadingState(profileId: string) {
    setLocalProfilesSellerCentralInLoadingState((prevSet) => new Set(prevSet).add(profileId));
  }

  function removeProfileFromLocalSellerCentralLoadingState(profileId: string) {
    setLocalProfilesSellerCentralInLoadingState((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(profileId);
      return newSet;
    });
  }

  const reportStatusQueries = useQueries({
    queries:
      activeTeam?.profiles?.map((profile) => ({
        queryKey: [QUERY_STATUS_REPORT_KEY, activeTeam, profile.id],
        queryFn: async (): Promise<ReportStatusQueryResult> => {
          const profileId = profile.id;
          const response = await profileService.getReportsStatus(activeTeam.id, profileId);

          removeProfileFromLocalLoadingState(profileId);

          const result: ReportStatusQueryResult = {
            profileId: profileId,
            data: null,
          };

          if (response.isSuccess) {
            result.data = response.payload;
          } else {
            console.log('Error fetching report status:', response);
          }

          return result;
        },

        refetchInterval: 5 * 60 * 1000,
        enabled: activeTeam && !isNil(activeTeam.profiles) && (isFetchReportsForAllProfiles || profile.id == activeProfile?.id),
      })) || [],
  });

  function getQueryByProfileID(profileId: string | null | undefined): UseQueryResult<ReportStatusQueryResult, Error> | undefined {
    return reportStatusQueries.find((query) => query.data?.profileId === profileId);
  }

  function refetchProfileStatus(profileId: string) {
    getQueryByProfileID(profileId)?.refetch();
  }

  const sellerCentralReportStatusQueries = useQueries({
    queries:
      activeTeam?.profiles?.map((profile) => ({
        queryKey: [QUERY_SELLER_CENTRAL_STATUS_REPORT_KEY, activeTeam, profile.id],
        queryFn: async (): Promise<SellerCentralReportStatusQueryResult> => {
          const profileId = profile.id;
          const response = await sellerCentralService.getStatus(activeTeam.id, profileId);

          removeProfileFromLocalSellerCentralLoadingState(profileId);

          const result: SellerCentralReportStatusQueryResult = {
            profileId: profileId,
            data: null,
          };

          if (response.isSuccess) {
            result.data = response.payload;
          } else {
            console.log('Error fetching seller central report status:', response);
          }

          return result;
        },

        refetchInterval: 5 * 60 * 1000,
        enabled: activeTeam && !isNil(activeTeam.profiles) && (isFetchReportsForAllProfiles || profile.id == activeProfile?.id),
      })) || [],
  });

  function getSellerCentralQueryByProfileID(
    profileId: string | null | undefined,
  ): UseQueryResult<SellerCentralReportStatusQueryResult, Error> | undefined {
    return sellerCentralReportStatusQueries.find((query) => query.data?.profileId === profileId);
  }

  // So that new references aren't created when there's no value change
  const profileReportStatusCache = useRef<Map<string, ProfileReportsStatus>>(new Map());
  function getReportStatusInfoByProfileId(profileId: string | undefined): ProfileReportsStatus {
    let result: ProfileReportsStatus = defaultProfileReportsStatusInfo;

    if (profileId) {
      const query = getQueryByProfileID(profileId);

      if (query) {
        const reportData = query.data?.data;
        const profileReportInfo = getProfileReportInfo(reportData || null);

        if (localProfilesInLoadingState.has(profileId) || query.isFetching) {
          result = {
            ...profileReportInfo,
            status: ReportingStatusType.WAITING_RESPONSE,
          };
        } else {
          result = profileReportInfo;
        }
      }
    }

    // Check against cache
    const cachedResult = profileReportStatusCache.current.get(profileId!);
    if (cachedResult && isEqual(result, cachedResult)) {
      return cachedResult;
    }

    profileReportStatusCache.current.set(profileId!, result);
    return result;
  }

  const activeProfileReportsStatusInfo = getReportStatusInfoByProfileId(activeProfile?.id);

  function profileSyncStatus(profileId: string | undefined): SyncStatus {
    const isInLocalLoadingState = profileId
      ? localProfilesInLoadingState.has(profileId) || localProfilesSellerCentralInLoadingState.has(profileId)
      : false;

    // -- SOMETHING IS WRONG --
    // if reports status cannot be fetched from API
    const query = getQueryByProfileID(profileId);
    if (query?.isFetched && isNil(query?.data?.data)) {
      // Should not happen, but if it does let click sync
      const msg = `ReportsContext: did not receive any profileReportsData after fetching for active team ${activeTeam?.id}, active profile: ${activeProfile?.id}`;
      Sentry.captureMessage(msg, 'info');
      console.log(msg);

      return {
        canClickSync: true,
        reason: null,
        isLoading: isInLocalLoadingState,
      };
    }

    const profileReportsStatus = getReportStatusInfoByProfileId(profileId);
    const isProcessing = [ReportingStatusType.NEVER, ReportingStatusType.WAITING_RESPONSE, ReportingStatusType.LOADING].includes(
      profileReportsStatus.status,
    );

    // -- NO REPORTS (WARMING UP) --

    // If the profile has no reports (warming up) or it is old and all reports have been deleted
    if (profileReportsStatus.status === ReportingStatusType.NEVER) {
      // If an existing profile has existed in our system (i.e., we have a schema)
      // but it is removed from a team and we haven't updated data for months
      // Now, lets say we delete all reports older than a month, which means this profile has no reports
      // Thus, frontend shows warming up, but there is no process that will update it
      const profileCreatedAt = activeTeam?.profiles.find((p) => p.id === profileId)?.createdAt;
      const isOldProfile = !isNil(profileCreatedAt) && dayjs(profileCreatedAt).isBefore(dayjs().subtract(1, 'hour'));

      return {
        canClickSync: isOldProfile && !isInLocalLoadingState,
        reason: isInLocalLoadingState ? SyncStatusReason.WAITING_RESPONSE : isProcessing ? SyncStatusReason.LOADING : null,
        isLoading: isProcessing || isInLocalLoadingState,
      };
    }

    // -- PROCESSING OR WAITING RESPONSE--
    if (isProcessing) {
      return {
        canClickSync: false,
        reason: isInLocalLoadingState ? SyncStatusReason.WAITING_RESPONSE : SyncStatusReason.LOADING,
        isLoading: true,
      };
    }

    if (profileReportsStatus.nextPossibleUpdate && dayjs(profileReportsStatus.nextPossibleUpdate).isAfter(dayjs())) {
      return {
        canClickSync: false,
        reason: SyncStatusReason.TOO_EARLY,
        isLoading: false,
      };
    }

    // -- PAYWALL --
    if (!isNil(profileReportsStatus.updatedAt)) {
      // When can't sync due plan restriction, still let click sync button to show paywall. PLAN_RESTRICTION has to be checked after sync is clicked
      if (!isNil(activeTeam) && !activeTeam.canSyncProfileByLastSync(dayjs(profileReportsStatus.updatedAt))) {
        return {
          canClickSync: activeTeam.subscriptionPlan.canAlwaysClickSync,
          reason: activeTeam.subscriptionPlan.canAlwaysClickSync ? SyncStatusReason.PLAN_RESTRICTION : SyncStatusReason.TOO_EARLY,
          isLoading: isProcessing || isInLocalLoadingState,
        };
      }
    }

    // -- ANYTHING ELSE --
    return {
      canClickSync: true,
      reason: null,
      isLoading: isProcessing || isInLocalLoadingState,
    };
  }

  async function updateProfileData(profileId: string): Promise<void> {
    try {
      const reportsPromises = [profileService.createAllReports(profileId)];
      addProfileToLocalLoadingState(profileId);

      const profile = user?.teams.flatMap((team) => team.profiles).find((profile) => profile.id === profileId);
      if (profile && profile.isSeller) {
        reportsPromises.push(sellerCentralService.createReport(profileId));
        addProfileToLocalSellerCentralLoadingState(profileId);
      }

      await Promise.all(reportsPromises);
    } catch (error) {
      console.error('Error updating profile data:', error);
      throw error;
    } finally {
      removeProfileFromLocalLoadingState(profileId);
      removeProfileFromLocalSellerCentralLoadingState(profileId);
    }
  }

  const profileSellerCentralReportStatusCache = useRef<Map<string, ProfileReportsStatus>>(new Map());
  function getSellerCentralReportStatusInfoByProfileId(profileId: string | undefined): ProfileReportsStatus {
    let result: ProfileReportsStatus = defaultProfileReportsStatusInfo;

    if (profileId) {
      const query = getSellerCentralQueryByProfileID(profileId);

      if (query) {
        const reportData = query.data?.data?.seller_central;

        if (localProfilesSellerCentralInLoadingState.has(profileId) || query.isFetching) {
          result = {
            status: ReportingStatusType.WAITING_RESPONSE,
            updatedAt: reportData?.updated_at,
            nextPossibleUpdate: '', // TODO
          };
        } else {
          result = {
            status: reportData?.status ?? ReportingStatusType.LOADING,
            updatedAt: reportData?.updated_at,
            nextPossibleUpdate: '', // TODO
          };
        }
      }
    }

    // Check against cache
    const cachedResult = profileSellerCentralReportStatusCache.current.get(profileId!);
    if (cachedResult && isEqual(result, cachedResult)) {
      return cachedResult;
    }

    profileSellerCentralReportStatusCache.current.set(profileId!, result);
    return result;
  }

  function getCombinedUpdatedAtByProfileId(profileId: string | undefined): string {
    const dataStatus = getReportStatusInfoByProfileId(profileId);
    let updatedAt = dataStatus?.updatedAt;

    if (dataStatus?.status == ReportingStatusType.NEVER) {
      return t(`team_profiles_page.labels.${ReportingStatusType.NEVER}`);
    }

    const sellerCentralDataStatus = getSellerCentralReportStatusInfoByProfileId(profileId);
    const sellerCentralUpdatedAt = sellerCentralDataStatus?.updatedAt;

    if (sellerCentralUpdatedAt && dayjs(sellerCentralUpdatedAt) < dayjs(updatedAt)) {
      updatedAt = sellerCentralUpdatedAt;
    }

    return isNil(updatedAt) ? '24h+ ago' : updatedAt;
  }

  return (
    <ReportsContext.Provider
      value={{
        updateProfileData,
        profileSyncStatus,
        activeProfileSyncStatus: profileSyncStatus(activeProfile?.id),
        activeProfileReportsStatusInfo,
        isActiveProfileReportsBeingProcessed: profileSyncStatus(activeProfile?.id).isLoading,
        activeProfileHasProcessedReports: activeProfileReportsStatusInfo.status != ReportingStatusType.NEVER,
        refetchProfileStatus,
        getReportStatusInfoByProfileId,
        getSellerCentralReportStatusInfoByProfileId,
        getCombinedUpdatedAtByProfileId,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export function useReportsContext(): ReportsContextType {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error('useReportsContext must be used within a ReportsProvider');
  }
  return context;
}

function getProfileReportInfo(profileReport: ProfileReportsStatusDTO | null): ProfileReportsStatus {
  if (!profileReport) {
    return {
      status: ReportingStatusType.ERROR,
      updatedAt: undefined,
      nextPossibleUpdate: undefined,
    };
  }
  let updatedAt: string | undefined = undefined;
  const statuses = new Set<ReportingStatusType>();
  // Loop over all top-level keys in ProfileReportsStatusDTO
  for (const key of Object.keys(profileReport) as Array<keyof ProfileReportsStatusDTO>) {
    // TODO: Refactor
    if (key == 'next_possible_update' || key == 'update_ongoing') {
      continue;
    }

    const adTypeReport = profileReport[key];

    for (const subKey of Object.keys(adTypeReport) as Array<keyof AdTypeReportStatusDTO>) {
      const reportingStatus = adTypeReport[subKey];

      if (!reportingStatus) {
        continue;
      }

      // Updated at should be based on oldest processed report
      if (!updatedAt || dayjs(reportingStatus.updated_at) < dayjs(updatedAt)) {
        updatedAt = reportingStatus.updated_at;
      }

      if (!isNil(reportingStatus.status) && !isEmpty(reportingStatus.status)) {
        statuses.add(reportingStatus.status);
      }
    }
  }

  let reportsCollectiveStatus = ReportingStatusType.COMPLETED;
  // Order collective status by severity
  for (const status of REPORT_STATUSES_ORDERED_BY_SEVERITY) {
    if (statuses.has(status)) {
      reportsCollectiveStatus = status;
      break;
    }
  }

  return {
    status: profileReport.update_ongoing ? ReportingStatusType.LOADING : reportsCollectiveStatus,
    updatedAt,
    nextPossibleUpdate: profileReport.next_possible_update,
  };
}
