import { IDashboardWidgetCatalogItem } from '../../../types/IDashboardWidgetCatalogItem';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { v4 } from 'uuid';
import { DashboardWidgetType } from '@/modules/dashboards/types/DashboardWidgetType';
import { HorizontalDividerWidgetConfiguration } from './HorizontalDividerWidgetConfiguration';

export const HORIZONTAL_DIVIDER_WIDGET_CATALOG_ITEM: IDashboardWidgetCatalogItem<HorizontalDividerWidgetConfiguration> = {
  title: 'Horizontal Divider',
  description: 'A horizontal divider with a title in the center.',
  icon: <HorizontalRuleIcon />,
  iconColor: 'yellow',
  id: v4(),
  type: DashboardWidgetType.HorizontalDividerWidget,
  configuration: new HorizontalDividerWidgetConfiguration(),
  layout: {
    h: 1,
    w: 4,
    i: v4(),
    x: 0,
    y: 0,
    maxH: 1,
    maxW: 99,
    minH: 1,
    minW: 2,
  },
};
