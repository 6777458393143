import { filtersService } from '@/components/filter-builder/api/filters-service';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import { getDefaultCampaignFilters } from '@/components/filter-builder/models/AlFilterModel';
import { GridToggles } from '@/components/grid/types';
import useMetricsInContext from '@/hooks/useMetricsInContext';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import {
  DEFAULT_GRID_TOGGLES,
  FiltersPropsWithChildren,
  MetricsGraphTablePageContext,
  defaultMetricsGraphTablePageContext,
} from '@/types/context-shared';
import { isEqual } from 'lodash-es';
import { FunctionComponent, createContext, useContext, useEffect, useState } from 'react';

export const OPTIMIZER_CONTEXT_KEY = 'optimizer-context';
const METRICS_USER_SETTING_KEY_BY_CONTEXT = UserSettingKey.VISIBLE_METRICS_OPTIMIZER;

const OptimizerContext = createContext<MetricsGraphTablePageContext>({
  ...defaultMetricsGraphTablePageContext,
});

export const OptimizerProvider: FunctionComponent<FiltersPropsWithChildren> = ({ filters, setFilters, children }) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();

  const { visibleMetrics, setVisibleMetrics } = useMetricsInContext(METRICS_USER_SETTING_KEY_BY_CONTEXT);

  const [gridToggles, setGridToggles] = useState<GridToggles>(DEFAULT_GRID_TOGGLES);

  const [isMounted, setIsMounted] = useState(false);

  const { setFilterValue, setFilterValues } = useFilters({ filters, setFilters });

  useEffect(() => {
    if (!isMounted) return;
    if (!activeProfile) return;

    filtersService.saveProfileFilters(OPTIMIZER_CONTEXT_KEY, activeProfile.id, filters);
  }, [filters]);

  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      const profileFilters = filtersService.loadProfileFilters(OPTIMIZER_CONTEXT_KEY, activeProfile?.id, getDefaultCampaignFilters());

      if (!isEqual(profileFilters, filters)) {
        setFilters(profileFilters);
      }
    }
  }, [activeProfileIdChange]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <OptimizerContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
        setFilterValues,
        visibleMetrics,
        setVisibleMetrics,
        gridToggles,
        setGridToggles,
      }}
    >
      {children}
    </OptimizerContext.Provider>
  );
};

export const useOptimizerContext = (): MetricsGraphTablePageContext => {
  const context = useContext(OptimizerContext);
  if (!context) {
    throw new Error('useOptimizerContext must be used within a OptimizerProvider');
  }
  return context;
};
