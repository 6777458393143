import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import ChangePercentageCellRenderer from '@/components/grid/cells/ChangePercentageCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import ChangePercentageHeaderRenderer, {
  IChangePercentageHeaderRendererProps,
} from '@/components/grid/headers/ChangePercentageHeaderRenderer';
import useAggregators from '@/components/grid/hooks/useAggregators';
import useColDefsFunctions from '@/components/grid/hooks/useColDefsFunctions';
import { ComparisonUnit, ExpandedGridContext } from '@/components/grid/types';
import { getMetricConfigByColId } from '@/components/metrics/MetricsConfig';
import { CommonWithSellerMetricField, MetricAggregates } from '@/components/metrics/types/MetricField';
import useFormatting from '@/hooks/useFormatting';
import { TableWidgetDataDTO, TableWidgetDataRowDTO, WidgetDataDTO } from '@/modules/dashboards/api/dashboard/data/dashboard-data.contracts';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { CampaignsGroupedByAdTypeRow } from '@/modules/dashboards/hooks/useCampaignCalculations';
import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import * as Sentry from '@sentry/react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { ColDef, GridApi, GridOptions, IHeaderParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { FunctionComponent, memo, useEffect, useMemo, useRef, useState } from 'react';
import { IDashboardWidget } from '../../../types/IDashboardWidget';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetProvider from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetFilterIndicator from '../../dashboard-widget/DashboardWidgetFilterIndicator';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import DashboardWidgetTopBar from '../../dashboard-widget/DashboardWidgetTopBar';
import { EntityType } from '../../forms/EntityTypeSelect';
import { TABLE_METRIC_COLUMNS, TableWidgetConfiguration } from './TableWidgetConfiguration';
import TableWidgetConfigurationForm from './TableWidgetConfigurationForm';
import { isEmpty } from 'lodash-es';

interface TableWidgetProps extends IDashboardWidget<TableWidgetConfiguration> {
  data?: WidgetDataDTO;
  isFetchingDataForWidget?: boolean;
  configurationId: string;
}

interface TableWidgetGridContext extends ExpandedGridContext {
  metricColumnAggregates: MetricAggregates | undefined;
  comparisonUnit: ComparisonUnit;
}

const TableWidget: FunctionComponent<TableWidgetProps> = ({ configuration, id, isFetchingDataForWidget, data }) => {
  const { metricsDataComparator, getMetricFieldChangePercentageCellRendererParams } = useColDefsFunctions();
  const { metricsDataAggFunc } = useAggregators();

  const { getLongFormatterForMetricField } = useFormatting();
  const gridApiRef = useRef<GridApi<TableWidgetDataRowDTO>>();
  const gridContextRef = useRef<TableWidgetGridContext>();

  const dashboardCurrency = useDashboardContextValue((context) => context.dashboard?.dto.currency);
  const [metricColumnAggregates, setMetricColumnAggregates] = useState<MetricAggregates | undefined>(undefined);

  const columnTypes = useMemo<Record<string, ColDef<CampaignsGroupedByAdTypeRow>>>(() => {
    const getChangePercentageHeaderRendererParams = (
      params: IHeaderParams<CampaignsGroupedByAdTypeRow>,
    ): IChangePercentageHeaderRendererProps => {
      const colId = params.column.getColId() as ColumnId;
      const metricField = getMetricConfigByColId(colId)?.key;

      let current: number | undefined;
      if (metricField !== undefined) {
        current = params.context?.metricColumnAggregates?.[metricField]?.current;
      }

      const formattedAggValue =
        metricField !== undefined && current !== undefined
          ? getLongFormatterForMetricField(metricField)(current, {
              customCurrencyCode: dashboardCurrency,
            })
          : (current?.toString() ?? '0');

      return {
        ...params,
        currentValue: formattedAggValue,
      };
    };

    return {
      metricFieldWithChangePercentage: {
        autoHeight: true,
        minWidth: 40,
        width: 125,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        comparator: metricsDataComparator,
        aggFunc: metricsDataAggFunc,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CampaignsGroupedByAdTypeRow, unknown>) =>
          getMetricFieldChangePercentageCellRendererParams(params, {
            customCurrencyCode: dashboardCurrency,
          }),
        valueFormatter: (params: ValueFormatterParams) => {
          if (Array.isArray(params.value) && params.value.length > 0) {
            return params.value[0];
          }
          return '';
        },
      },
    };
  }, [dashboardCurrency]);

  function onGridReady(params: GridReadyEvent) {
    gridApiRef.current = params.api;

    // Context
    gridContextRef.current = params.context;

    if (gridContextRef.current && gridApiRef.current && !gridApiRef.current.isDestroyed()) {
      gridApiRef.current.refreshHeader();
      // Only needed if the comparison unit is changed
      // gridApiRef.current.refreshCells({ force: true });
    } else {
      // Should not happen as gridApiRef was just set
      Sentry.captureMessage(`TableWdigetTable: gridContextRef is undefined though it was just set`, 'info');
    }
  }

  const customGridOptions: GridOptions<CampaignsGroupedByAdTypeRow> = useMemo(() => {
    // While values might be undefined, default values are needed so grid knows which fields to expect
    const defaultCampaignGridContext: TableWidgetGridContext = {
      metricColumnAggregates: metricColumnAggregates,
      comparisonUnit: 'nominal',
      isComparisonDataMissing: false, //TODO: review if this needs to be dynamic
    };
    return {
      ...DEFAULT_GRID_OPTIONS,
      getRowId: (params) => params.data.name.toString(),
      context: defaultCampaignGridContext,
      maintainColumnOrder: true,
      sideBar: false,

      suppressAggFuncInHeader: true, // without this aggregated Impressions header would be func(Impressions)
      columnTypes: columnTypes,
      getRowStyle: (params) => {
        if (params.node.group) {
          // Check if the row is a group row
          return {
            background: 'linear-gradient(to bottom, rgba(96,165,250, 0.2), rgba(96,165,250, 0.05))',
          };
        }
      },
    };
  }, [columnTypes]);

  const metricColumns: ColDef<CampaignsGroupedByAdTypeRow>[] = useMemo(() => {
    return TABLE_METRIC_COLUMNS.map((col) => {
      return {
        ...col,
        hide: !configuration.metricColumns?.find((c) => c.columnId === col.field)?.isVisible,
      };
    });
  }, [configuration.metricColumns]);

  // Calculate the aggregates when the data changes
  useEffect(() => {
    if (data) {
      const metricColumnAggregates = (data as TableWidgetDataDTO).data.reduce((aggregates, row) => {
        metricColumns.forEach((col) => {
          const metricField = getMetricConfigByColId(col.colId as ColumnId)?.key as CommonWithSellerMetricField | undefined;
          if (metricField !== undefined) {
            aggregates[metricField] = aggregates[metricField] ?? { current: 0 };
            aggregates[metricField].current += row[metricField][0] ?? 0;
          }
        });
        return aggregates;
      }, {} as MetricAggregates);

      setMetricColumnAggregates(metricColumnAggregates);

      if (gridContextRef.current && gridApiRef.current && !gridApiRef.current.isDestroyed()) {
        gridContextRef.current.metricColumnAggregates = metricColumnAggregates;
        gridApiRef.current.refreshHeader();
      }
    }
  }, [data, gridContextRef.current]);

  const hasFilters =
    (!isEmpty(configuration.campaignFilters) &&
      (configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE)) ||
    (!isEmpty(configuration.targetsFilters) && configuration.entityType === EntityType.TARGET);

  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isFetchingDataForWidget} isConfigured={configuration.isConfigured}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget}>
        <DashboardWidgetTopBar>
          <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
          <DashboardWidgetFilterIndicator visible={hasFilters && !isMouseOnWidget} widgetId={id} />
        </DashboardWidgetTopBar>
        <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>
        <div className="flex-1 border-t">
          <AlGrid
            noBorders
            noTopBorderRadius
            gridOptions={customGridOptions}
            rowData={(data as TableWidgetDataDTO)?.data ?? []}
            colDefs={[
              {
                field: 'name',
              },
              ...metricColumns,
            ]}
            onGridReadyCallback={onGridReady}
          />
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <TableWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(TableWidget, isBaseWidgetConfigurationEqual);
