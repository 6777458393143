import NumbersIcon from '@mui/icons-material/Numbers';
import PercentIcon from '@mui/icons-material/Percent';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { FunctionComponent, MouseEvent } from 'react';
// Substitute with a relevant delta/change icon
import { ChangeHistory } from '@mui/icons-material';
import { ComparisonUnit } from '../grid/types';

export enum SortByUnit {
  Count = 'count',
  Delta = 'delta',
}

interface SortByToggleButtonProps {
  sortBy: SortByUnit; // Current sort by unit
  handleSortChange: (newSortBy: SortByUnit) => void; // Callback to handle sort change
  comparisonUnit: ComparisonUnit;
}

const SortByToggleButton: FunctionComponent<SortByToggleButtonProps> = ({ sortBy, handleSortChange, comparisonUnit }) => {
  const handleSortByChange = (event: MouseEvent<HTMLElement>, newSortBy: SortByUnit) => {
    if (newSortBy) {
      handleSortChange(newSortBy);
    }
  };

  return (
    <div className="flex items-center">
      <ToggleButtonGroup size="small" value={sortBy} exclusive onChange={handleSortByChange} aria-label="sort by">
        <Tooltip title="Sort by count">
          <ToggleButton value="count" aria-label="sort by count">
            <NumbersIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
        </Tooltip>
        <Tooltip title={comparisonUnit === 'percent' ? 'Sort by delta (percentage value)' : 'Sort by delta (absolute value)'}>
          <ToggleButton value="delta" aria-label="sort by delta">
            <ChangeHistory sx={{ fontSize: 15 }} />
            {comparisonUnit === 'percent' ? <PercentIcon sx={{ fontSize: 15 }} /> : <NumbersIcon sx={{ fontSize: 15 }} />}
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </div>
  );
};

export default SortByToggleButton;
