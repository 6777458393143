import { FilterAlt } from '@mui/icons-material';
import React from 'react';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';

interface DashboardWidgetFilterIndicatorProps {
  visible: boolean;
  widgetId: string;
}

const DashboardWidgetFilterIndicator: React.FC<DashboardWidgetFilterIndicatorProps> = ({ visible, widgetId }) => {
  const { isEditingDashboard, editingWidgetId } = useDashboardContextValue((context) => ({
    isEditingDashboard: context.isEditingDashboard,
    editingWidgetId: context.editingWidgetId,
  }));

  const isEditMode = isEditingDashboard || editingWidgetId === widgetId;

  if (isEditMode) {
    return null;
  }

  return <div className="pt-2 pr-2 opacity-45 text-md">{visible && <FilterAlt fontSize="inherit" />}</div>;
};

export default DashboardWidgetFilterIndicator;
