import { CopyAll } from '@mui/icons-material';
import { useContext } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { ButtonBase } from '@mui/material';
import { useDashboardQueries } from '../../hooks/useDashboardQueries';
import { isEmpty } from 'lodash-es';
import { toast } from 'react-toastify';

export function DashboardWidgetConfigurationDrawerDuplicateButton() {
  const { widgetId } = useContext(DashboardWidgetContext);

  const duplicateWidget = useDashboardContextValue((context) => context.duplicateWidget);
  const { refetchForWidgetWithId } = useDashboardQueries();

  async function onDuplicateWidgetClicked() {
    if (widgetId) {
      const duplicatedWidgetID = await duplicateWidget(widgetId);
      if (!isEmpty(duplicatedWidgetID)) {
        refetchForWidgetWithId(duplicatedWidgetID);
        toast.success('Widget duplicated successfully', {
          position: 'top-center',
        });
      } else {
        toast.error('Failed to duplicate widget', {
          position: 'top-center',
        });
      }
    }
  }

  return (
    <ButtonBase className="flex rounded py-1 px-2 hover:bg-blue-200 hover:bg-opacity-20" onClick={onDuplicateWidgetClicked}>
      <div className="flex items-center text-sm text-blue-600 cursor-pointer">
        <CopyAll fontSize="inherit" />
        <div className="pl-1">Duplicate Widget</div>
      </div>
    </ButtonBase>
  );
}
