import InfoMessage from '@/components/feedback/InfoMessage';
import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { TextLabel } from '@/components/typography/TextLabel';
import { useTranslation } from '@/lib';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { useOptimizerContext } from '../contexts/OptimizerContext';
import { PreOptimizationInfo } from '../hooks/useOptimizationWarnings';
import { OptimizationParams, OptimizationPreset, availableOptimizationPresets } from './optimization/OptimizerConfig';

enum SmartBidCeilingOption {
  SMART = 'Smart',
  MANUAL = 'Manual',
  OFF = 'Off',
}

interface OptimizationParamsModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  optimizationParams: OptimizationParams;
  setOptimizationParams: (optimizationParams: OptimizationParams) => void;
  selectedCampaigns: SelectedCampaignDTO[];
  onPreviewOptimizationsClicked: (filtersModified: AlFilterModel[]) => void;
  preOptimizationInfo: PreOptimizationInfo;
}

export const OptimizationParamsModal: FunctionComponent<OptimizationParamsModalProps> = ({
  isOpen,
  onClose,
  optimizationParams,
  setOptimizationParams,
  selectedCampaigns,
  onPreviewOptimizationsClicked,
  preOptimizationInfo,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { filters: contextFilters, setFilterValues } = useOptimizerContext();
  const [filtersUsedForOptimizing, setFiltersUsedForOptimizing] = useState(contextFilters);

  const [bidCeilingOption, setBidCeilingOption] = useState<SmartBidCeilingOption>(SmartBidCeilingOption.SMART);

  useEffect(() => {
    setFiltersUsedForOptimizing(contextFilters);
  }, [contextFilters]);

  const handleOptimizationParamsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox') {
      if (name == 'usePlacementOptimization') {
        // If checkbox is removed from Optimize Placements then remove and disable checkbox from Apply Max Increase Limits
        optimizationParams.limitPlacementChange = checked;
      }
      setOptimizationParams({
        ...optimizationParams,
        [name]: checked,
      });
    } else {
      setOptimizationParams({
        ...optimizationParams,
        [name]: value,
      });
    }
  };

  const handlePresetChange = (event: SelectChangeEvent<OptimizationPreset>) => {
    const selectedPreset = event.target.value as OptimizationPreset;
    setOptimizationParams({
      ...optimizationParams,
      selectedPreset: selectedPreset,
    });
  };

  const handleSmartBidCeilingChange = (event: SelectChangeEvent<SmartBidCeilingOption>) => {
    const selectedOption = event.target.value as SmartBidCeilingOption;

    if (selectedOption === SmartBidCeilingOption.MANUAL) {
      setOptimizationParams({
        ...optimizationParams,
        smartBidCeilingDisabled: true,
        bidCeiling: 0, // expected to change
      });
    }

    if (selectedOption === SmartBidCeilingOption.SMART) {
      setOptimizationParams({
        ...optimizationParams,
        smartBidCeilingDisabled: false,
        bidCeiling: 0,
      });
    }

    if (selectedOption === SmartBidCeilingOption.OFF) {
      setOptimizationParams({
        ...optimizationParams,
        smartBidCeilingDisabled: true,
        bidCeiling: 0,
      });
    }

    setBidCeilingOption(selectedOption);
  };

  const [showAdvanced, setShowAdvanced] = useState(false);

  const toggleAdvanced = () => {
    setShowAdvanced((prev) => {
      return !prev;
    });
  };

  const onPreviewActionClicked = () => {
    onClose();
    onPreviewOptimizationsClicked(filtersUsedForOptimizing);
  };

  let optimizationApplicabilityText;
  if (optimizationParams.useGroupSettings) {
    if (preOptimizationInfo.stats.selectedCampaignsGroupedCount === selectedCampaigns.length) {
      optimizationApplicabilityText = 'Applies only to "Not Set" Opt Groups';
    } else if (preOptimizationInfo.stats.selectedCampaignsUnGroupedCount === selectedCampaigns.length) {
      optimizationApplicabilityText = 'Applies to all selected campaigns';
    } else {
      optimizationApplicabilityText = 'Applies to ungrouped campaigns and "Not Set" Opt Groups';
    }
  } else {
    optimizationApplicabilityText = 'Applies to all selected campaigns';
  }

  const selectedCampaignsContainOptGroups = preOptimizationInfo.stats.selectedCampaignsGroupedCount > 0;

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle style={{ paddingBottom: '0px' }}>Optimization Settings</DialogTitle>
      <DialogContent>
        <div className="flex items-center my-2">
          <TextLabel>Date Range used for optimizing</TextLabel>
          <Tooltip title="Optimize current bids based on data from this date range">
            <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
          </Tooltip>
        </div>

        <DateRangeButton
          title="Optimization Range"
          filters={contextFilters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to calculate bid optimizations"
          hideComparison
        />
        {/* Group toggle with header */}
        <div className="grid grid-cols-2 mt-4">
          {selectedCampaignsContainOptGroups && (
            <div className="flex flex-col">
              <div className="flex items-center">
                <TextLabel>Opt Group Settings</TextLabel>
                <Tooltip title="Disable to ignore Optimization Group settings">
                  <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                </Tooltip>
              </div>

              <div className="flex flex-row mx-2 mt-4">
                <FormGroup row>
                  <div className="flex items-center">
                    <Switch checked={optimizationParams.useGroupSettings} onChange={handleOptimizationParamsChange} name="useGroupSettings" />
                    <span className={`ml-2 text-sm font-bold ${optimizationParams.useGroupSettings ? 'text-blue-600' : 'text-orange-500'}`}>
                      {optimizationParams.useGroupSettings ? 'Use Opt Group Settings' : 'Ignore Opt Group Settings'}
                    </span>
                  </div>
                </FormGroup>
              </div>
            </div>
          )}

          {/* TACOS, PRIORITIZATION and bottom text */}
          <div className={`flex flex-col ${selectedCampaignsContainOptGroups ? '' : 'col-span-2'}`}>
            <div className="flex flex-row gap-x-2">
              <div className="w-24 shrink-0">
                <TextLabel>{'Target ACOS*'}</TextLabel>
                <TextField
                  type="number"
                  onChange={handleOptimizationParamsChange}
                  name="tacos"
                  defaultValue={optimizationParams.tacos}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    },
                  }}
                />
              </div>

              <div>
                <TextLabel>{'Prioritization*'}</TextLabel>
                <Select value={optimizationParams.selectedPreset} onChange={handlePresetChange} className="w-40 mt-2">
                  {availableOptimizationPresets
                    .filter((preset) => preset !== OptimizationPreset.NOT_SET)
                    .map((preset) => (
                      <MenuItem key={preset} value={preset}>
                        {t(`optimizer_page.optimization_presets.${preset}`)}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>

            <Typography className={'ml-2 text-xs '} style={{ color: theme.palette.text.disabled }}>
              <span className="text-red-500">*</span>

              {optimizationApplicabilityText}
            </Typography>
          </div>
        </div>
        <Typography variant="h6" gutterBottom className="mt-4">
          Targets to Optimize
        </Typography>
        <div className="grid grid-cols-2">
          <div className="flex flex-col">
            <TextLabel>Improve Efficiency</TextLabel>
            <FormGroup row>
              <Checkbox checked={optimizationParams.highAcos} onChange={handleOptimizationParamsChange} name="highAcos" />
              <Typography variant="body1" className="flex items-center gap-1">
                High ACOS
                <Tooltip title="The ACOS is above the user's Target ACOS">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>
            <FormGroup row>
              <Checkbox checked={optimizationParams.highSpend} onChange={handleOptimizationParamsChange} name="highSpend" />
              <Typography variant="body1" className="flex items-center gap-1">
                High Spend, No Sales
                <Tooltip title="Spend has exceeded the Target Cost-Per-Acquisition without any sales">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>
          </div>
          <div className="flex flex-col">
            <TextLabel>Improve Sales</TextLabel>
            <FormGroup row>
              <Checkbox checked={optimizationParams.lowAcos} onChange={handleOptimizationParamsChange} name="lowAcos" />
              <Typography variant="body1" className="flex items-center gap-1">
                Low ACOS
                <Tooltip title="The ACOS is below the user's Target ACOS">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>

            <FormGroup row>
              <Checkbox checked={optimizationParams.lowVisibility} onChange={handleOptimizationParamsChange} name="lowVisibility" />
              <Typography variant="body1" className="flex items-center gap-1">
                Low Visibility
                <Tooltip title="Fewer clicks than the average Clicks-to-Conversion">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>
            <div className="flex flex-row items-center">
              <span className="ml-[10px]">{'\u2514'}</span>
              <FormGroup row>
                <Checkbox
                  checked={optimizationParams.showZeroImpressions}
                  onChange={handleOptimizationParamsChange}
                  name="showZeroImpressions"
                />
                <Typography variant="body1" className="flex items-center gap-1">
                  Include 0 Impressions
                  <Tooltip title="Vastly increases the number of Low Visibility bid adjustments">
                    <HelpOutlineIcon className="cursor-pointer text-sm" />
                  </Tooltip>
                </Typography>
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center items-center mt-4">
          <Button className="self-end " variant="text" onClick={toggleAdvanced}>
            {showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
            {showAdvanced ? <ArrowDropUpRoundedIcon fontSize="small" /> : <ArrowDropDownRoundedIcon fontSize="small" />}
          </Button>
        </div>
        <Collapse in={showAdvanced}>
          <Divider className="my-2" />

          <Typography variant="h6" gutterBottom>
            Bid Ceiling Settings
          </Typography>
          <div className="flex flex-row gap-6">
            <div className="flex flex-col">
              <div className="flex items-center">
                <TextLabel>Bid Ceilings</TextLabel>
                <Tooltip title="Limit bid increases to remain within Target ACOS">
                  <HelpOutlineIcon className="ml-1 cursor-pointer text-sm" />
                </Tooltip>
              </div>
              <div className="flex flex-row">
                <Select value={bidCeilingOption} onChange={handleSmartBidCeilingChange} className="w-34 mt-2">
                  {Object.values(SmartBidCeilingOption).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            {bidCeilingOption == SmartBidCeilingOption.MANUAL && (
              <div className="flex flex-col">
                <div>
                  <TextLabel>Manual Bid Ceiling</TextLabel>
                  <TextField
                    defaultValue={optimizationParams.bidCeiling}
                    type="number"
                    onChange={handleOptimizationParamsChange}
                    name="bidCeiling"
                    style={{ width: '120px' }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                      },
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <Typography variant="h6" gutterBottom className="mt-4">
            Placement Settings
          </Typography>
          <div className="flex flex-row gap-6">
            <FormGroup row>
              <Checkbox
                checked={optimizationParams.usePlacementOptimization}
                onChange={handleOptimizationParamsChange}
                name="usePlacementOptimization"
              />
              <Typography variant="body1" className="flex items-center gap-1">
                Optimize Placements
                <Tooltip title="Campaign placements are calculated from performance and factored into keyword/product target bids">
                  <HelpOutlineIcon className="cursor-pointer text-sm" />
                </Tooltip>
              </Typography>
            </FormGroup>

            <div className="flex flex-row items-center gap-3">
              {/* Opposite value is sent in service because label is opposite of what backend expects */}
              <FormGroup row>
                <Checkbox
                  checked={optimizationParams.limitPlacementChange}
                  onChange={handleOptimizationParamsChange}
                  name="limitPlacementChange"
                  disabled={!optimizationParams.usePlacementOptimization}
                />
                <Typography variant="body1" className="flex items-center gap-1">
                  Apply Max Increase Limits
                  <Tooltip title="Large increases for placements will be limited to avoid unwanted spend / ACOS spikes">
                    <HelpOutlineIcon className="cursor-pointer text-sm" />
                  </Tooltip>
                </Typography>
              </FormGroup>
            </div>
          </div>
        </Collapse>
      </DialogContent>
      <Divider />
      {preOptimizationInfo.warnings.length > 0 && (
        <div className="flex flex-col mx-5">
          {preOptimizationInfo.warnings.map((warning, index) => (
            <InfoMessage key={index} text={warning} />
          ))}
        </div>
      )}
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Tooltip
          title={
            bidCeilingOption == SmartBidCeilingOption.MANUAL && (optimizationParams.bidCeiling == 0 || optimizationParams.bidCeiling == null)
              ? 'Manual bid ceiling not set'
              : ''
          }
        >
          <span>
            <Button
              onClick={onPreviewActionClicked}
              disabled={
                bidCeilingOption == SmartBidCeilingOption.MANUAL &&
                (optimizationParams.bidCeiling == 0 || optimizationParams.bidCeiling == null)
              }
              endIcon={<EastRoundedIcon />}
            >
              Preview Optimizations
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
