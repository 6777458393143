import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import { DATE_FORMAT } from '@/components/filter-builder/FiltersConfig';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import { AlFilterModel, OperatorType, TeamFilterModel, getMonthToDateDateRange } from '@/components/filter-builder/models/AlFilterModel';
import { FormatterType, GridToggles } from '@/components/grid/types';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { IdNameDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { profileService } from '@/modules/profiles/api/profile.service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { CurrencyCode, getMostCommonCurrencyCodeForUser } from '@/modules/users/types/CurrencyCode';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GridApi, GridReadyEvent } from 'ag-grid-enterprise';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ProfileStatsFilterBar from '../components/ProfileStatsFilterBar';
import ProfilesStatsTable from '../components/profile-stats-table/ProfilesStatsTable';

const ProfilesStatsPage: FunctionComponent = () => {
  const { t } = useTranslation();

  // GRID
  const gridApiRef = useRef<GridApi | null>(null);
  function onProductsTableGridReady(params: GridReadyEvent) {
    gridApiRef.current = params.api;
  }

  // FILTERS
  const { user } = useUserContext();
  const { activeTeam } = useActiveTeamContext();

  // Frontend filters added in uef
  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    return getMonthToDateDateRange();
  });

  const { dates, comparisonDates } = useFilters({ filters, setFilters });

  useEffect(() => {
    if (!activeTeam?.hasProPlan) {
      return;
    }
    filtersService.saveDateFilters(filters);
  }, [filters]);

  // QUERY
  const PROFILE_STATS_QUERY_KEY = [
    'profile-stats',
    dates.map((d) => d.format(DATE_FORMAT)),
    comparisonDates.map((d) => d.format(DATE_FORMAT)),
    user?.teams.map((t) => t.name), // If team name is changed we need to refresh
    user?.teams.map((t) => t.profiles.map((p) => p.id)).flat(), // If a profile is added or removed we need to refresh
  ];

  const {
    data: profilesWithMetrics,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: PROFILE_STATS_QUERY_KEY,
    queryFn: async () => {
      const result = await profileService.getUserProfilesWithMetrics({
        userId: user?.id as number,
        dates: {
          startDate: dates[0].format(DATE_FORMAT),
          endDate: dates[1].format(DATE_FORMAT),
        },
        compareDates: {
          startDate: comparisonDates[0].format(DATE_FORMAT),
          endDate: comparisonDates[1].format(DATE_FORMAT),
        },
      });

      if (result.isSuccess) {
        return result.payload.filter((p) => p.isActive);
      } else {
        toast.error('Error loading profile stats');
      }
    },
  });

  // Set team filter with  as default, requires data for profilesWithMetrics
  const [isFirstDataLoaded, setIsFirstDataLoaded] = useState(false);
  useEffect(() => {
    if (isFirstDataLoaded || !activeTeam || !profilesWithMetrics) return;
    const teamIdName: IdNameDTO[] =
      profilesWithMetrics?.reduce((acc, profile) => {
        const id = profile.teamId.toString();

        // Check if the ID already exists in the accumulator array
        if (!acc.some((item) => item.id === id)) {
          acc.push({ id, name: profile.teamName });
        }

        return acc;
      }, [] as IdNameDTO[]) ?? [];

    const teamFilter = new TeamFilterModel(teamIdName);
    teamFilter.conditions = [
      {
        values: [activeTeam.id.toString()],
        operator: OperatorType.IN,
      },
    ];

    if (!filters.some((f) => f.key === teamFilter.key)) {
      setFilters([...filters, teamFilter]);
    }
    setIsFirstDataLoaded(true);
  }, [profilesWithMetrics]);

  // Table toggles
  const [gridToggles, setGridToggles] = useState<GridToggles>({
    comparisonUnit: 'nominal',
    // sortByUnit: SortByUnit.Count, // TODO
    formatterType: FormatterType.LONG,
  });

  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyCode>(() => getMostCommonCurrencyCodeForUser(user) ?? CurrencyCode.USD);

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={
          <div className="flex items-center gap-x-2">
            <div>{t('profiles_stats_page.header')}</div>
            <div>
              <Tooltip title={'Profile totals include all campaigns (incl. archived)'}>
                <span className="flex items-center font-medium">
                  <InfoIcon fontSize="small" style={{ marginRight: '6px' }} />
                </span>
              </Tooltip>
            </div>
          </div>
        }
      ></PageLayoutTopBar>
      <PageLayoutBody>
        <div className="mt-4 flex flex-grow flex-1 flex-col">
          <AlErrorBoundary>
            <ProfileStatsFilterBar
              profilesWithMetrics={profilesWithMetrics}
              gridApiRef={gridApiRef}
              filters={filters}
              setFilters={setFilters}
              gridToggles={gridToggles}
              setGridToggles={setGridToggles}
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
            />
          </AlErrorBoundary>

          <AlErrorBoundary>
            <ProfilesStatsTable
              onGridReadyCallback={onProductsTableGridReady}
              isLoading={isLoading}
              rowData={isFirstDataLoaded ? profilesWithMetrics : undefined} // Only show table after data is loaded and team filter set
              externalFilters={filters}
              gridToggles={gridToggles}
              selectedCurrency={selectedCurrency}
              refetch={refetch}
            />
          </AlErrorBoundary>
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default ProfilesStatsPage;
