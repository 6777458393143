import { FunctionComponent, memo, useState } from 'react';
import { IDashboardWidget } from '../../../types/IDashboardWidget';
import DashboardWidgetProvider from '../../dashboard-widget/DashboardWidgetContextProvider';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';
import TextWidgetConfigurationForm from './TextWidgetConfigurationForm';
import { TextWidgetConfiguration } from './TextWidgetConfiguration';

interface TextWidgetProps extends IDashboardWidget<TextWidgetConfiguration> {
  configurationId: string;
}

const TextWidget: FunctionComponent<TextWidgetProps> = ({ configuration, id }) => {
  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={false} isConfigured={configuration.isConfigured}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget}>
        <div className=" w-full h-full p-2 tiptap overflow-auto" dangerouslySetInnerHTML={{ __html: configuration.content }}></div>
        <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>
      </DashboardWidgetContent>
      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <TextWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(TextWidget, isBaseWidgetConfigurationEqual);
