import useFormatting, { CurrencyPosition } from '@/hooks/useFormatting';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { InputAdornment } from '@mui/material';
import { isNil } from 'lodash-es';
import React from 'react';
import { toast } from 'react-toastify';

export const useHelperComponents = () => {
  function createMultilineMessageComponent(messages: string[], maxMessages = 3, breakCount = 2): React.ReactElement {
    // Helper function to render messages with <br />
    const renderMessagesWithBreaks = (messagesToRender: string[]): React.ReactNode => {
      return messagesToRender.map((message, index) => (
        <React.Fragment key={index}>
          {message}
          {Array(breakCount)
            .fill(null)
            .map((_, index) => (
              <br key={index} />
            ))}
        </React.Fragment>
      ));
    };

    const visibleMessages = messages.slice(0, maxMessages);
    const remainingMessagesCount = messages.length - maxMessages;

    const othersText =
      remainingMessagesCount > 0 ? (remainingMessagesCount === 1 ? `and 1 other` : `and ${remainingMessagesCount} others`) : null;

    return (
      <>
        {renderMessagesWithBreaks(visibleMessages)}
        {!isNil(othersText) && othersText}
      </>
    );
  }

  function toastWarnWithSetMessages(warnings: Set<string>) {
    toast.warn(createMultilineMessageComponent(Array.from(warnings)));
  }

  const { getCurrencySymbol, getCurrencySymbolPosition } = useFormatting();
  // TODO: apply this where is 'New Bid ($)'
  const getCurrencyInputProps = () => {
    const currencySymbol = getCurrencySymbol();
    return getCurrencySymbolPosition() === CurrencyPosition.LEFT
      ? { startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment> }
      : { endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment> };
  };

  function getExternalLinkComponent(link: string, linkText: string = 'Learn more', onlyHoverUnderline = true) {
    return (
      <a
        className={`mx-1  text-inherit ${onlyHoverUnderline ? 'hover:underline no-underline' : 'underline'}`}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        {linkText}
        <OpenInNewIcon fontSize="inherit" />
      </a>
    );
  }
  return {
    getCurrencyInputProps,
    createMultilineMessageComponent,
    toastWarnWithSetMessages,
    getExternalLinkComponent,
  };
};
