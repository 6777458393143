import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import LoadingOverlay from '@/components/feedback/LoadingOverlay';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultCampaignFilters } from '@/components/filter-builder/models/AlFilterModel';
import { useNavigationConfirmationModal } from '@/components/modals/confirmation-modal/useNavigationConfirmationModal';
import { Environment } from '@/config/Environment';
import { useLayoutContext } from '@/contexts/LayoutContext';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UpdateResponseDTO, UpdateResponseModal } from '../../application/components/UpdateResponseModal';
import { TeamSelect } from '../../teams/components/TeamSelect';
import UpgradeSubscriptionButton from '../../teams/components/UpgradeSubscriptionButton';
import ProfileSyncSelectButton from '../ProfileSyncSelectButton';
import { CampaignAdType, CostType, EnabledPausedArchivedState, MultiAdGroupsEnabledType } from '../api/campaign/campaign-contracts';
import { campaignService, createCampaignsWithTimelineQueryKey } from '../api/campaign/campaign-service';
import { SelectedCampaignDTO } from '../api/campaign/models/CampaignModel';
import { OptimizationParamsModal } from '../components/OptimizationParamsModal';
import OptimizerFilterBar from '../components/OptimizerFilterBar';
import OptimizerSelectionActionsBar from '../components/OptimizerSelectionActionsBar';
import CampaignTable from '../components/campaign-table/CampaignTable';
import OptimizerMetrics from '../components/metrics/OptimizerMetrics';
import { OptimizationsPreviewModal } from '../components/optimization/OptimizationsPreviewModal';
import { OptimizationApplyData, OptimizationParams, OptimizationPreset } from '../components/optimization/OptimizerConfig';
import { optimizationService } from '../components/optimization/api/optimization-service';
import { OptimizationModel } from '../components/optimization/models/OptimizationModel';
import CampaignTimelineChart from '../components/timeline/CampaignTimelineChart';
import { OPTIMIZER_CONTEXT_KEY, OptimizerProvider } from '../contexts/OptimizerContext';
import useOptimizationWarnings from '../hooks/useOptimizationWarnings';

const OptimizerPage: FunctionComponent = () => {
  const { activeTeam, activeProfile, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { refetchUser } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { startGlobalLoading, stopGlobalLoading } = useLayoutContext();
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);
  const { isAdminModeActive } = useUserContext();

  const { ModalComponent: navigationModal, setBlock } = useNavigationConfirmationModal();

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(OPTIMIZER_CONTEXT_KEY, activeProfile?.id ?? '', getDefaultCampaignFilters());
  });

  const [filtersUsedForOptimizing, setFiltersUsedForOptimizing] = useState<AlFilterModel[]>([]);

  // GRID
  const campaignTableGridApi = useRef<GridApi | null>(null);

  function onCampaignTableGridReady(params: GridReadyEvent) {
    campaignTableGridApi.current = params.api;
  }

  // CAMPAIGNS
  const campaignsWithTimelineQueryKey = createCampaignsWithTimelineQueryKey(activeProfile?.id, filters);
  const [selectedCampaigns, setSelectedCampaigns] = useState<SelectedCampaignDTO[]>([]);
  const {
    data: campaignsWithTimeline,
    isLoading: isCampaignRowDataLoading,
    isError: isCampaignLoadingError,
    error: campaignLoadingError,
    refetch: refetchCampaignsWithTimeline,
    isFetching: isFetchingCampaignsWithTimeline,
  } = useQuery({
    queryKey: campaignsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await campaignService.getCampaignsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading campaign ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(filters) && !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetchingCampaignsWithTimeline', isFetchingCampaignsWithTimeline);

  // SELECTIONS ACTION BAR
  const { preOptimizationInfo } = useOptimizationWarnings({ selectedCampaigns });

  const onDiscardClicked = () => {
    clearSelections();
  };

  function clearSelections() {
    setSelectedCampaigns([]);
    setOptimizationPreviewSelection([]);
    if (campaignTableGridApi.current && !campaignTableGridApi.current.isDestroyed()) {
      campaignTableGridApi.current.deselectAll();
    }
  }

  // OPTIMIZATION PARAMS MODAL
  const [isOptimizationParamsModalOpen, setIsOptimizationParamsModalOpen] = useState(false);
  const [optimizationParams, setOptimizationParams] = useState<OptimizationParams>({
    tacos: 30,
    selectedPreset: OptimizationPreset.BALANCED,
    highAcos: true,
    lowAcos: true,
    highSpend: true,
    lowVisibility: true,
    showZeroImpressions: false,
    smartBidCeilingDisabled: false,
    bidCeiling: 0,
    useGroupSettings: true,
    usePlacementOptimization: true,
    limitPlacementChange: true,
  });

  // OPTIMIZATION PREVIEW MODAL
  const [isLoadingOptimizedResults, setIsLoadingOptimizedResults] = useState(false);
  const onPreviewOptimizationsClicked = (filtersUsedForOptimizing: AlFilterModel[]) => {
    const fetchOptimizedResults = async () => {
      try {
        if (isNil(activeTeam)) {
          toast.error('Error active team is not set');
          return;
        }

        const today = dayjs();
        // TODO: move these rules into a CampaignModel function
        // Do not send vCPM cost type campaigns to optimizer
        // Do not send multi adgroup support false campaigns (legacy campaigns) to optimizer
        // Do not send paused campaigns to optimizer
        // Do not send campaigns whose end date is in the past
        const filteredSelectedCampaigns = selectedCampaigns.filter(
          (campaign) =>
            campaign.costType != CostType.VCPM &&
            campaign.multiAdGroupsEnabled != MultiAdGroupsEnabledType.FALSE &&
            campaign.state != EnabledPausedArchivedState.PAUSED &&
            campaign.state != EnabledPausedArchivedState.ARCHIVED &&
            (campaign.endDate == null || dayjs(campaign.endDate).isAfter(today)),
        );

        if (filteredSelectedCampaigns.length == 0) {
          toast.error('No campaigns to optimize');
          return;
        }
        setIsLoadingOptimizedResults(true);

        const queryKey = optimizationService.createOptimizedResultsQueryKey(
          activeProfile?.id,
          filteredSelectedCampaigns.map((campaign) => campaign.id),
          filtersUsedForOptimizing,
          optimizationParams,
        );

        const queryFn = () =>
          optimizationService.getOptimizedResults(
            filteredSelectedCampaigns.map((campaign) => campaign.id),
            filtersUsedForOptimizing,
            optimizationParams,
          );

        startGlobalLoading(queryKey);

        const newOptimizedResults = await queryClient.fetchQuery({
          queryKey,
          queryFn,
          staleTime: 1000 * 60 * 5, // Data is fresh for 5 minutes
        });

        stopGlobalLoading(queryKey);

        if (newOptimizedResults.isSuccess) {
          setOptimizedResults(newOptimizedResults.payload);
          setFiltersUsedForOptimizing(filtersUsedForOptimizing);
          setIsOptimizationsPreviewModalOpen(true);
          setOptimizationPreviewSelection([]);
        } else {
          toast.error('Error loading getting optimized results');
          return;
        }
      } catch (error) {
        toast.error('Error loading getting optimized results');
        console.error(error);
      }
      setIsLoadingOptimizedResults(false);
    };

    fetchOptimizedResults();
  };

  const [optimizationResults, setOptimizedResults] = useState<OptimizationModel | null>(null);
  const [isPreviewOptimizationsModalOpen, setIsOptimizationsPreviewModalOpen] = useState(false);
  const handleClosePreviewOptimizationsModal = () => {
    setIsOptimizationsPreviewModalOpen(false);
  };

  useEffect(() => {
    if (isPreviewOptimizationsModalOpen) {
      setBlock(true);
    } else {
      setBlock(false);
    }
  }, [isPreviewOptimizationsModalOpen]);

  const [optimizationPreviewSelection, setOptimizationPreviewSelection] = useState<OptimizationApplyData[]>([]);

  const [optimizationApplyResponse, setOptimizationApplyResponse] = useState<UpdateResponseDTO>();
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);

  const { mutate: onApplyOptimization, isPending: isLoadingApplyOptimization } = useMutation({
    mutationFn: () => optimizationService.applyOptimization(optimizationResults?.jobId ?? 0, optimizationPreviewSelection),
    onMutate: () => {
      if (Environment.isDev()) {
        console.log('Optimizing these with new values:', optimizationPreviewSelection);
      }
    },
    onSuccess: (res) => {
      if (res.isSuccess) {
        setOptimizationApplyResponse({ responseErrorMsg: null, payload: res?.payload });
      } else {
        setOptimizationApplyResponse({ responseErrorMsg: `Did not receive a response from server: ${res.message}`, payload: undefined });
      }
    },

    onSettled: () => {
      setUpdateResponseModalOpen(true);
      setSelectedCampaigns([]);
      setOptimizationPreviewSelection([]);

      // Sleep a bit so backend has time to update lastUpdatedAt
      setTimeout(() => {
        refetchCampaignsWithTimeline();
      }, 1500);
    },
  });

  // Paywall modal
  const [paywallMessage, setPaywallHeader] = useState('');
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
    setPaywallHeader('');
  };

  useEffect(() => {
    if (hasUserOverMaxAllowedFreeProfiles()) {
      navigate(Routes.PROFILES);
    }
  }, []);

  const onOptimizeClicked = () => {
    if (isNil(activeTeam)) {
      toast.error('Error: Active team is not set');
      return;
    }

    if (!isAdminModeActive) {
      if (selectedCampaigns.length > activeTeam.subscriptionPlan.campaignSelectionLimit) {
        setPaywallHeader(
          `Upgrade to Pro to access unlimited batch sizes. Free tier ${activeTeam?.subscriptionPlan.campaignSelectionLimit} campaign limit reached.`,
        );
        setIsPaywallModalOpen(true);
        return;
      } else if (
        !activeTeam.subscriptionPlan.canOptimizeSBAndSDCampaigns &&
        selectedCampaigns.filter((c) => c.adType == CampaignAdType.BRANDS || c.adType == CampaignAdType.DISPLAY).length > 0
      ) {
        setPaywallHeader(`Upgrade to Pro to Optimize Sponsored Brands & Sponsored Display`);
        setIsPaywallModalOpen(true);
        return;
      }
    }

    setIsOptimizationParamsModalOpen(true);
  };

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={t('optimizer_page.header')}
        actions={
          <div className="flex flex-row items-center gap-2">
            {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
            <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
            <TeamSelect />
            <ProfileSyncSelectButton setFilters={setFilters} />
          </div>
        }
      ></PageLayoutTopBar>

      <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

      {!isShowingAlerts && (
        <PageLayoutBody suppressBottomPadding={true}>
          <OptimizerProvider filters={filters} setFilters={setFilters}>
            <div className="mt-2">
              <AlErrorBoundary>
                <OptimizerMetrics />
              </AlErrorBoundary>
            </div>

            <div className="my-2 ">
              <AlErrorBoundary>
                <CampaignTimelineChart />
              </AlErrorBoundary>
            </div>

            <div>
              <AlErrorBoundary>
                <OptimizerFilterBar campaignsWithTimeline={campaignsWithTimeline} gridApiRef={campaignTableGridApi} />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <CampaignTable
                  selectedCampaigns={selectedCampaigns}
                  setSelectedCampaigns={setSelectedCampaigns}
                  withTimeline={campaignsWithTimeline}
                  isLoading={isCampaignRowDataLoading}
                  campaignLoadingErrorMessage={campaignLoadingError instanceof Error ? campaignLoadingError.message : ''}
                  isCampaignLoadingError={isCampaignLoadingError}
                  noTopBorderRadius={true}
                  onGridReadyCallback={onCampaignTableGridReady}
                />
              </AlErrorBoundary>
            </div>
            {/* Preview modal needs to be in the context to get filters to display opt date ranges */}
            {optimizationResults ? (
              <AlErrorBoundary>
                <OptimizationsPreviewModal
                  isOpen={isPreviewOptimizationsModalOpen}
                  onClose={handleClosePreviewOptimizationsModal}
                  optimizationResults={optimizationResults}
                  onApplyOptimization={onApplyOptimization}
                  optimizationPreviewSelection={optimizationPreviewSelection}
                  setOptimizationPreviewSelection={setOptimizationPreviewSelection}
                  selectedCampaigns={selectedCampaigns}
                  filtersUsedForOptimizing={filtersUsedForOptimizing}
                  onPreviewOptimizationsClicked={onPreviewOptimizationsClicked}
                />
              </AlErrorBoundary>
            ) : null}

            {/* Needs to be in context to access dates */}
            <OptimizationParamsModal
              isOpen={isOptimizationParamsModalOpen}
              onClose={() => setIsOptimizationParamsModalOpen(false)}
              optimizationParams={optimizationParams}
              setOptimizationParams={setOptimizationParams}
              selectedCampaigns={selectedCampaigns}
              onPreviewOptimizationsClicked={onPreviewOptimizationsClicked}
              preOptimizationInfo={preOptimizationInfo}
            />
          </OptimizerProvider>
        </PageLayoutBody>
      )}

      <AlErrorBoundary>
        <OptimizerSelectionActionsBar
          optimizationParams={optimizationParams}
          onOptimizationParamsChanged={setOptimizationParams}
          selectedCampaigns={selectedCampaigns}
          totalCampaignsCount={campaignsWithTimeline ? campaignsWithTimeline.campaigns.length : 0}
          onDiscardClicked={onDiscardClicked}
          setSelectedCampaigns={setSelectedCampaigns}
          onOptimizeClicked={onOptimizeClicked}
          preOptimizationInfo={preOptimizationInfo}
        />
      </AlErrorBoundary>

      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.OPTIMIZER}
        headerText={paywallMessage}
      ></PaywallModal>

      <UpdateResponseModal
        isOpen={updateResponseModalOpen}
        onClose={() => setUpdateResponseModalOpen(false)}
        updateResponse={optimizationApplyResponse}
      />

      <LoadingOverlay isVisible={isLoadingOptimizedResults} message="Crunching numbers..." />
      <LoadingOverlay isVisible={isLoadingApplyOptimization} message="Sending new data to Amazon..." />
      {navigationModal}
    </PageLayout>
  );
};

export default OptimizerPage;
