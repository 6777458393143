import { FilterDTO } from '@/components/filter-builder/api/filters-contracts';
import { CommonMetricField, CommonWithSellerMetricField } from '@/components/metrics/types/MetricField';
import { v4 } from 'uuid';
import { IDashboardWidgetBaseConfiguration } from '../../../types/IDashboardWidgetBaseConfiguration';
import { EntityType } from '../../forms/EntityTypeSelect';
import { GroupByTime } from '../../forms/GroupByTimeSelect';

export class ComboGraphWidgetConfiguration implements IDashboardWidgetBaseConfiguration {
  id: string;
  title = 'Combo Graph';
  isConfigured: boolean = false;
  selectedMetrics: CommonWithSellerMetricField[] = [
    CommonMetricField.SALES,
    CommonMetricField.ACOS,
    CommonMetricField.SPEND,
    CommonMetricField.ROAS,
  ];
  groupByTime = GroupByTime.DAY;
  entityType = EntityType.CAMPAIGN;
  profilesIds: string[] = [];

  campaignFilters: FilterDTO[] = [];
  placementFilters: FilterDTO[] = [];
  targetsFilters: FilterDTO[] = [];

  constructor() {
    this.id = v4();
  }
}
