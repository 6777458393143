import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import PopoverLikePopper from '@/components/PopoverLikePopper';
import { DashboardModel } from '../../types/DashboardModel';
import { TeamProfile } from '../../types/TeamProfile';
import { useUserContext } from '@/modules/users';

interface DashboardProfilesPopoverProps {
  dashboard: DashboardModel;
  onSelectionChange: (selectedProfiles: TeamProfile[]) => void;
}

const DashboardProfilesPopover: React.FC<DashboardProfilesPopoverProps> = ({ dashboard, onSelectionChange }) => {
  const { user } = useUserContext();

  // State to manage selected profiles
  const [selectedProfiles, setSelectedProfiles] = useState<TeamProfile[]>(dashboard.teamProfiles ?? []);

  // Filter user profiles by team
  const profileOptions: TeamProfile[] | undefined =
    user?.teams
      .filter((team) => team.id === Number(dashboard.teamId))
      .flatMap<TeamProfile>((team) =>
        team.profiles.flatMap((p) => ({
          profileId: p.id,
          profileName: p.name,
          teamId: team.id,
          teamName: team.name,
          countryCode: p.countryCode,
          state: p.state,
        })),
      )
      .sort((a, b) => a.profileName.localeCompare(b.profileName)) ?? [];

  // State to control popover visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handlePopoverMouseEnter = () => {
    setOpen(true);
  };

  const handlePopoverMouseLeave = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (profile: TeamProfile) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    let newSelectedProfiles;
    if (isChecked) {
      newSelectedProfiles = [...selectedProfiles, profile];
    } else {
      newSelectedProfiles = selectedProfiles.filter((p) => p.profileId !== profile.profileId);
    }
    setSelectedProfiles(newSelectedProfiles);
    onSelectionChange(newSelectedProfiles);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ display: 'inline-block' }}>
      <div className="normal-case cursor-default">{selectedProfiles.length} Profiles</div>
      <PopoverLikePopper
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{
          maxWidth: 'unset',
        }}
      >
        <div
          onMouseEnter={handlePopoverMouseEnter}
          onMouseLeave={handlePopoverMouseLeave}
          className={`grid p-4 ${profileOptions && profileOptions.length > 10 ? 'grid-cols-2' : 'grid-cols-1'}`}
        >
          {profileOptions?.map((profile) => (
            <FormControlLabel
              key={profile.profileId}
              control={
                <Checkbox
                  size="small"
                  checked={selectedProfiles.some((p) => p.profileId === profile.profileId)}
                  onChange={handleCheckboxChange(profile)}
                />
              }
              label={`${profile.profileName} (${profile.countryCode})`}
            />
          ))}
        </div>
      </PopoverLikePopper>
    </div>
  );
};

export default DashboardProfilesPopover;
