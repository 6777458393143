import { FilterBuilderDialog } from '@/components/filter-builder/FilterBuilderDialog';
import { FilterChipRow } from '@/components/filter-builder/FilterChipRow';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { AlFilterModel, SearchTermFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import GridTools from '@/components/grid/components/GridTools';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Button, Card, Divider } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, useRef, useState } from 'react';
import { SearchTermsWithTimeline } from '../api/search-terms-contracts';
import { useSearchTermsContext } from '../contexts/SearchTermsContext';
import useAvailableSearchTermFilters from '../hooks/useAvailableSearchTermFilters';

interface SearchTermsFilterBarProps {
  withTimeline: SearchTermsWithTimeline | undefined;
  gridApiRef: React.MutableRefObject<GridApi<unknown> | null>;
}

const SearchTermsFilterBar: FunctionComponent<SearchTermsFilterBarProps> = ({ withTimeline, gridApiRef }) => {
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false); // state to control the popover
  const { filters, setFilters, setFilterValue, setFilterValues, gridToggles, setGridToggles } = useSearchTermsContext();

  const { getComparisonDataMissingMessage } = useComparisonMissing({});

  const onFilterChipClicked = () => {
    setIsFilterDialogOpen((previousValue) => !previousValue);
  };

  const onFilterDeleteButtonClicked = (filter: AlFilterModel) => {
    setFilters((previousFilters) => previousFilters.filter((f) => f.key !== filter.key));
  };

  const { availableSearchTermFilters } = useAvailableSearchTermFilters();

  function onFiltersChanged(newFilters: AlFilterModel[]) {
    setFilters(newFilters);
  }

  return (
    <Card className="flex flex-row items-center gap-x-4 p-2 pb-3 rounded-none rounded-tr-xl rounded-tl-xl -mb-1 relative overflow-visible">
      <SearchBox
        filterKey={FilterKey.SEARCH_TERM}
        newFilterModel={() => new SearchTermFilterModel()}
        placeholder="Search Term"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />

      <div className="flex flex-row">
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={onFilterChipClicked}
          startIcon={<FilterListRoundedIcon />}
          ref={filtersButtonRef}
          className="mr-2 flex-shrink-0"
        >
          Adjust Filters
        </Button>

        <FilterBuilderDialog
          buttonRef={filtersButtonRef}
          setIsOpen={setIsFilterDialogOpen}
          isOpen={isFilterDialogOpen}
          availableFilters={availableSearchTermFilters}
          defaultFilterKey={FilterKey.CAMPAIGN_ID}
          appliedFilters={filters}
          onFiltersChanged={onFiltersChanged}
        />

        <FilterChipRow
          filters={filters}
          onFilterDeleteButtonClicked={onFilterDeleteButtonClicked}
          onFilterChipClicked={onFilterChipClicked}
        />
      </div>
      <div className="flex flex-grow"></div>
      <div className="flex h-full flex-col justify-center items-center">
        <DateRangeButton
          title="Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to stats"
          comparisonRangeWarning={getComparisonDataMissingMessage(withTimeline?.isComparisonDataMissing ?? false)}
        />
      </div>

      <Divider className="my-4 " orientation="vertical" flexItem />
      <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} />
    </Card>
  );
};

export default SearchTermsFilterBar;
