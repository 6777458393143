import DateRangeButtonBase from '@/components/buttons/DateRangeButtonBase';
import { DATE_FORMAT } from '@/components/filter-builder/FiltersConfig';
import { DateFilterModel, LogicalOperatorType, OperatorType } from '@/components/filter-builder/models/AlFilterModel';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import Tooltip from '@mui/material/Tooltip';
import dayjs, { Dayjs } from 'dayjs';
import { FunctionComponent, useRef, useState } from 'react';
import { DateRangePickerPopover } from './DateRangePickerPopover';

// TODO: Replace with DateRangeButton
interface DateRangePickerButtonProps {
  isDisabled?: boolean;
  dates: Dayjs[];
  comparisonDates: Dayjs[];
  onSetDates: (dates: Dayjs[], comparisonDates: Dayjs[]) => void;
  disablePaywall?: boolean;
  comparisonRangeWarningText?: string;
}

const DateRangePickerButton: FunctionComponent<DateRangePickerButtonProps> = ({
  isDisabled = false,
  dates,
  comparisonDates,
  onSetDates,
  disablePaywall,
  comparisonRangeWarningText,
}) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { isAdminModeActive } = useUserContext();

  const dateRangeButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isDateRangePopoverOpen, setIsDateRangePopoverOpen] = useState(false); // state to control the popover

  const onDateRangeButtonClicked = () => {
    if (activeTeam?.hasProPlan || disablePaywall || isAdminModeActive) {
      setIsDateRangePopoverOpen(true);
    } else {
      setIsPaywallModalOpen(true);
    }
  };

  const dateFilter = new DateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [dates[0].format(DATE_FORMAT)],
        operator: OperatorType.GREATER_THAN_OR_EQUAL,
      },
      {
        values: [dates[1].format(DATE_FORMAT)],
        operator: OperatorType.LESS_THAN_OR_EQUAL,
      },
    ],
  });

  const comparisonDateFilter = new DateFilterModel({
    logicalOperator: LogicalOperatorType.AND,
    conditions: [
      {
        values: [comparisonDates[0].format(DATE_FORMAT)],
        operator: OperatorType.GREATER_THAN_OR_EQUAL,
      },
      {
        values: [comparisonDates[1].format(DATE_FORMAT)],
        operator: OperatorType.LESS_THAN_OR_EQUAL,
      },
    ],
  });

  const dateRange = dateFilter.conditions;
  const comparisonDateRange = comparisonDateFilter.conditions;

  function onSubmit(filters: DateFilterModel[]) {
    const dateConditions = filters[0].conditions;
    const comparisonConditions = filters[1].conditions;
    if (dateConditions && comparisonConditions) {
      onSetDates(
        [dayjs(dateConditions[0].values[0]), dayjs(dateConditions[1].values[0])],
        [dayjs(comparisonConditions[0].values[0]), dayjs(comparisonConditions[1].values[0])],
      );
    }
  }

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  const minDate = activeProfile?.createdAt ? dayjs(activeProfile?.createdAt).subtract(59, 'days').format(DATE_FORMAT) : undefined;

  return (
    <div className="flex">
      <Tooltip title="Date range used to calculate metrics">
        <span>
          <DateRangeButtonBase
            buttonRef={dateRangeButtonRef}
            isDisabled={isDisabled}
            onDateRangeButtonClicked={onDateRangeButtonClicked}
            dateRange={dateRange}
            comparisonDateRange={comparisonDateRange}
            comparisonRangeWarning={comparisonRangeWarningText}
          />
        </span>
      </Tooltip>
      <DateRangePickerPopover
        title="Select Dates"
        buttonRef={dateRangeButtonRef}
        setIsOpen={setIsDateRangePopoverOpen}
        isOpen={isDateRangePopoverOpen}
        onSubmit={onSubmit}
        defaultDateRange={dateRange}
        defaultComparisonDateRange={comparisonDateRange}
        disablePaywall={disablePaywall}
        minDateString={minDate}
      />
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={onClosePaywallModal}
        returnURLPath={Routes.PROFILES_STATS}
        headerText="Upgrade to Pro to set custom date range"
      ></PaywallModal>
    </div>
  );
};

export default DateRangePickerButton;
