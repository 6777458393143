import AlGrid, { DEFAULT_GRID_OPTIONS, GRID_DEFAULT_SORTING_ORDER } from '@/components/grid/AlGrid';
import ButtonGroupCellRenderer, { IButtonGroupCellRendererParams } from '@/components/grid/cells/ButtonGroupCellRenderer';
import ChipArrayCellRenderer, { IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import { ITextCellRendererParams, TextCellRenderer } from '@/components/grid/cells/TextCellRenderer';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import RowActionButton from '@/components/grid/components/RowActionButton';
import DefaultHeaderRenderer from '@/components/grid/headers/DefaultHeaderRenderer';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import { TailwindColorVariant } from '@/config/theme/color.type';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { useTranslation } from '@/lib';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { UserSettingKey } from '@/modules/users';
import { getColorForText } from '@/types/colors.enum';
import { Delete, Settings } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { dataGroupsService } from '../api/data-groups-service';
import { DataGroupModel } from '../models/DataGroup';
import { DataItemModel } from '../models/DataItem';
import { DataGroupType } from '../models/data-groups-contracts';
import EditDataGroupSelectionModal from './EditDataGroupSelectionModal';
import { generateDataGroupDefaultColumnState } from './data-group.default-column-state';

interface DataGroupsTableProps {
  rowData: DataGroupModel[] | undefined;
  isLoading: boolean;
  refetch: () => void;
}

const DataGroupsTable: FunctionComponent<DataGroupsTableProps> = ({ rowData, isLoading, refetch }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { setColumnStateGridApi, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.DATA_GROUPS_TABLE_COLUMN_STATE,
    generateDataGroupDefaultColumnState(),
  );

  // EDIT GROUP
  const [toBeEditedDataGroup, setToBeEditedDataGroup] = useState<DataGroupModel | null>(null);
  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);

  const onEditClicked = (params: ICellRendererParams) => {
    setToBeEditedDataGroup((): DataGroupModel | null => {
      if (!params.data) {
        return null;
      }

      return new DataGroupModel({
        id: params.data.id,
        name: params.data.name,
        type: params.data.type,
        items: params.data.items.map((item: DataItemModel) => new DataItemModel({ id: item.id, name: item.name })),
      });
    });
    setIsEditGroupModalOpen(true);
  };

  const onUpdateGroupSucceeded = (dataGroupType: DataGroupType) => {
    refetch();

    dataGroupsService.invalidateEntitiesContainingDataGroupsByType(dataGroupType, queryClient);

    toast.success('Data group updated successfully');
  };

  const onEditModalClose = () => {
    setIsEditGroupModalOpen(false);
    setTimeout(() => setToBeEditedDataGroup(null), 300); // Let the modal closing animation finish
  };

  // DELETE GROUP
  const [toBeDeletedDataGroup, setToBeDeletedDataGroup] = useState<DataGroupModel | null>(null);
  const [isLoadingDeleteGroup, setIsLoadingDeleteGroup] = useState(false);
  const onDeleteClicked = (params: ICellRendererParams) => {
    setToBeDeletedDataGroup((): DataGroupModel | null => {
      if (!params.data) return null;
      return new DataGroupModel({
        id: params.data.id,
        name: params.data.name,
        type: params.data.type,
        items: params.data.items.map((item: DataItemModel) => new DataItemModel({ id: item.id, name: item.name })),
      });
    });
    openDeleteConfirmationModal();
  };

  const onDeleteConfirmed = async () => {
    if (!toBeDeletedDataGroup) return;

    setIsLoadingDeleteGroup(true);

    const response = await dataGroupsService.deleteGroup(toBeDeletedDataGroup.id);
    setIsLoadingDeleteGroup(false);
    if (response.isSuccess === false) {
      toast.error(response.message);
      return;
    }
    refetch();

    toast.success(`Data group ${toBeDeletedDataGroup.name} deleted successfully`);

    dataGroupsService.invalidateEntitiesContainingDataGroupsByType(toBeDeletedDataGroup.type, queryClient);
  };

  const { ModalComponent: DeleteConfirmationModal, handleOpenModal: openDeleteConfirmationModal } = useDeleteConfirmation({
    headerText: `Delete group '${toBeDeletedDataGroup?.name}'?`,
    questionText: `Are you sure you want to delete group '${toBeDeletedDataGroup?.name}' with ${toBeDeletedDataGroup?.items.length} value${
      toBeDeletedDataGroup && toBeDeletedDataGroup?.items.length > 1 ? 's' : ''
    }?`,
    onDelete: onDeleteConfirmed,
  });

  const columnDefs: ColDefOrGroup<DataGroupModel>[] = useMemo(() => {
    const colDefs: ColDefOrGroup<DataGroupModel>[] = [
      {
        colId: ColumnId.TYPE,
        headerName: 'Type',
        field: 'type',
        width: 100,
        cellRenderer: TextCellRenderer,
        cellRendererParams: (): ITextCellRendererParams => {
          return {
            valueToString(enumKey) {
              return t(`optimizer_page.data_group_type.${enumKey}`);
            },
            valueToColor(enumKey) {
              switch (enumKey) {
                case DataGroupType.CAMPAIGN:
                  return TailwindColorVariant.BLUE;
                case DataGroupType.TARGET:
                default:
                  return TailwindColorVariant.GREEN;
              }
            },
          };
        },
      },
      {
        colId: ColumnId.NAME,
        headerName: 'Name',
        field: 'name',
        width: 100,
      },
      {
        colId: ColumnId.DATA_ITEMS,
        headerName: 'Values',
        width: 150,
        field: 'items',
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<DataGroupModel>): IChipArrayCellRendererParams => {
          return {
            maxChipsToShow: 99,
            chipArrayChips: params.data?.items
              ? params.data.items.map((item: DataItemModel) => {
                  return {
                    value: item.name,
                    color: getColorForText(item.name),
                  };
                })
              : [],
          };
        },
        valueGetter: (params: ValueGetterParams<DataGroupModel>) => {
          return params.data?.items.map((item: DataItemModel) => item.name).join(', ') ?? '';
        },
      },
      {
        colId: ColumnId.ACTIONS,
        headerName: 'Actions',
        width: 255,
        pinned: 'right',
        cellRenderer: ButtonGroupCellRenderer,
        cellRendererParams: (params: ICellRendererParams<DataGroupModel>): IButtonGroupCellRendererParams => {
          return {
            buttons: [
              <RowActionButton
                color="default"
                key="edit"
                text="Edit"
                tooltipText={`Edit '${params.data?.name}'`}
                onClick={() => onEditClicked(params)}
                icon={<Settings />}
              ></RowActionButton>,
              // TODO: isLoadingDeleteGroup doesn't do anything because it can't be accessed
              <RowActionButton
                key="delete"
                text="Delete"
                isLoadingText="Deleting..."
                color="red"
                isDisabled={isLoadingDeleteGroup}
                tooltipText={`Delete '${params.data?.name}'`}
                isLoading={isLoadingDeleteGroup}
                onClick={() => onDeleteClicked(params)}
                icon={<Delete />}
              ></RowActionButton>,
            ],
          };
        },
      },
    ];
    applyStateToDefinitions(colDefs);

    return colDefs;
  }, []);

  // TODO: remove defaultColDef?
  const customGridOptions: GridOptions<DataGroupModel> = {
    ...DEFAULT_GRID_OPTIONS,
    getRowId: (params) => params.data.id.toString(),
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 40,
      headerComponent: DefaultHeaderRenderer,
      sortingOrder: GRID_DEFAULT_SORTING_ORDER,
    },
    onColumnMoved: handleColumnStateChange,
    onColumnVisible: handleColumnStateChange,
    onColumnResized: handleColumnStateChange,
    onColumnRowGroupChanged: handleColumnStateChange,
    onSortChanged: handleColumnStateChange,
    onColumnPinned: handleColumnStateChange,
  };

  const onGridReady = (params: GridReadyEvent<DataGroupModel>) => {
    setColumnStateGridApi(params.api);
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      <div className="flex flex-grow">
        <AlGrid
          colDefs={columnDefs}
          rowData={rowData}
          gridOptions={customGridOptions}
          isLoading={isLoading}
          onGridReadyCallback={onGridReady}
        />
      </div>

      {/* Need conditional rendering to ensure the editableDataGroup is there on modal init */}
      {toBeEditedDataGroup && (
        <EditDataGroupSelectionModal
          editableDataGroup={toBeEditedDataGroup}
          isOpen={isEditGroupModalOpen}
          onClose={onEditModalClose}
          onApplySucceeded={onUpdateGroupSucceeded}
          existingGroups={rowData ?? []}
        />
      )}

      {DeleteConfirmationModal}
    </>
  );
};

export default DataGroupsTable;
