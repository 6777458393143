import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultTargetFilters } from '@/components/filter-builder/models/AlFilterModel';
import VideoModal from '@/components/modals/video-modal/VideoModal';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { VideoUrl } from '@/config/urls';
import useGlobalLoadingStateObserver from '@/hooks/useGlobalLoadingStateObserver';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { ShowPaywallParams, usePaywall } from '@/modules/plans/hooks/usePaywall';
import { createTargetingWithTimelineQueryKey, targetingService } from '@/modules/targeting/api/targets-service';
import { SelectedTarget } from '@/modules/targeting/models/TargetsModel';
import { PreferredTimePicker } from '@/modules/teams/components/PreferredTimePicker';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey, useUserContext } from '@/modules/users';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Alert, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import TargetingFilterBar from '../components/TargetingFilterBar';
import TargetingMetrics from '../components/TargetingMetrics';
import TargetingSelectionActionsBar from '../components/TargetingSelectionActionsBar';
import TargetingTimelineChart from '../components/TargetingTimelineChart';
import TargetingTable from '../components/targeting-table/TargetingTable';
import { TARGETING_CONTEXT_KEY, TargetingProvider } from '../contexts/TargetingContext';

let isPaywallDisplayedBefore = false;

const TargetingPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { showPaywallWithDelay } = usePaywall();
  const { refetchUser } = useUserContext();

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(TARGETING_CONTEXT_KEY, activeProfile?.id ?? '', getDefaultTargetFilters());
  });
  const [selectedTargets, setSelectedTargets] = useState<SelectedTarget[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  // TARGETS
  const targetingTableGridApi = useRef<GridApi | null>(null);
  function clearSelections() {
    setSelectedTargets([]);
    if (targetingTableGridApi.current && !targetingTableGridApi.current.isDestroyed()) {
      targetingTableGridApi.current.deselectAll();
    }
  }

  const targetsWithTimelineQueryKey = createTargetingWithTimelineQueryKey(activeProfile?.id, filters);
  const {
    data: targetsWithTimeline,
    isLoading: isTargetsRowDataLoading,
    isError: isTargetsLoadingError,
    error: targetsLoadingError,
    isFetching,
  } = useQuery({
    queryKey: targetsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await targetingService.getTargetsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading targeting ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(filters) && !isEmpty(activeProfile?.id),
  });

  useGlobalLoadingStateObserver('isFetching targeting', isFetching);

  function onTargetingTableGridReady(params: GridReadyEvent) {
    targetingTableGridApi.current = params.api;
  }

  // Paywall
  useEffect(() => {
    const paywallDelay = isPaywallDisplayedBefore ? 0 : 10;
    const paywallParams: ShowPaywallParams = {
      message: 'Upgrade to Pro to view Targets',
      videoUrl: VideoUrl.TARGETING,
      delayInSeconds: paywallDelay,
    };

    if (hasUserOverMaxAllowedFreeProfiles()) {
      showPaywallWithDelay(paywallParams);
      isPaywallDisplayedBefore = true;
    }

    if (isNil(activeTeam)) {
      toast.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canUseTargetingPage) {
      showPaywallWithDelay(paywallParams);
      isPaywallDisplayedBefore = true;
      return;
    }
  }, []);

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(UserSettingKey.TARGETING_TUTORIAL_DISPLAY_COUNT);

  const isDataLimitReached = targetsWithTimeline?.limitReached ?? false;
  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={
            <div className="flex flex-row items-center gap-4">
              {t('targeting_page.header')}
              <Button onClick={() => openTutorialModal()} variant="contained" startIcon={<OndemandVideoIcon />}>
                How to Use Targeting
              </Button>
            </div>
          }
          actions={
            <div className="flex flex-row items-center gap-2">
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <PreferredTimePicker isEmbeddedInTopBar onSuccess={() => refetchUser()} />
              <TeamSelect />
              <ProfileSyncSelectButton setFilters={setFilters} />
            </div>
          }
        />

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding>
            <TargetingProvider filters={filters} setFilters={setFilters}>
              <div className="mt-2">
                <AlErrorBoundary>
                  <TargetingMetrics />
                </AlErrorBoundary>
              </div>

              {isDataLimitReached && (
                <Alert className="py-4 mt-2" severity="info">
                  <div>
                    Timeline Chart and Data Table limited to 250,000 targets, sorted by spend. Apply filters to reduce the number of displayed
                    targets.
                  </div>
                </Alert>
              )}

              <div className="my-2">
                <AlErrorBoundary>
                  <TargetingTimelineChart />
                </AlErrorBoundary>
              </div>

              <AlErrorBoundary>
                <TargetingFilterBar withTimeline={targetsWithTimeline} gridApiRef={targetingTableGridApi} />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <TargetingTable
                  withTimeline={targetsWithTimeline}
                  isLoading={isTargetsRowDataLoading}
                  selectedTargets={selectedTargets}
                  setSelectedTargets={setSelectedTargets}
                  targetingLoadingErrorMessage={targetsLoadingError instanceof Error ? targetsLoadingError.message : ''}
                  isTargetingLoadingError={isTargetsLoadingError}
                  onGridReadyCallback={onTargetingTableGridReady}
                  noTopBorderRadius={true}
                />
              </AlErrorBoundary>
            </TargetingProvider>
          </PageLayoutBody>
        )}
      </PageLayout>
      <AlErrorBoundary>
        <TargetingSelectionActionsBar
          selectedTargets={selectedTargets}
          totalTargetsCount={targetsWithTimeline?.targets ? targetsWithTimeline.targets.length : 0}
          onDiscardClicked={clearSelections}
        />
      </AlErrorBoundary>

      <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={VideoUrl.TARGETING}
        title={'How to Use the Targeting Tab'}
      />
    </>
  );
};

export default TargetingPage;
