import { ColumnId } from '@/components/grid/columns/columns.enum';
import { Environment } from '@/config/Environment';
import useFormatting from '@/hooks/useFormatting';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { GleapWorkflowType } from '@/lib/gleap';
import { HelpOutline } from '@mui/icons-material';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import Gleap from 'gleap';
import { FunctionComponent, useRef, useState } from 'react';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import OptimizationEditSelectionPopover, {
  BidUpdateData,
  BidUpdateType,
  PlacementUpdateData,
  PlacementUpdateType,
  TotalUpdateData,
} from './optimization/OptimizationEditSelectionPopover';
import { OptimizationApplyData } from './optimization/OptimizerConfig';
import useBidLimits from './optimization/bid-limits';
import { BiddingEntity, OptimizationModel } from './optimization/models/OptimizationModel';
import { PreviewDataRow } from './optimization/models/PreviewDataRow';

interface OptimizerPreviewActionsBarProps {
  optimizationPreviewSelection: OptimizationApplyData[];
  visibleRowCount: number;
  onApply: () => void;
  isApplyLoading: boolean;
  optimizationResults: OptimizationModel;
  optimizationTableApi: GridApi<PreviewDataRow> | null;
  setOptimizationPreviewSelection: (optimizationApplyData: OptimizationApplyData[]) => void;
}

const OptimizerPreviewActionsBar: FunctionComponent<OptimizerPreviewActionsBarProps> = ({
  optimizationPreviewSelection,
  visibleRowCount,
  onApply,
  isApplyLoading,
  optimizationResults,
  optimizationTableApi,
  setOptimizationPreviewSelection,
}) => {
  const { formatWithThousandsSeparator } = useFormatting();
  const { toastWarnWithSetMessages } = useHelperComponents();

  //Gleap stuff
  const startGleapFeedbackFlow = (workflowToStart: GleapWorkflowType = GleapWorkflowType.Default) => {
    if (workflowToStart === GleapWorkflowType.BugReport) {
      Gleap.startFeedbackFlow(GleapWorkflowType.BugReport, true);
    } else {
      Gleap.open();
    }
  };

  // CONFIRMATION CHECKBOX
  const [isConfirmationCheckboxChecked, setIsConfirmationCheckboxChecked] = useState(false);
  const handleConfirmationCheckboxChange = () => {
    setIsConfirmationCheckboxChecked((prevState) => !prevState);
  };

  // BULK EDIT
  const { getNewBidValue_byCurrentProfileMarketplaceLimits } = useBidLimits();
  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isEditSelectionModalOpen, setIsEditSelectionModalOpen] = useState(false);
  function onEditSelectionClicked() {
    setIsEditSelectionModalOpen(true);
  }

  function onApplySelectionEdit(updateData: TotalUpdateData) {
    // Apply updates to preview data rows
    optimizationResults.previewData = applyUpdatesToPreviewDataRows(
      optimizationResults.previewData,
      updateData,
      optimizationPreviewSelection,
    );

    // Refresh edited cells
    if (optimizationTableApi) {
      optimizationTableApi.refreshCells({
        columns: [ColumnId.NEW_VALUE, ColumnId.DELTA],
      });
    }

    // Update the selection
    setOptimizationPreviewSelection(
      optimizationPreviewSelection.map((optimizationApplyData) => {
        const adjustedPreviewData = optimizationResults.previewData.find(
          (pd) => optimizationApplyData.id === pd.id && optimizationApplyData.bidding_entity === pd.biddingEntity,
        );
        return {
          ...optimizationApplyData,
          new_value: adjustedPreviewData?.newValue ?? optimizationApplyData.new_value,
        };
      }),
    );
  }

  function applyUpdatesToPreviewDataRows(
    previewDataRows: PreviewDataRow[],
    updateData: TotalUpdateData,
    selectedRows: OptimizationApplyData[],
  ): PreviewDataRow[] {
    if (Environment.isDev()) {
      console.log({
        previewDataRows,
        updateData,
        selectedRows,
      });
    }

    const warnings = new Set<string>();

    const modifiedRows = previewDataRows.map((row) => {
      if (selectedRows.some((selectedRow) => selectedRow.id === row.id && selectedRow.bidding_entity === row.biddingEntity)) {
        switch (row.biddingEntity) {
          case BiddingEntity.KEYWORD:
          case BiddingEntity.PRODUCT_TARGET:
            applyBidUpdate(row, updateData.bidUpdateData, warnings);
            break;
          default:
            applyPlacementUpdate(row, updateData.placementUpdateData);
            break;
        }
      }
      return row;
    });

    if (warnings.size > 0) {
      toastWarnWithSetMessages(warnings);
    }

    return modifiedRows;
  }

  function applyBidUpdate(row: PreviewDataRow, updateData: BidUpdateData, warnings: Set<string>) {
    let newValue;
    switch (updateData.bidUpdateType) {
      case BidUpdateType.SET_BID_TO_AMOUNT:
        newValue = updateData.newBidValue;
        break;
      case BidUpdateType.INCREASE_BID_BY_AMOUNT:
        newValue = row.oldValue + updateData.newBidValue;
        break;
      case BidUpdateType.DECREASE_BID_BY_AMOUNT:
        newValue = row.oldValue - updateData.newBidValue;
        break;
      case BidUpdateType.INCREASE_BID_BY_PERCENTAGE:
        newValue = row.oldValue * (1 + updateData.newBidValue / 100);
        break;
      case BidUpdateType.DECREASE_BID_BY_PERCENTAGE:
        newValue = row.oldValue * (1 - updateData.newBidValue / 100);
        break;
      case BidUpdateType.NO_CHANGE:
      default:
        return;
    }

    const clampedValue = getNewBidValue_byCurrentProfileMarketplaceLimits(newValue, row.campaignAdType, row.campaignIsVideo, warnings);
    row.newValue = clampedValue;
  }

  function applyPlacementUpdate(row: PreviewDataRow, updateData: PlacementUpdateData) {
    switch (updateData.placementUpdateType) {
      case PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE:
        row.newValue = updateData.newPlacementValue / 100;
        break;
      case PlacementUpdateType.INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS:
        row.newValue = row.oldValue + updateData.newPlacementValue / 100;
        break;
      case PlacementUpdateType.DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS:
        row.newValue = row.oldValue - updateData.newPlacementValue / 100;
        break;
      // No need for NO_CHANGE as it does nothing
    }
  }

  // HANDLE SELECTION
  function onDeselectAll() {
    setOptimizationPreviewSelection([]);
    if (optimizationTableApi) {
      optimizationTableApi.deselectAll();
    }
  }

  function onSelectAll() {
    if (optimizationTableApi) {
      optimizationTableApi.selectAll();
    }
  }
  return (
    <>
      <OppositeModeOverlayBar>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <span onClick={onDeselectAll} className="flex whitespace-nowrap text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(optimizationPreviewSelection.length)} of {formatWithThousandsSeparator(visibleRowCount)}
            </span>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <Button
            variant={isEditSelectionModalOpen ? 'contained' : 'outlined'}
            ref={bulkEditButtonRef}
            onClick={onEditSelectionClicked}
            startIcon={<EditRoundedIcon />}
            disabled={optimizationPreviewSelection.length === 0}
            className="mr-2 flex-shrink-0"
          >
            {optimizationPreviewSelection.length > 0
              ? `Edit ${formatWithThousandsSeparator(optimizationPreviewSelection.length)} item${optimizationPreviewSelection.length > 1 ? 's' : ''}`
              : 'Edit Selection'}
          </Button>

          <Divider className="my-2" orientation="vertical" flexItem />

          <Button
            onClick={() => startGleapFeedbackFlow(GleapWorkflowType.Default)}
            variant="outlined"
            className="whitespace-nowrap px-4"
            startIcon={<HelpOutline />}
          >
            I need help
          </Button>

          <Button
            onClick={() => startGleapFeedbackFlow(GleapWorkflowType.BugReport)}
            variant="outlined"
            className="whitespace-nowrap px-4"
            startIcon={<BugReportOutlinedIcon />}
          >
            Report a bug
          </Button>

          <Divider className="my-2" orientation="vertical" flexItem />

          <Button
            className="whitespace-nowrap"
            onClick={optimizationPreviewSelection.length == visibleRowCount ? onDeselectAll : onSelectAll}
            variant="text"
          >
            {optimizationPreviewSelection.length == visibleRowCount ? 'Deselect All' : 'Select All'}
          </Button>

          <span onClick={handleConfirmationCheckboxChange} className="flex whitespace-nowrap text-sm items-center hover:cursor-pointer">
            <Checkbox checked={isConfirmationCheckboxChecked} name="confirmationCheckbox" color="secondary" />I confirm changes
          </span>

          <Tooltip title="Send changes to Amazon">
            <span>
              <LoadingButton
                onClick={onApply}
                endIcon={<SendIcon />}
                loading={isApplyLoading}
                loadingPosition="end"
                variant="contained"
                disabled={!isConfirmationCheckboxChecked || optimizationPreviewSelection.length == 0}
                color="primary"
                className="whitespace-nowrap"
              >
                <span>Apply Changes</span>
              </LoadingButton>
            </span>
          </Tooltip>
        </div>
      </OppositeModeOverlayBar>

      <OptimizationEditSelectionPopover
        selectedItems={optimizationPreviewSelection}
        isOpen={isEditSelectionModalOpen}
        onClose={() => setIsEditSelectionModalOpen(false)}
        onApply={onApplySelectionEdit}
        buttonRef={bulkEditButtonRef}
      />
    </>
  );
};

export default OptimizerPreviewActionsBar;
