import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import { createSearchTermsMetricsQueryKey, metricsService } from '@/components/metrics/api/metrics-service';
import { MetricAggregates } from '@/components/metrics/types/MetricField';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { toast } from 'react-toastify';
import { SearchTermsGridContext } from '../components/search-terms-table/SearchTermsTable';
import { useSearchTermsContext } from '../contexts/SearchTermsContext';
import { SearchTermModel } from '../models/SearchTermModel';

interface useSearchTermsMetricColumnAggregatesProps {
  gridApiRef: React.MutableRefObject<GridApi<SearchTermModel> | undefined>;
  gridContextRef: React.MutableRefObject<SearchTermsGridContext | undefined>;
}

const useSearchTermsMetricColumnAggregates = ({ gridApiRef, gridContextRef }: useSearchTermsMetricColumnAggregatesProps) => {
  const { filters } = useSearchTermsContext();
  const { activeProfile } = useActiveTeamContext();

  const {
    data: metricColumnAggregates,
    isLoading: isMetricsLoading,
    error: metricsError,
  } = useQuery({
    queryKey: createSearchTermsMetricsQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await metricsService.getSearchTermsMetrics(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading metric values');
      }
    },
    select: (metricValues): MetricAggregates => {
      return isNil(metricValues)
        ? ({} as MetricAggregates)
        : metricValues.reduce((aggregates, metric) => {
            aggregates[metric.key] = { current: metric.current };
            return aggregates;
          }, {} as MetricAggregates);
    },
    enabled: !isEmpty(filters),
  });

  const { onGridReadyForMetricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates,
  });

  return { metricColumnAggregates, isMetricsLoading, metricsError, onGridReadyForMetricColumnAggregates };
};

export default useSearchTermsMetricColumnAggregates;
