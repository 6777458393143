// useTranslation.ts
import { useEffect, useState } from 'react';
import i18n from './i18nConfig';

export function useTranslation() {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const onLanguageChanged = (lng: string) => {
      setLanguage(lng);
    };

    i18n.on('languageChanged', onLanguageChanged);
    return () => {
      i18n.off('languageChanged', onLanguageChanged);
    };
  }, []);

  function t(key: string) {
    const translated = i18n.t(key);
    if (translated === key) {
      console.error(`Missing translation: ${key}`);
    }
    return translated;
  }

  function tWithFallback(key: string, fallback: string | null) {
    const translated = i18n.t(key);
    if (translated === key) {
      return fallback;
    }
    return translated;
  }
  return {
    t,
    tWithFallback,
    language,
    changeLanguage: (lng: string) => {
      i18n.changeLanguage(lng);
    },
  };
}
