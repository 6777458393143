import { MetricDTO } from '@/components/metrics/api/metrics-contracts';
import { UpdatedEntityDTO } from '@/modules/application/types/UpdatedEntity';
import { CampaignAdType, PlacementType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { OptimizationPreset } from '../OptimizerConfig';
import { BiddingEntity } from '../models/OptimizationModel';

export interface OptimizationResponse {
  job_id: number;
  preview_data: ResponsePreviewDataRow[];
  debug_data: ResponseAdGroupDebugRow[];
  campaign_debug_data: ResponseCampaignDebugRow[];
  campaign_group_debug_data: ResponseCampaignDebugRow[];
  profile_debug_data: ResponseCampaignDebugRow;
  ad_type_totals_debug_data: ResponseCampaignDebugRow[];
  comparison_missing: boolean;
}

// Needs to be kept in sync with MatchTypeFilterModel
export enum MatchType {
  NONE = '',
  AUTO = 'AUTO',
  EXACT = 'EXACT',
  INDIVIDUAL = 'INDIVIDUAL',
  EXPANDED = 'EXPANDED',
  CATEGORY = 'CATEGORY',
  PHRASE = 'PHRASE',
  BROAD = 'BROAD',
  THEME = 'THEME',
}

export const FieldNameToMatchTypeRecord: Record<string, MatchType> = {
  exactMatch: MatchType.EXACT,
  broadMatch: MatchType.BROAD,
  phraseMatch: MatchType.PHRASE,
  individualProductTarget: MatchType.INDIVIDUAL,
  expandedProductTarget: MatchType.EXPANDED,
};

export interface ResponsePreviewDataRow {
  i: string;
  at: CampaignAdType;
  ci: string;
  cn: string;
  cg: string;
  b: BiddingEntity;
  t: string;
  m: MatchType;
  ag: string;
  ov: number;
  nv: number;
  lo: string | null;
  rs: OptimizationReason[];
  v: boolean | null; // is video or brands video
  impressions: MetricDTO;
  clicks: MetricDTO;
  orders: MetricDTO;
  units: MetricDTO;
  ctr: MetricDTO;
  cvr: MetricDTO;
  cpc: MetricDTO;
  spend: MetricDTO;
  sales: MetricDTO;
  sso: MetricDTO;
  sss: MetricDTO;
  acos: MetricDTO;
  roas: MetricDTO;
  rpc: MetricDTO;
  cpa: MetricDTO;
  aov: MetricDTO;
  cpm: MetricDTO;
  gt: number;
  gp: OptimizationPreset;
  gbc: number;
}

export interface ResponseDebugDataRow {
  //TODO: extends CommonMetrics
  tcpa: MetricDTO;
  tcpc: MetricDTO;
  rpc_inherited: string;
  aov_inherited: string;
  actc_inherited: string;
  impressions: MetricDTO;
  clicks: MetricDTO;
  orders: MetricDTO;
  units: MetricDTO;
  ctr: MetricDTO;
  cvr: MetricDTO;
  cpc: MetricDTO;
  spend: MetricDTO;
  sales: MetricDTO;
  acos: MetricDTO;
  roas: MetricDTO;
  rpc: MetricDTO;
  cpa: MetricDTO;
  aov: MetricDTO;
  cpm: MetricDTO;
}

export interface ResponseCampaignDebugRow {
  campaign_name: string;
  ad_type: string;
  placement_mod: number;

  stats: ResponseDebugDataRowWithPlacement[];
}

export interface ResponseDebugDataRowWithPlacement extends ResponseDebugDataRow {
  placement_type: PlacementType;
}

export interface ResponseAdGroupDebugRow {
  ad_group: string;
  tcpa: MetricDTO;
  tcpc: MetricDTO;
  rpc_inherited: string;
  aov_inherited: string;
  actc_inherited: string;
  impressions: MetricDTO;
  clicks: MetricDTO;
  orders: MetricDTO;
  units: MetricDTO;
  ctr: MetricDTO;
  cvr: MetricDTO;
  cpc: MetricDTO;
  spend: MetricDTO;
  sales: MetricDTO;
  acos: MetricDTO;
  roas: MetricDTO;
  rpc: MetricDTO;
  cpa: MetricDTO;
  aov: MetricDTO;
  cpm: MetricDTO;
}

export enum EnabledKeywordGroups {
  LOW_VISIBILITY = 'LOW_VISIBILITY',
  LOW_ACOS = 'LOW_ACOS',
  HIGH_ACOS = 'HIGH_ACOS',
  HIGH_SPEND_NO_SALES = 'HIGH_SPEND_NO_SALES',
}

export enum OptimizationReason {
  // Keyword rules
  LOW_VISIBILITY = 'LOW_VISIBILITY',
  HIGH_ACOS = 'HIGH_ACOS',
  HIGH_SPEND = 'HIGH_SPEND',
  LOW_ACOS = 'LOW_ACOS',

  // Limit rules
  MAX_BID_DECREASE = 'MAX_BID_DECREASE',
  USER_BID_CEILING = 'USER_BID_CEILING',
  KEYWORD_BID_CEILING = 'KEYWORD_BID_CEILING',
  AD_GROUP_BID_CEILING = 'AD_GROUP_BID_CEILING',
  CAMPAIGN_BID_CEILING = 'CAMPAIGN_BID_CEILING',
  CAMPAIGN_GROUP_BID_CEILING = 'CAMPAIGN_GROUP_BID_CEILING',
  PROFILE_BID_CEILING = 'PROFILE_BID_CEILING',
  LOWEST_POSSIBLE_BID = 'LOWEST_POSSIBLE_BID',
  SMALLEST_POSSIBLE_INCREASE = 'SMALLEST_POSSIBLE_INCREASE',

  CAMPAIGN_PERFORMANCE = 'CAMPAIGN_PERFORMANCE',
  CAMPAIGN_GROUP_PERFORMANCE = 'CAMPAIGN_GROUP_PERFORMANCE',
  PROFILE_PERFORMANCE = 'PROFILE_PERFORMANCE',

  MAX_ONE_TIME_CHANGE = 'MAX_ONE_TIME_CHANGE',
  LOWEST_POSSIBLE_ADJUSTMENT = 'LOWEST_POSSIBLE_ADJUSTMENT',
  HIGHEST_POSSIBLE_ADJUSTMENT = 'HIGHEST_POSSIBLE_ADJUSTMENT',
}

export interface OptimizationRequestDTO {
  dates: {
    start_date: string;
    end_date: string;
  };
  compare_dates: {
    start_date: string;
    end_date: string;
  };
  campaign_ids: string[];
  tacos: number;
  preset: OptimizationPreset;
  advanced: {
    bid_ceiling: number;
    smart_bid_ceiling_disabled: boolean;
    enabled_keyword_groups: EnabledKeywordGroups[];
    exclude_no_impressions: boolean;
    override_group_settings: boolean;
    skip_placement_optimization: boolean;
    no_limit_placement_change: boolean;
  };
}

export interface OptimizedBiddingEntityDTO extends UpdatedEntityDTO {
  bidding_entity: BiddingEntity;
}

export interface OptimizationApplyDTO {
  job_id: number;
  updates: OptimizationUpdates[];
}

export interface OptimizationUpdates {
  id: string;
  ad_type: CampaignAdType;
  bidding_entity: BiddingEntity;
  match_type: MatchType;
  new_value: number;
  algo_value: number;
  old_value: number;
  reasons: OptimizationReason[];
}

export interface RevertJobDTO {
  job_id: number;
}
