import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Menu, MenuItem, ToggleButton, Tooltip } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, useState } from 'react';

interface ExportGridButtonProps {
  gridApiRef: React.MutableRefObject<GridApi<unknown> | null | undefined>;
}

const ExportGridButton: FunctionComponent<ExportGridButtonProps> = ({ gridApiRef }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCsvExport = () => {
    onCsvExport();
    handleClose();
  };

  const handleXlsxExport = () => {
    onExcelExport();
    handleClose();
  };

  const onCsvExport = () => {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsCsv();
    }
  };

  const onExcelExport = () => {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsExcel();
    }
  };

  return (
    <div>
      <Tooltip title={'Export Table'} placement="left">
        <ToggleButton value={''} onClick={handleClick}>
          <FileDownloadOutlinedIcon sx={{ fontSize: 15 }} />
        </ToggleButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleCsvExport}>Export as CSV</MenuItem>
        <MenuItem onClick={handleXlsxExport}>Export as XLSX</MenuItem>
      </Menu>
    </div>
  );
};

export default ExportGridButton;
