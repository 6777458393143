import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import React, { FunctionComponent } from 'react';
import { FormatterType } from '../grid/types';

interface FormatterToggleButtonProps {
  handleFormatterChange: (newType: FormatterType) => void;
  formatterType: FormatterType;
}

const FormatterToggleButton: FunctionComponent<FormatterToggleButtonProps> = ({
  handleFormatterChange,
  formatterType = FormatterType.LONG,
}) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newType: FormatterType) => {
    if (newType) handleFormatterChange(newType);
  };
  return (
    <div className="flex items-center">
      <ToggleButtonGroup size="small" value={formatterType} exclusive onChange={handleChange} aria-label="display format">
        <Tooltip title="Display numbers in long format">
          <ToggleButton value={FormatterType.LONG} aria-label="display format long">
            <span className="uppercase text-xxs font-semibold">1,234</span>
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Display numbers in short format">
          <ToggleButton value={FormatterType.SHORT} aria-label="display format short">
            <span className="uppercase text-xxs font-semibold mx-2">1k</span>
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </div>
  );
};

export default FormatterToggleButton;
