import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import { createProductsMetricsQueryKey, metricsService } from '@/components/metrics/api/metrics-service';
import { MetricAggregates } from '@/components/metrics/types/MetricField';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { toast } from 'react-toastify';
import { ProductsGridContext } from '../components/products-table/ProductsTable';
import { useProductsContext } from '../contexts/ProductsContext';
import { ProductModel } from '../models/ProductModel';

interface useProductsMetricColumnAggregatesProps {
  gridApiRef: React.MutableRefObject<GridApi<ProductModel> | undefined>;
  gridContextRef: React.MutableRefObject<ProductsGridContext | undefined>;
}

const useProductsMetricColumnAggregates = ({ gridApiRef, gridContextRef }: useProductsMetricColumnAggregatesProps) => {
  const { filters } = useProductsContext();
  const { activeProfile } = useActiveTeamContext();

  const {
    data: metricColumnAggregates,
    isLoading: isMetricsLoading,
    error: metricsError,
  } = useQuery({
    queryKey: createProductsMetricsQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await metricsService.getProductsMetrics(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        toast.error('Error loading metric values');
      }
    },
    select: (metricValues): MetricAggregates => {
      return isNil(metricValues)
        ? ({} as MetricAggregates)
        : metricValues.reduce((aggregates, metric) => {
            aggregates[metric.key] = { current: metric.current };
            return aggregates;
          }, {} as MetricAggregates);
    },
    enabled: !isEmpty(filters),
  });

  const { onGridReadyForMetricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates,
  });

  return { metricColumnAggregates, isMetricsLoading, metricsError, onGridReadyForMetricColumnAggregates };
};

export default useProductsMetricColumnAggregates;
