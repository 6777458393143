import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import dayjs from 'dayjs';
import { CommonMetricField, MetricField, ProfileStatsSellerMetricField, SellerMetricField } from '../metrics/types/MetricField';
import { TimeUnit } from './types';

// weekStart: 0 (Sunday) to 6 (Saturday)
function getWeekStartDate(dateString: string, weekStart: number = 0): string {
  let date;
  try {
    // If we say that the dateString is UTC then if date is converted to local time then for example in US 1 July in US changes to 30th June
    date = dayjs(dateString); // Do Not Parse date in UTC
  } catch (error) {
    console.log(error);
    return '';
  }

  // Adjust day according to the weekStart
  const dayNum = (date.day() + 7 - weekStart) % 7;

  // Subtract the dayNum from the date to get the week start date
  const startDate = date.subtract(dayNum, 'day');

  // Format the week start date as Month Day (e.g., Aug 14)
  return startDate.format('MMM D');
}

// Get month name for aggregation
function getMonthName(dateString: string): string {
  let date;
  try {
    date = dayjs(dateString);
  } catch (error) {
    console.log('Invalid date string:', dateString, error);
    return 'Invalid Date';
  }

  // Return month name (e.g., Aug)
  return date.format('MMM');
}

export function aggregateByPeriod(data: TimelineModel, aggregateBy: TimeUnit): TimelineModel {
  const initialData = new Map<string, { [key in MetricField]?: number }>();
  const periodDayCount = new Map<string, number>();

  // Initialize data for weekly or monthly aggregation
  data.xAxisData.forEach((date) => {
    const periodKey = aggregateBy === TimeUnit.WEEK ? getWeekStartDate(date) : getMonthName(date);

    if (!initialData.has(periodKey)) {
      initialData.set(periodKey, {});
      periodDayCount.set(periodKey, 0);
    }

    periodDayCount.set(periodKey, (periodDayCount.get(periodKey) || 0) + 1);
  });

  // Sum up direct metrics weekly or monthly
  data.yAxisData.forEach((metric) => {
    // TODO: fix later
    if (metric.key == ('seller_metrics' as CommonMetricField)) {
      return;
    }

    metric.values.forEach((value, index) => {
      const periodKey = aggregateBy === TimeUnit.WEEK ? getWeekStartDate(data.xAxisData[index]) : getMonthName(data.xAxisData[index]);
      const metrics = initialData.get(periodKey);
      if (metrics) {
        const metricKey = metric.key;
        metrics[metricKey] = (metrics[metricKey] || 0) + value;
      }
    });
  });

  // Check if the period is partial
  const isPeriodPartial = (periodKey: string): boolean => {
    const dayCount = periodDayCount.get(periodKey);
    return dayCount !== undefined && dayCount < (aggregateBy === TimeUnit.WEEK ? 7 : dayjs().date());
  };

  // Create a new data with updated keys (add Partial where needed)
  const aggregatedData = new Map<string, { [key in MetricField]?: number }>();
  initialData.forEach((metrics, periodKey) => {
    const newKey = `${periodKey}${isPeriodPartial(periodKey) ? ' (Partial)' : ''}`;
    aggregatedData.set(newKey, metrics);
  });

  // Calculate metrics like CTR, ACOS, ROAS, etc.
  aggregatedData.forEach((metrics) => {
    // Calculating Click-Through Rate (CTR)
    if (metrics[CommonMetricField.CLICKS] !== undefined && metrics[CommonMetricField.IMPRESSIONS] !== undefined) {
      metrics[CommonMetricField.CTR] = metrics[CommonMetricField.CLICKS] / metrics[CommonMetricField.IMPRESSIONS];
    }

    // Calculating Advertising Cost of Sales (ACOS)
    if (metrics[CommonMetricField.SPEND] !== undefined && metrics[CommonMetricField.SALES]) {
      metrics[CommonMetricField.ACOS] = metrics[CommonMetricField.SPEND] / metrics[CommonMetricField.SALES];
    }

    // Calculating Return on Advertising Spend (ROAS)
    if (metrics[CommonMetricField.SALES] !== undefined && metrics[CommonMetricField.SPEND]) {
      metrics[CommonMetricField.ROAS] = metrics[CommonMetricField.SALES] / metrics[CommonMetricField.SPEND];
    }

    // Calculating Revenue Per Click (RPC)
    if (metrics[CommonMetricField.SALES] !== undefined && metrics[CommonMetricField.CLICKS]) {
      metrics[CommonMetricField.RPC] = metrics[CommonMetricField.SALES] / metrics[CommonMetricField.CLICKS];
    }

    // Calculating Cost Per Acquisition (CPA)
    if (metrics[CommonMetricField.SPEND] !== undefined && metrics[CommonMetricField.ORDERS]) {
      metrics[CommonMetricField.CPA] = metrics[CommonMetricField.SPEND] / metrics[CommonMetricField.ORDERS];
    }

    // Calculating Average Order Value (AOV)
    if (metrics[CommonMetricField.SALES] !== undefined && metrics[CommonMetricField.ORDERS]) {
      metrics[CommonMetricField.AOV] = metrics[CommonMetricField.SALES] / metrics[CommonMetricField.ORDERS];
    }

    // Calculating Cost Per Click (CPC)
    if (metrics[CommonMetricField.SPEND] !== undefined && metrics[CommonMetricField.CLICKS]) {
      metrics[CommonMetricField.CPC] = metrics[CommonMetricField.SPEND] / metrics[CommonMetricField.CLICKS];
    }

    // Calculating Cost Per Mille (CPM)
    if (metrics[CommonMetricField.SPEND] !== undefined && metrics[CommonMetricField.IMPRESSIONS]) {
      metrics[CommonMetricField.CPM] = (metrics[CommonMetricField.SPEND] / metrics[CommonMetricField.IMPRESSIONS]) * 1000;
    }

    // Calculating Conversion Rate (CVR)
    if (metrics[CommonMetricField.ORDERS] !== undefined && metrics[CommonMetricField.CLICKS]) {
      metrics[CommonMetricField.CVR] = metrics[CommonMetricField.ORDERS] / metrics[CommonMetricField.CLICKS];
    }

    // SELLER
    if (metrics[SellerMetricField.SELLER_SALES] && metrics[CommonMetricField.SPEND]) {
      metrics[SellerMetricField.SELLER_ACOS] = metrics[CommonMetricField.SPEND] / metrics[SellerMetricField.SELLER_SALES];
    }

    if (metrics[SellerMetricField.SELLER_SALES] && metrics[SellerMetricField.SELLER_UNITS]) {
      metrics[SellerMetricField.SELLER_ASP] = metrics[SellerMetricField.SELLER_SALES] / metrics[SellerMetricField.SELLER_UNITS];
    }

    if (metrics[SellerMetricField.SELLER_ORDERS] && metrics[SellerMetricField.SELLER_CLICKS]) {
      metrics[SellerMetricField.SELLER_CVR] = metrics[SellerMetricField.SELLER_ORDERS] / metrics[SellerMetricField.SELLER_CLICKS];
    }

    if (metrics[SellerMetricField.SELLER_UNITS] && metrics[SellerMetricField.SELLER_CLICKS]) {
      metrics[SellerMetricField.SELLER_UNIT_SESS] = metrics[SellerMetricField.SELLER_UNITS] / metrics[SellerMetricField.SELLER_CLICKS];
    }

    if (metrics[CommonMetricField.SALES] && metrics[SellerMetricField.SELLER_SALES]) {
      metrics[SellerMetricField.SELLER_AD_SALES_OF_TOTAL] = metrics[CommonMetricField.SALES] / metrics[SellerMetricField.SELLER_SALES];
    }

    if (metrics[SellerMetricField.SELLER_SALES] && metrics[SellerMetricField.SELLER_ORDERS]) {
      metrics[SellerMetricField.SELLER_AOV] = metrics[SellerMetricField.SELLER_SALES] / metrics[SellerMetricField.SELLER_ORDERS];
    }

    if (metrics[CommonMetricField.SPEND] && metrics[SellerMetricField.SELLER_ORDERS]) {
      metrics[SellerMetricField.SELLER_CPA] = metrics[CommonMetricField.SPEND] / metrics[SellerMetricField.SELLER_ORDERS];
    }

    if (metrics[SellerMetricField.SELLER_SALES] && metrics[CommonMetricField.SPEND]) {
      metrics[SellerMetricField.SELLER_ROAS] = metrics[SellerMetricField.SELLER_SALES] / metrics[CommonMetricField.SPEND];
    }

    if (metrics[SellerMetricField.SELLER_UNITS] && metrics[SellerMetricField.SELLER_VIEWS]) {
      metrics[SellerMetricField.SELLER_UNIT_VIEW] = metrics[SellerMetricField.SELLER_UNITS] / metrics[SellerMetricField.SELLER_VIEWS];
    }

    if (metrics[ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED] && metrics[SellerMetricField.SELLER_UNITS]) {
      metrics[ProfileStatsSellerMetricField.SELLER_UNITS_REFUND_RATE] =
        metrics[ProfileStatsSellerMetricField.SELLER_UNITS_REFUNDED] / metrics[SellerMetricField.SELLER_UNITS];
    }
  });

  return fromAggregatedData(aggregatedData);
}

function fromAggregatedData(aggregatedData: Map<string, { [key in CommonMetricField]?: number }>): TimelineModel {
  const xAxisData: string[] = [];
  const yAxisData: {
    key: CommonMetricField;
    values: number[];
  }[] = [];

  // Assuming aggregatedData is not empty, get the first entry's metrics
  const firstPeriodMetrics = aggregatedData.size > 0 ? Array.from(aggregatedData.values())[0] : {};

  // Initialize yAxisData with empty arrays for each metric in the order they appear in aggregatedData
  Object.keys(firstPeriodMetrics).forEach((metricKey) => {
    yAxisData.push({
      key: metricKey as CommonMetricField,
      values: [],
    });
  });

  // Populate xAxisData and accumulate period values in yAxisData
  aggregatedData.forEach((metrics, periodKey) => {
    xAxisData.push(`${periodKey}`);

    yAxisData.forEach((metricData) => {
      const metricValue = metrics[metricData.key as keyof typeof metrics] ?? 0;
      metricData.values.push(metricValue);
    });
  });

  return new TimelineModel({
    xAxisData: xAxisData,
    yAxisData: yAxisData,
  });
}
