import NumbersIcon from '@mui/icons-material/Numbers';
import PercentIcon from '@mui/icons-material/Percent';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import React, { FunctionComponent } from 'react';
import { ComparisonUnit } from '../grid/types';

interface UnitToggleButtonProps {
  handleComparisonUnitChange: (event: React.MouseEvent<HTMLElement>, newUnit: ComparisonUnit) => void;
  comparisonUnit: ComparisonUnit;
}

const UnitToggleButton: FunctionComponent<UnitToggleButtonProps> = ({ handleComparisonUnitChange, comparisonUnit = 'percent' }) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newUnit: ComparisonUnit) => {
    if (newUnit) handleComparisonUnitChange(event, newUnit);
  };
  return (
    <div className="flex items-center">
      <ToggleButtonGroup size="small" value={comparisonUnit} exclusive onChange={handleChange} aria-label="comparison value unit">
        <Tooltip title={comparisonUnit === 'hidden' ? 'Comparison is hidden' : 'Hide comparison'}>
          <ToggleButton value="hidden" aria-label="comparison hidden">
            <VisibilityOffIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
        </Tooltip>

        <Tooltip title={comparisonUnit === 'nominal' ? 'Comparison unit is set to nominal' : 'Display comparison unit in nominal values'}>
          <ToggleButton value="nominal" aria-label="comparison unit nominal">
            <NumbersIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
        </Tooltip>

        <Tooltip
          title={comparisonUnit === 'percent' ? 'Comparison unit is set to percentage' : 'Display comparison unit in percentage values'}
        >
          <ToggleButton value="percent" aria-label="comparison unit percent">
            <PercentIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </div>
  );
};

export default UnitToggleButton;
