import { FunctionComponent, memo, useMemo, useState } from 'react';
import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { ComboGraphWidgetConfiguration } from './ComboGraphWidgetConfiguration';

import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetProvider from '../../dashboard-widget/DashboardWidgetContextProvider';
import ComboGraphWidgetConfigurationForm from './ComboGraphWidgetConfigurationForm';

import { ReactECharts } from '@/components/chart/ReactEcharts';
import { METRIC_COLORS, getConfigForMetric } from '@/components/metrics/MetricsConfig';
import { CommonMetricField } from '@/components/metrics/types/MetricField';
import useFormatting from '@/hooks/useFormatting';
import { ComboGraphWidgetDataDTO, WidgetDataDTO } from '@/modules/dashboards/api/dashboard/data/dashboard-data.contracts';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import dayjs from 'dayjs';
import {
  BarSeriesOption,
  EChartsOption,
  LineSeriesOption,
  TooltipComponentFormatterCallbackParams,
  TooltipComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { isArray, isEmpty } from 'lodash-es';
import DashboardWidgetFilterIndicator from '../../dashboard-widget/DashboardWidgetFilterIndicator';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import DashboardWidgetTopBar from '../../dashboard-widget/DashboardWidgetTopBar';
import { EntityType } from '../../forms/EntityTypeSelect';
import { GroupByTime } from '../../forms/GroupByTimeSelect';

interface ComboGraphWidgetProps extends IDashboardWidget<ComboGraphWidgetConfiguration> {
  data?: WidgetDataDTO;
  isFetchingDataForWidget?: boolean;
  configurationId: string;
}

const ComboGraphWidget: FunctionComponent<ComboGraphWidgetProps> = ({ configuration, id, data, isFetchingDataForWidget }) => {
  const dashboardCurrency = useDashboardContextValue((context) => context.dashboard?.dto.currency);

  const { getLongFormatterForMetricField, getShortFormatterForMetricField } = useFormatting();

  function getEchartsOptions(dashboardData: ComboGraphWidgetDataDTO, widgetConfiguration: ComboGraphWidgetConfiguration): EChartsOption {
    const series: (LineSeriesOption | BarSeriesOption)[] = [];
    const yAxis: YAXisComponentOption[] = [];

    const xAxisHasDates = configuration.groupByTime === GroupByTime.DAY;

    const xAxisData = dashboardData.xAxis;

    // Map to store yAxis indices per metric
    const yAxisIndexMap: { [metric: string]: number } = {};

    // Build yAxis components for selected metrics
    widgetConfiguration.selectedMetrics.forEach((metric, index) => {
      yAxisIndexMap[metric] = index;

      const metricConfig = getConfigForMetric(metric);
      if (!metricConfig) {
        return;
      }

      const color = METRIC_COLORS[index % METRIC_COLORS.length];

      // Define yAxis for this metric
      yAxis.push({
        name: metricConfig?.title,
        type: 'value',
        position: index % 2 === 0 ? 'left' : 'right', // Alternate positions
        offset: index > 1 ? 60 * Math.floor(index / 2) : 0,
        nameTextStyle: {
          fontWeight: 'bold',
          fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        axisLabel: {
          fontWeight: 'bold',
          fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
          formatter: (value: number) =>
            getShortFormatterForMetricField(metric)(value, {
              customCurrencyCode: dashboardCurrency,
            }),
        },
        axisPointer: {
          label: {
            show: true,
            formatter: (params) =>
              getShortFormatterForMetricField(metric)(params.value as number, {
                customCurrencyCode: dashboardCurrency,
              }),
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: color,
          },
        },
      });
    });

    if (dashboardData.yAxisGrouped && Object.keys(dashboardData.yAxisGrouped).length > 0) {
      // Process grouped data
      // Assign colors to groups
      const groupNames = Object.keys(dashboardData.yAxisGrouped);
      const groupColorMap: { [groupName: string]: string } = {};

      groupNames.forEach((groupName, index) => {
        const color = METRIC_COLORS[index % METRIC_COLORS.length];
        groupColorMap[groupName] = color;
      });

      // Build series
      groupNames.forEach((groupName) => {
        const groupMetrics = dashboardData.yAxisGrouped[groupName];

        widgetConfiguration.selectedMetrics.forEach((metric) => {
          const metricConfig = getConfigForMetric(metric);
          if (!metricConfig) {
            return;
          }

          const color = groupColorMap[groupName]; // Use group color

          const seriesName = `${groupName} - ${metricConfig.title}`;

          // Access the correct values array for the metric
          const values = groupMetrics[metric as keyof typeof groupMetrics] as number[];

          const yAxisIndex = yAxisIndexMap[metric];

          let seriesItem: LineSeriesOption | BarSeriesOption | undefined;

          if (metricConfig.chartSeriesType === 'bar') {
            seriesItem = {
              id: `${metric}__${groupName}`,
              name: seriesName,
              type: 'bar',
              yAxisIndex: yAxisIndex,
              data: values,
              color: color,
              emphasis: {
                focus: 'none',
              },
              // Do not set stack to group bars side by side
            };
          } else if (metricConfig.chartSeriesType === 'line') {
            seriesItem = {
              id: `${metric}__${groupName}`,
              name: seriesName,
              type: 'line',
              yAxisIndex: yAxisIndex,
              data: values,
              color: color,
              symbol: 'emptyCircle',
              symbolSize: 6,
              emphasis: {
                focus: 'none',
              },
              showSymbol: true,
            };
          }

          if (seriesItem) {
            series.push(seriesItem);
          }
        });
      });
    } else {
      // Process ungrouped data
      widgetConfiguration.selectedMetrics.forEach((metric, index) => {
        const metricConfig = getConfigForMetric(metric);
        if (!metricConfig) {
          return;
        }

        const color = METRIC_COLORS[index % METRIC_COLORS.length];

        const seriesName = metricConfig.title;

        // Access the correct values array for the metric
        const values = dashboardData.yAxis[metric as keyof typeof dashboardData.yAxis] as number[];

        const yAxisIndex = yAxisIndexMap[metric];

        let seriesItem: LineSeriesOption | BarSeriesOption | undefined;

        if (metricConfig.chartSeriesType === 'bar') {
          seriesItem = {
            id: metric,
            name: seriesName,
            type: 'bar',
            yAxisIndex: yAxisIndex,
            data: values,
            color: color,
            emphasis: {
              focus: 'none',
            },
          };
        } else if (metricConfig.chartSeriesType === 'line') {
          seriesItem = {
            id: metric,
            name: seriesName,
            type: 'line',
            yAxisIndex: yAxisIndex,
            data: values,
            color: color,
            symbol: 'emptyCircle',
            symbolSize: 6,
            emphasis: {
              focus: 'none',
            },
            showSymbol: true,
          };
        }

        if (seriesItem) {
          series.push(seriesItem);
        }
      });
    }

    const options: EChartsOption = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        boundaryGap: true,
        axisTick: {
          alignWithLabel: true,
        },
        axisPointer: {
          label: {
            show: xAxisHasDates,
          },
        },
        axisLabel: {
          formatter: function (value: string) {
            if (xAxisHasDates) {
              const date = dayjs(value);
              return date.format('MMM DD');
            }
            return value;
          },
          fontWeight: 'bolder',
          fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: yAxis,
      series: series,
      grid: {
        left: widgetConfiguration.selectedMetrics.length >= 3 ? 125 : 65,
        right: widgetConfiguration.selectedMetrics.length >= 4 ? 125 : 65,
        top: 45,
        bottom: 45,
      },
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        axisPointer: {
          type: 'cross',
        },
        formatter: function (params: TooltipComponentFormatterCallbackParams): string {
          console.log(params);
          if (isArray(params)) {
            console.log(params);
            let result = `<b>${params[0].name}</b>` + '<br/>';

            params.forEach((param) => {
              let paramMetric: string | undefined = '';
              if (param.seriesId?.includes('__')) {
                paramMetric = param.seriesId.split('__')[0];
              } else {
                paramMetric = param.seriesId;
              }
              if (paramMetric) {
                const formattedValue = getLongFormatterForMetricField(paramMetric as CommonMetricField)(param.value as number, {
                  customCurrencyCode: dashboardCurrency,
                });
                result += `${param.marker}${param.seriesName}: ${formattedValue}<br/>`;
              }
            });
            return result;
          } else {
            return '';
          }
        },
      } as TooltipComponentOption,
    };

    return options;
  }

  const echartsOptions = useMemo(() => {
    if (data) {
      return getEchartsOptions(data as ComboGraphWidgetDataDTO, configuration);
    }
    return {};
  }, [data, configuration]);

  const hasFilters =
    (!isEmpty(configuration.campaignFilters) &&
      (configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE)) ||
    (!isEmpty(configuration.targetsFilters) && configuration.entityType === EntityType.TARGET);

  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isFetchingDataForWidget} isConfigured={configuration.isConfigured}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget}>
        <DashboardWidgetTopBar>
          <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
          <DashboardWidgetFilterIndicator visible={hasFilters && !isMouseOnWidget} widgetId={id} />
        </DashboardWidgetTopBar>
        <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>

        <div className="h-full w-full flex-1 flex">
          {data && (
            <ReactECharts
              settings={{
                notMerge: true,
              }}
              option={echartsOptions}
            />
          )}
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <ComboGraphWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(ComboGraphWidget, isBaseWidgetConfigurationEqual);
