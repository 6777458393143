import { Paper, Slide } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import OppositeModeProvider from './theme/OppositeModeProvider';

const OppositeModeHeaderActionBar: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <OppositeModeProvider>
      <Slide direction="down" in={true} mountOnEnter unmountOnExit>
        <Paper
          className="h-14 fixed py-2 flex justify-center w-full items-center z-[10] pr-[64px] pointer-events-auto rounded-none"
          sx={{ top: 0, right: 0, left: 64 }}
          elevation={3}
        >
          {children}
        </Paper>
      </Slide>
    </OppositeModeProvider>
  );
};

export default OppositeModeHeaderActionBar;
