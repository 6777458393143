import { useTranslation } from '@/lib';
import { GleapWorkflowType } from '@/lib/gleap';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, AlertTitle, Button, Dialog, IconButton, InputAdornment, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import Gleap from 'gleap';
import { isEmpty } from 'lodash-es';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { UpsertResponsePayloadDTO } from './UpdateResponseModal';

interface UpsertResponseAlertProps {
  title: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  isOpen: boolean;
  onClose: () => void;
  groupedUpsertResponsePayload: Map<string, UpsertResponsePayloadDTO[]>;
  errorMessage?: string;
}

export const UpsertResponseAlert: FunctionComponent<UpsertResponseAlertProps> = ({
  title,
  severity,
  isOpen,
  onClose,
  groupedUpsertResponsePayload: groupedPayload,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const isWarning = severity === 'warning';

  const getCloseAction = () => (
    <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  const onOpenTicketClicked = () => {
    Gleap.startFeedbackFlow(GleapWorkflowType.BugReport, true);
  };

  const handleCopyClick = async () => {
    try {
      if (errorMessage) {
        await navigator.clipboard.writeText(errorMessage);
        toast.success('Copied to clipboard');
      }
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <Alert severity={severity} action={getCloseAction()}>
        <AlertTitle>{title}</AlertTitle>

        {isWarning && (
          <>
            <Typography variant="body1">
              If the problem persists, please{' '}
              <Button variant="text" onClick={onOpenTicketClicked}>
                open a ticket
              </Button>{' '}
              {!isEmpty(errorMessage) && <>and copy the following errors to us </>}
            </Typography>

            <div className="flex w-full flex-col">
              {!isEmpty(errorMessage) && (
                <>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={errorMessage}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={handleCopyClick}>
                              <ContentCopyIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </>
              )}
            </div>
          </>
        )}

        <div className="flex flex-col gap-2">
          {Array.from(groupedPayload.keys()).map((key) => {
            const items = groupedPayload.get(key)?.map((item, index) => {
              let header = 'Campaign';
              if ('bidding_entity' in item) {
                header = t(`optimizer_page.bidding_entity.${item.bidding_entity}`);
              } else if ('entity_type' in item && 'create_count' in item) {
                header = t(`search_terms_page.create_negatives_entity_type.${item.entity_type}`);
              } else if ('entity_type' in item) {
                header = t(`optimizer_page.bidding_entity.${item.entity_type}`);
              }

              return (
                <React.Fragment key={key + index}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography component="span" variant="subtitle2">
                          {header}
                        </Typography>
                      }
                      secondary={
                        <>
                          {'update_count' in item && (
                            <>
                              Update Count: {item.update_count}
                              <br />
                            </>
                          )}
                          {'create_count' in item && (
                            <>
                              Created Count: {item.create_count}
                              <br />
                            </>
                          )}
                          {isWarning && <>Error Count: {item.error_count}</>}
                        </>
                      }
                    />
                  </ListItem>
                </React.Fragment>
              );
            });

            return (
              <React.Fragment key={key}>
                <h2 className="pb-0 mb-0"> {key} </h2>
                {items}
              </React.Fragment>
            );
          })}
        </div>
      </Alert>
    </Dialog>
  );
};
