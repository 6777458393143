import { useMemo } from 'react';

import { SelectedTarget } from '../models/TargetsModel';
import { EnabledPausedArchivedState } from '@/modules/optimizer/api/campaign/campaign-contracts';

interface TargetingInfo {
  warnings: string[];
  selectedLegacyTargetsCount: number;
  archivedTargetsCount: number;
  selectedTargetsWithOptRulesCount: number;
}

interface UseTargetingWarningsProps {
  selectedTargets: SelectedTarget[];
}

const useTargetingWarnings = ({ selectedTargets }: UseTargetingWarningsProps) => {
  const preBulkEditInfo: TargetingInfo = useMemo(() => {
    const warningMessages: string[] = [];

    const selectedLegacyTargetsCount = selectedTargets.filter((t) => t.isLegacyAutoTarget).length;
    if (selectedLegacyTargetsCount > 0) {
      warningMessages.push(`Cannot change ${selectedLegacyTargetsCount} Legacy Auto-targets manually`);
    }

    const archivedTargetsCount = selectedTargets.filter((target) => target.state === EnabledPausedArchivedState.ARCHIVED).length;
    if (archivedTargetsCount > 0) {
      warningMessages.push(`Cannot change ${archivedTargetsCount} archived targets`);
    }

    const selectedTargetsWithOptRulesCount = selectedTargets.filter((t) => t.hasOptRule).length;
    if (selectedTargetsWithOptRulesCount > 0) {
      warningMessages.push(
        `Cannot change bids for ${selectedTargetsWithOptRulesCount} target${selectedTargetsWithOptRulesCount > 1 ? 's' : ''} with optimization rules`,
      );
    }

    return { warnings: warningMessages, selectedLegacyTargetsCount, archivedTargetsCount, selectedTargetsWithOptRulesCount };
  }, [selectedTargets]);

  return { ...preBulkEditInfo };
};

export default useTargetingWarnings;
