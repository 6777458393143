import { Editor, EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { Highlight } from '@tiptap/extension-highlight';
import { TextAlign } from '@tiptap/extension-text-align';
import { FunctionComponent, useEffect, useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

interface MenuBarProps {
  editor: Editor | null;
}
const MenuBar: FunctionComponent<MenuBarProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="flex flex-col items-start  gap-2">
      <div className="flex flex-wrap items-center gap-2 origin-top-left">
        {/* Heading buttons */}
        <ToggleButtonGroup
          size="small"
          exclusive
          value={
            editor.isActive('heading', { level: 1 })
              ? 'h1'
              : editor.isActive('heading', { level: 2 })
                ? 'h2'
                : editor.isActive('heading', { level: 3 })
                  ? 'h3'
                  : ''
          }
          onChange={(_, value) => {
            if (value === 'h1') editor.chain().focus().toggleHeading({ level: 1 }).run();
            if (value === 'h2') editor.chain().focus().toggleHeading({ level: 2 }).run();
            if (value === 'h3') editor.chain().focus().toggleHeading({ level: 3 }).run();
            if (value === '') editor.chain().focus().setParagraph().run();
          }}
        >
          <ToggleButton value="h1">
            <span className="text-xs leading-[15px]">H1</span>
          </ToggleButton>
          <ToggleButton value="h2">
            <span className="text-xs leading-[15px]">H2</span>
          </ToggleButton>
          <ToggleButton value="h3">
            <span className="text-xs leading-[15px]">H3</span>
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Text formatting buttons */}
        <ToggleButtonGroup size="small" aria-label="text formatting">
          <ToggleButton value="bold" selected={editor.isActive('bold')} onClick={() => editor.chain().focus().toggleBold().run()}>
            <FormatBoldIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton value="italic" selected={editor.isActive('italic')} onClick={() => editor.chain().focus().toggleItalic().run()}>
            <FormatItalicIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton value="strike" selected={editor.isActive('strike')} onClick={() => editor.chain().focus().toggleStrike().run()}>
            <FormatStrikethroughIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton
            value="highlight"
            selected={editor.isActive('highlight')}
            onClick={() => editor.chain().focus().toggleHighlight().run()}
          >
            <BorderColorIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Alignment buttons */}
        <ToggleButtonGroup
          size="small"
          exclusive
          value={
            editor.isActive({ textAlign: 'left' })
              ? 'left'
              : editor.isActive({ textAlign: 'center' })
                ? 'center'
                : editor.isActive({ textAlign: 'right' })
                  ? 'right'
                  : editor.isActive({ textAlign: 'justify' })
                    ? 'justify'
                    : ''
          }
          onChange={(_, value) => {
            if (value === 'left') editor.chain().focus().setTextAlign('left').run();
            if (value === 'center') editor.chain().focus().setTextAlign('center').run();
            if (value === 'right') editor.chain().focus().setTextAlign('right').run();
            if (value === 'justify') editor.chain().focus().setTextAlign('justify').run();
          }}
        >
          <ToggleButton value="left">
            <FormatAlignLeftIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton value="center">
            <FormatAlignCenterIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton value="right">
            <FormatAlignRightIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
          <ToggleButton value="justify">
            <FormatAlignJustifyIcon sx={{ fontSize: 15 }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

interface RichTextEditorProps {
  content: string;
  onContentChange: (content: string) => void;
}

const RichTextEditor: FunctionComponent<RichTextEditorProps> = ({ content, onContentChange }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Highlight,
    ],
    content: content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onContentChange(html); // Callback to update the configuration
    },
  });

  // keep track of focus state
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    editor?.on('focus', handleFocus);
    editor?.on('blur', handleBlur);

    // Cleanup function to remove event listeners
    return () => {
      editor?.off('focus', handleFocus);
      editor?.off('blur', handleBlur);
    };
  }, [editor]);

  return (
    <>
      <MenuBar editor={editor} />
      <div className={`border mt-3 p-2 rounded-md ${isFocused ? 'border-primary-500' : 'border-gray-200'}`}>
        <EditorContent editor={editor} onFocus={console.log} />
      </div>
    </>
  );
};

export default RichTextEditor;
