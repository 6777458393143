import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { filterFactory } from '@/components/filter-builder/models/FilterFactory';
import CampaignFilterListEditor from '@/modules/campaigns/components/CampaignFilterListEditor';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { FunctionComponent } from 'react';
import { cloneDeep } from 'lodash-es';
import { EntityType } from '../../forms/EntityTypeSelect';
import TargetingFilterListEditor from '@/modules/campaigns/components/TargetingFilterListEditor';
import { ComboGraphWidgetConfiguration } from './ComboGraphWidgetConfiguration';
import { Typography } from '@mui/material';
import PlacementFilterListEditor from '@/modules/campaigns/components/PlacementFilterListEditor';

interface ComboGraphWidgetFiltersProps {
  configuration: ComboGraphWidgetConfiguration;
  setConfiguration: React.Dispatch<React.SetStateAction<ComboGraphWidgetConfiguration>>;
}

const ComboGraphWidgetFilters: FunctionComponent<ComboGraphWidgetFiltersProps> = ({ configuration, setConfiguration }) => {
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);

  const onCampaignFiltersChanged = (newFilters: AlFilterModel[]) => {
    setConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.campaignFilters = newFilters.map((filter) => filter.toDTO());
      return newConfig;
    });
  };

  const onPlacementFiltersChanged = (newFilters: AlFilterModel[]) => {
    setConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.placementFilters = newFilters.map((filter) => filter.toDTO());
      return newConfig;
    });
  };

  const onTargetingFiltersChanged = (newFilters: AlFilterModel[]) => {
    setConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.targetsFilters = newFilters.map((filter) => filter.toDTO());
      return newConfig;
    });
  };

  return (
    <>
      {configuration.entityType != EntityType.PROFILE && (
        <div className="mt-4">
          <Typography variant="body1">{'Filters'}</Typography>
        </div>
      )}

      {configuration.entityType === EntityType.CAMPAIGN && (
        <CampaignFilterListEditor
          teamProfiles={dashboardProfiles ?? []}
          filters={
            configuration.campaignFilters
              ? configuration.campaignFilters.map((filter) =>
                  filterFactory.createFilterModelFromDTO(filter, {
                    teamProfiles: dashboardProfiles,
                  }),
                )
              : []
          }
          onFiltersChange={onCampaignFiltersChanged}
        />
      )}

      {configuration.entityType === EntityType.TARGET && (
        <TargetingFilterListEditor
          teamProfiles={dashboardProfiles ?? []}
          filters={
            configuration.targetsFilters
              ? configuration.targetsFilters.map((filter) =>
                  filterFactory.createFilterModelFromDTO(filter, {
                    teamProfiles: dashboardProfiles,
                  }),
                )
              : []
          }
          onFiltersChange={onTargetingFiltersChanged}
        />
      )}

      {configuration.entityType === EntityType.PLACEMENT && (
        <PlacementFilterListEditor
          teamProfiles={dashboardProfiles ?? []}
          filters={
            configuration.placementFilters
              ? configuration.placementFilters.map((filter) =>
                  filterFactory.createFilterModelFromDTO(filter, {
                    teamProfiles: dashboardProfiles,
                  }),
                )
              : []
          }
          onFiltersChange={onPlacementFiltersChanged}
        />
      )}
    </>
  );
};

export default ComboGraphWidgetFilters;
