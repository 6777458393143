import { FilterBuilderDialog } from '@/components/filter-builder/FilterBuilderDialog';
import { FilterChipRow } from '@/components/filter-builder/FilterChipRow';
import useFilters from '@/components/filter-builder/hooks/useFilters';
import { AlFilterModel, createProfileStatsFilters } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import CurrencySelect from '@/components/form/CurrencySelect';
import GridTools from '@/components/grid/components/GridTools';
import { GridToggles } from '@/components/grid/types';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import { IdNameDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { ProfileWithMetricsModel } from '@/modules/profiles/types/ProfileWithMetricsModel';
import { useUserContext } from '@/modules/users';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Button, Card, Tooltip } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import dayjs from 'dayjs';
import { FunctionComponent, useRef, useState } from 'react';

interface ProfileStatsFilterBarProps {
  profilesWithMetrics: ProfileWithMetricsModel[] | undefined;
  gridApiRef: React.MutableRefObject<GridApi<unknown> | null>;
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
  gridToggles: GridToggles;
  setGridToggles: React.Dispatch<React.SetStateAction<GridToggles>>;
  selectedCurrency: CurrencyCode;
  setSelectedCurrency: (currency: CurrencyCode) => void;
}

const ProfileStatsFilterBar: FunctionComponent<ProfileStatsFilterBarProps> = ({
  profilesWithMetrics,
  gridApiRef,
  filters,
  setFilters,
  gridToggles,
  setGridToggles,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false); // state to control the popover
  const { setFilterValues } = useFilters({ filters, setFilters });

  const onFilterChipClicked = () => {
    setIsFilterDialogOpen((previousValue) => !previousValue);
  };

  const onFilterDeleteButtonClicked = (filter: AlFilterModel) => {
    setFilters((previousFilters) => previousFilters.filter((f) => f.key !== filter.key));
  };

  const teamIdName: IdNameDTO[] =
    profilesWithMetrics?.reduce((acc, profile) => {
      const id = profile.teamId.toString();

      // Check if the ID already exists in the accumulator array
      if (!acc.some((item) => item.id === id)) {
        acc.push({ id, name: profile.teamName });
      }

      return acc;
    }, [] as IdNameDTO[]) ?? [];

  const profileIdName: IdNameDTO[] =
    profilesWithMetrics?.reduce((acc, profile) => {
      const id = profile.id.toString();

      // Check if the ID already exists in the accumulator array
      if (!acc.some((item) => item.id === id)) {
        acc.push({ id, name: profile.nameWithMarket });
      }

      return acc;
    }, [] as IdNameDTO[]) ?? [];

  const availableFilters = createProfileStatsFilters(teamIdName, profileIdName).filter((filter) => filter.isFilterBuilderFilter);

  function onFiltersChanged(newFilters: AlFilterModel[]) {
    setFilters(newFilters);
  }

  const someProfilesHaveMissingComparison = profilesWithMetrics?.some((profile) => profile.comparisonMissing);
  const comparisonMissingMessage = someProfilesHaveMissingComparison ? 'Some profiles have missing comparison data' : null;

  //Currency
  const usedCurrencies = profilesWithMetrics?.reduce((acc, profile) => {
    if (!acc.includes(profile.currencyCode)) {
      acc.push(profile.currencyCode);
    }
    return acc;
  }, [] as CurrencyCode[]);

  // min date for date range should be earliest profile created at
  const { user } = useUserContext();
  const earliestProfile = user?.teams
    .map((t) => t.profiles)
    .flat()
    .reduce((earliest, current) => {
      if (!earliest.createdAt || !current.createdAt) {
        return earliest;
      }

      return dayjs(current.createdAt).isBefore(dayjs(earliest.createdAt)) ? current : earliest;
    });

  return (
    <Card className="flex flex-row items-center gap-x-4 p-2 pb-3 rounded-none rounded-tr-xl rounded-tl-xl -mb-1 relative overflow-visible">
      <div className="flex flex-row">
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={onFilterChipClicked}
          startIcon={<FilterListRoundedIcon />}
          ref={filtersButtonRef}
          className="mr-2 flex-shrink-0"
        >
          Adjust Filters
        </Button>

        <FilterBuilderDialog
          buttonRef={filtersButtonRef}
          setIsOpen={setIsFilterDialogOpen}
          isOpen={isFilterDialogOpen}
          availableFilters={availableFilters}
          defaultFilterKey={FilterKey.TEAM}
          appliedFilters={filters}
          onFiltersChanged={onFiltersChanged}
        />

        <FilterChipRow
          filters={filters}
          onFilterDeleteButtonClicked={onFilterDeleteButtonClicked}
          onFilterChipClicked={onFilterChipClicked}
        />
      </div>

      <div className="flex flex-grow"></div>
      <div className="flex h-full flex-col justify-center items-center">
        <DateRangeButton
          title="Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to stats"
          comparisonRangeWarning={comparisonMissingMessage}
          minDate={earliestProfile?.createdAt}
        />
      </div>

      <Tooltip placement="top" title={'Currency used for the totals shown in headers'}>
        <span>
          <CurrencySelect
            onCurrencyChange={setSelectedCurrency}
            selectedCurrency={selectedCurrency}
            optionallyAddedCurrencies={usedCurrencies}
          />
        </span>
      </Tooltip>
      <GridTools gridToggles={gridToggles} setGridToggles={setGridToggles} gridApiRef={gridApiRef} />
    </Card>
  );
};

export default ProfileStatsFilterBar;
