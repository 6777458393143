import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { NegativeMatchType } from '../negative-targets/api/negative-targets-contracts';
import { CountryCode } from '../users/types/CountryCode';

export function getErrorMessage(e: unknown): string {
  if (e instanceof Error) {
    return e.message;
  } else if (typeof e === 'string') {
    return e;
  }
  return 'An unexpected error occurred';
}

// TODO: move amazon stuff to its own file

const expressionPrefixes = ['asin=', 'asin-expanded=', 'category=', 'brand='];
export function isExpression(testString: string): boolean {
  return expressionPrefixes.some((expression) => testString.startsWith(expression));
}

export function getASINFromExpression(testString: string): string {
  testString = testString.replaceAll('"', '').replaceAll("'", '');

  expressionPrefixes.forEach((expression) => {
    testString = testString.replace(expression, '');
  });

  return testString;
}

export function isValidASIN(testString: string): boolean {
  const regex = /^(B[\dA-Z]{9}|\d{9}(X|\d))$/i; // Added the 'i' flag for case insensitivity
  return regex.test(testString);
}

export function standardizeASIN(asin: string): string {
  const termsToCheck = ['asin=', 'asin-expanded=', 'category='];
  if (termsToCheck.some((term) => asin.includes(term))) {
    return asin;
  }
  return `asin="${asin}"`;
}

export const truncateName = (name: string): string => {
  return name.length > 35 ? name.substring(0, 15) + ' ... ' + name.substring(name.length - 15, name.length) : name;
};

const DEFAULT_MAX_STRING_LENGTH = 30;
export const truncateString = (s: string, tagLengthLimit = DEFAULT_MAX_STRING_LENGTH): string => {
  return s.length > tagLengthLimit ? s.substring(0, 4) + '...' + s.substring(s.length - 4, s.length) : s;
};

export function getSeparatedValuesFromText(text: string, currentTags: string[]): string[] {
  // Split the pasted text based on newline or tab character
  // \t represents a tab character.
  // \n represents a newline character in Unix/Linux systems.
  // \r represents a carriage return character, used in old Mac systems and part of the Windows newline sequence (\r\n).
  // + quantifier, if the pasted text contains consecutive newline or tab characters, they will be treated as a single delimiter
  // If we want to split on each occurrence of a newline or tab character (even if they are consecutive), we should split(/[\t\n\r]/)
  const pastedTags = text.split(/[\t\n\r]+/).filter((tag) => {
    const trimmedTag = tag.trim();
    return trimmedTag !== '';
  });

  const allTags = currentTags.map((tag) => tag.toLowerCase());
  const newTags = pastedTags.filter((tag) => !allTags.includes(tag.toLowerCase()));
  const duplicatedTags = pastedTags.filter((tag) => allTags.includes(tag.toLowerCase()));

  if (duplicatedTags?.length > 0) {
    if (duplicatedTags.length == 1) {
      toast.error(duplicatedTags[0] + ' is already in the list');
    } else {
      toast.error('These existing values not added: ' + duplicatedTags.join(', '));
    }
  }
  return newTags;
}

export function isNonNegativeNumber(value: unknown): boolean {
  return typeof value === 'number' && isFinite(value) && value >= 0;
}

export function isPositiveNumber(value: unknown): boolean {
  return typeof value === 'number' && isFinite(value) && value > 0;
}

export const downloadObjectArrayAsCsv = (rowData: object[], fileName: string, headers?: string[]) => {
  // Create CSV header from keys of the first object, assuming all objects have the same structure
  let csvHeader = Object.keys(rowData[0]).join(',') + '\n';
  if (headers && headers.length > 0) {
    csvHeader = headers.join(',') + '\n';
  }

  // Create CSV rows from rowData
  const csvRows = rowData
    .map((row) =>
      Object.values(row)
        .map(
          (field) => `"${field.toString().replace(/"/g, '""')}"`, // Handle fields that might contain quotes
        )
        .join(','),
    )
    .join('\n');

  const csvString = csvHeader + csvRows;

  // Create a Blob from the CSV String
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  // Create a link and trigger the download
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = `${fileName}.csv`;
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function sanitizeFilename(input: string): string {
  const illegalRe = /[/?<>\\:*|"]/g; // Regular expression to match illegal characters in Windows and Unix/Linux
  // eslint-disable-next-line no-control-regex
  const controlRe = /[\x00-\x1f\x80-\x9f]/g; // Control characters
  // eslint-disable-next-line no-control-regex
  const reservedRe = /^\.+$|[\x00-\x1f\x80-\x9f]|^\.+|\.+$|[*?<>|]/g; // Additional problematic characters
  // eslint-disable-next-line no-control-regex
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i; // Windows reserved names

  const sanitized = input
    .replace(illegalRe, '') // Remove illegal characters
    .replace(controlRe, '') // Remove control characters
    .replace(reservedRe, '') // Remove reserved characters problematic for Windows
    .replace(/\s/g, '_') // Replace spaces with underscores, or you can use .replace(/\s+/g, '') to remove spaces
    .replace(windowsReservedRe, ''); // Remove Windows reserved names

  return sanitized;
}

export function roundToPrecision(precisionNumber: number, numberToRound: number) {
  // Determine the number of decimal places in precisionNumber
  const decimalPlaces = (precisionNumber.toString().split('.')[1] || '').length;

  // Round the numberToRound to the specified number of decimal places
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(numberToRound * factor) / factor;
}

export function isValidPositiveNumberOrEmptyString(value: string): boolean {
  return /^(\d+(\.\d*)?|\.\d*|)?$/.test(value);
}

export function cleanAsinsReturnErrorMessage(asins: string[]): { expressions: string[]; errorMessage: string | null } {
  const expressions: string[] = [];
  const invalidAsins: string[] = [];

  for (const expressionOrASIN of asins) {
    if (isValidASIN(expressionOrASIN)) {
      expressions.push(standardizeASIN(expressionOrASIN));
    } else if (isExpression(expressionOrASIN)) {
      if (isValidASIN(getASINFromExpression(expressionOrASIN))) {
        expressions.push(expressionOrASIN);
      } else {
        invalidAsins.push(expressionOrASIN);
      }
    } else {
      invalidAsins.push(expressionOrASIN);
    }
  }

  let errorMessage: string | null = null;

  if (invalidAsins.length > 0) {
    const INVALID_ASIN_SHOW_COUNT = 5;
    const firstX = invalidAsins.slice(0, INVALID_ASIN_SHOW_COUNT);
    const remainingCount = invalidAsins.length - firstX.length;

    errorMessage = `ASIN${firstX.length > 1 ? 's are' : ' is'} invalid: ${firstX.join(', ')}${remainingCount > 0 ? `, with ${remainingCount} others` : ''}.`;
  }

  return { expressions, errorMessage };
}

export interface RemoveInvalidKeywordsResult {
  validatedKeywords: string[];
  warnings: Set<string>;
}

export function removeInvalidKeywords(keywords: string[], negativeMatchTypes: NegativeMatchType[] = []): RemoveInvalidKeywordsResult {
  const validatedKeywords: string[] = [];
  const warnings: Set<string> = new Set();

  // Rule-specific constraints
  const maxWordsForNegativePhrase = 4;
  const maxWordsForNegativeExact = 10;
  const maxWordsForKeywords = 10;
  const maxKeywordLength = 80;

  // Regex for invalid use of special characters
  const invalidPeriod = /^[.]|[.]$|\s[.]\s/; // Period can't be at start, end, or between spaces
  const invalidHyphenPlus = /(^[-+])|([-+]\s|\s[-+])|([-+]$)/; // Hyphen/plus cannot be at start, end, or around spaces

  const validCharRegex =
    /^[\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF0-9a-zA-Z®ÁÉÍÑÓÚÜáéíñóúüÄÖŒßäöÀÂÆÇÈÊËÎÏÔÙÛŸàâæçèêëîïôùûÿœ\-&\\+[\]\t\n\r'" ]*$/;

  // Determine the stricter word limit based on the provided match types
  const hasPhraseMatch = negativeMatchTypes.some(
    (type) => type === NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE || type === NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE,
  );
  const hasExactMatch = negativeMatchTypes.some(
    (type) => type === NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT || type === NegativeMatchType.AD_GROUP_NEGATIVE_EXACT,
  );

  // Apply the more restrictive limit: 4 words if Negative Phrase is involved
  const maxWords = hasPhraseMatch ? maxWordsForNegativePhrase : hasExactMatch ? maxWordsForNegativeExact : maxWordsForKeywords;

  // Helper function to count words and ensure they meet "part" constraints
  const countWords = (keyword: string): number => keyword.trim().split(/\s+/).length;

  // Iterate over the keywords
  for (let keyword of keywords) {
    keyword = keyword.trim(); // Remove leading and trailing spaces

    // Check word count
    const wordCount = countWords(keyword);
    if (wordCount > maxWords) {
      warnings.add(`Keyword exceeds the ${maxWords} word limit: "${keyword}"`);
      continue;
    }

    // Check maximum length constraint
    if (keyword.length > maxKeywordLength) {
      warnings.add(`Keyword exceeds the 80 character limit: "${keyword}"`);
      continue;
    }

    // Check for invalid use of period (.)
    if (invalidPeriod.test(keyword)) {
      warnings.add(`Invalid use of a period in keyword: "${keyword}"`);
      continue;
    }

    // Check for invalid use of hyphen (-) or plus (+)
    if (invalidHyphenPlus.test(keyword)) {
      warnings.add(`Invalid use of hyphen or plus sign in keyword: "${keyword}"`);
      continue;
    }

    // Check for invalid use of special characters
    if (!validCharRegex.test(keyword)) {
      warnings.add(`Keyword contains invalid characters: "${keyword}"`);
      continue;
    }

    // Invalid use of double quotes
    if (/["]/.test(keyword)) {
      const doubleQuoteCount = (keyword.match(/"/g) || []).length;
      if (doubleQuoteCount % 2 !== 0) {
        warnings.add(`Invalid use of a double quote in keyword: "${keyword}"`);
        continue;
      }
    }

    // Can't contain comma
    if (keyword.includes(',')) {
      warnings.add(`Keyword contains a comma: "${keyword}"`);
      continue;
    }

    // If keyword is valid, add it to validatedKeywords; if not, only add the warning
    validatedKeywords.push(keyword);
  }

  return { validatedKeywords, warnings };
}

export function countDaysInclusive(startDateString: string, endDateString: string): number {
  const startDate = dayjs(startDateString);
  const endDate = dayjs(endDateString);

  if (!startDate.isValid() || !endDate.isValid()) {
    throw new Error('Invalid date format');
  }

  const diffInDays = endDate.diff(startDate, 'day');

  if (diffInDays < 0) {
    throw new Error('End date must be on or after start date');
  }

  return diffInDays + 1;
}

const countryDomainMap: Record<CountryCode, string> = {
  [CountryCode.US]: 'amazon.com',
  [CountryCode.CA]: 'amazon.ca',
  [CountryCode.MX]: 'amazon.com.mx',
  [CountryCode.UK]: 'amazon.co.uk',
  [CountryCode.DE]: 'amazon.de',
  [CountryCode.FR]: 'amazon.fr',
  [CountryCode.IT]: 'amazon.it',
  [CountryCode.ES]: 'amazon.es',
  [CountryCode.NL]: 'amazon.nl',
  [CountryCode.SE]: 'amazon.se',
  [CountryCode.PL]: 'amazon.pl',
  [CountryCode.BE]: 'amazon.com.be',
  [CountryCode.JP]: 'amazon.co.jp',
  [CountryCode.IN]: 'amazon.in',
  [CountryCode.AU]: 'amazon.com.au',
  [CountryCode.SG]: 'amazon.sg',
  [CountryCode.AE]: 'amazon.ae',
  [CountryCode.SA]: 'amazon.sa',
  [CountryCode.EG]: 'amazon.eg',
  [CountryCode.TR]: 'amazon.com.tr',
  [CountryCode.BR]: 'amazon.com.br',
};

export function getProductLink(asin: string, countryCode: CountryCode): string {
  const domain = countryDomainMap[countryCode];
  return `https://${domain}/dp/${asin}`;
}
export const assertUnhandledCase = (x: never): never => {
  throw new Error(`Unhandled case: ${x}`);
};
