import { CommonMetricsDTO, MetricDTO } from '@/components/metrics/api/metrics-contracts';
import { CommonMetricField, SellerMetricField } from '@/components/metrics/types/MetricField';
import { AdvertisedProductModel } from '@/modules/advertised-products/models/AdvertisedProductModel';
import { TimelineDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { ProductModel } from '../models/ProductModel';

export enum ProductAvailability {
  IN_STOCK = 'IN_STOCK', //The item is in stock.
  IN_STOCK_SCARCE = 'IN_STOCK_SCARCE', //The item is in stock, but stock levels are limited.
  OUT_OF_STOCK = 'OUT_OF_STOCK', //The item is currently out of stock.
  PREORDER = 'PREORDER', //The item is not yet available, but can be pre-ordered.
  LEADTIME = 'LEADTIME', //The item is only available after some amount of lead time.
  AVAILABLE_DATE = 'AVAILABLE_DATE', //The item is not available, but will be available on a future date.
  ERROR = 'ERROR', // front only
  UNKNOWN = '',
}

export interface ProductWithMetricsWithPreviousDTO extends CommonMetricsDTO {
  /**
   * Title
   */
  t: string;
  /**
   * Availability
   */
  av: ProductAvailability;
  /**
   * BasisPrice
   */
  bp: number;
  /**
   * PriceToPay
   */
  pp: number;
  /**
   * BestSellerRank
   */
  b: number;
  /**
   * ImageUrl
   */
  i: string;
  /**
   * asin
   */
  a: string;
  /**
   * ParentAsin
   */
  pa: string;
  /**
   * sku
   */
  s: string;
  /**
   * Category
   */
  c: string;
  /**
   * Brand
   */
  bd: string;
  /**
   * Product Data Group item IDs
   */
  pd: number[] | null;

  rank_change: number[];

  seller_metrics: {
    acos: MetricDTO;
    ad_sales_of_total: MetricDTO;
    aov: MetricDTO;
    asp: MetricDTO;
    clicks: MetricDTO;
    cpa: MetricDTO;
    cvr: MetricDTO;
    orders: MetricDTO;
    org_sales: MetricDTO;
    org_traffic: MetricDTO;
    roas: MetricDTO;
    sales: MetricDTO;
    unit_sess: MetricDTO;
    unit_view: MetricDTO;
    units: MetricDTO;
    views: MetricDTO;
  };
}

export interface ProductsTimelineDTO extends TimelineDTO {
  y_axis: Record<CommonMetricField, number[]> & {
    seller_metrics: Record<SellerMetricField, MetricDTO>;
  };
}

export interface ProductsWithTimelineDTO {
  products: ProductWithMetricsWithPreviousDTO[];
  timeline: ProductsTimelineDTO;
  comparison_missing: boolean;
}

export interface ProductsWithTimeline {
  products: ProductModel[];
  timeline: TimelineModel;
  isComparisonDataMissing: boolean;
}

export interface AdvertisedProductWithMetricsWithPreviousDTO extends CommonMetricsDTO {
  /**
   * Title
   */
  t: string;
  /**
   * ImageUrl
   */
  i: string;
  /**
   * asin
   */
  a: string;
  /**
   * ParentAsin
   */
  pa: string;
  /**
   * sku
   */
  s: string;
  /**
   * campaign id
   */
  ci: string;
  /**
   * campaign name
   */
  cn: string;
  /**
   * ad group id
   */
  ai: string;
  /**
   * ad group name
   */
  an: string;
  /**
   * ad group state
   */
  as: string;
  /**
   * Product Data Group item IDs
   */
  pd: number[] | null;
  /**
   * Product Units Sold
   */
  units: MetricDTO;

  rank_change: number[];
}

export interface AdvertisedProductsWithTimelineDTO {
  products: AdvertisedProductWithMetricsWithPreviousDTO[];
  timeline: TimelineDTO;
  comparison_missing: boolean;
}

export interface AdvertisedProductsWithTimeline {
  products: AdvertisedProductModel[];
  timeline: TimelineModel;
  isComparisonDataMissing: boolean;
}
