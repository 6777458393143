import { MetricsDTO, ProductMetricsDTO } from '../api/metrics-contracts';
import { CommonMetricField, MetricField, SellerMetricField, stringToSellerMetricFieldString } from '../types/MetricField';

export class MetricModel {
  public key: MetricField;
  public current: number;
  public previous: number;

  constructor(args: MetricsCreateArguments) {
    this.key = args.key;
    this.current = args.current;
    this.previous = args.previous;
  }

  public static fromProductDTO(response: ProductMetricsDTO): MetricModel[] {
    const metrics: MetricModel[] = [];

    for (const [key, value] of Object.entries(response.seller_metrics)) {
      const sellerMetricKey = stringToSellerMetricFieldString(key);

      if (!Object.values(SellerMetricField).includes(sellerMetricKey as SellerMetricField)) {
        continue;
      }
      metrics.push(
        new MetricModel({
          key: sellerMetricKey as SellerMetricField,
          current: value ? value[0] : 0,
          previous: value ? value[1] : 0,
        }),
      );
    }
    metrics.push(...MetricModel.fromDTO(response));

    return metrics;
  }

  public static fromDTO(response: MetricsDTO): MetricModel[] {
    const metrics: MetricModel[] = [];
    for (const [key, value] of Object.entries(response)) {
      if (!Object.values(CommonMetricField).includes(key as CommonMetricField)) {
        continue;
      }
      metrics.push(
        new MetricModel({
          key: key as CommonMetricField,
          current: value ? value[0] : 0,
          previous: value ? value[1] : 0,
        }),
      );
    }
    return metrics;
  }
}

interface MetricsCreateArguments {
  key: MetricField;
  current: number;
  previous: number;
}
