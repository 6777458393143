import { ButtonBase } from '@mui/material';
import { ArrowCircleLeftOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { FunctionComponent } from 'react';

interface BackButtonProps {
  pathname: string;
}

export const BackButton: FunctionComponent<BackButtonProps> = ({ pathname }) => {
  const navigate = useNavigate();

  function navigateToPath() {
    navigate(pathname);
  }
  return (
    <ButtonBase
      sx={{}}
      onClick={navigateToPath}
      component="div"
      className={`rounded-full text-gray-100 hover:cursor-pointer hover:bg-gray-100 hover:bg-opacity-20 hover:text-gray-100 dark:hover:text-gray-100`}
    >
      <ArrowCircleLeftOutlined></ArrowCircleLeftOutlined>
    </ButtonBase>
  );
};
