import { CommonMetricField, MetricField, SellerMetricField, stringToSellerMetricFieldString } from '@/components/metrics/types/MetricField';
import { TimelineDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { ProductsTimelineDTO } from '@/modules/products/api/products-contracts';

export class TimelineModel {
  public xAxisData: string[];
  public yAxisData: {
    key: MetricField;
    values: number[];
  }[];

  constructor(args: TimelineCreateArguments) {
    this.xAxisData = args.xAxisData;
    this.yAxisData = args.yAxisData;
  }

  public static fromResponse(response: TimelineDTO): TimelineModel {
    return new TimelineModel({
      xAxisData: response.x_axis,
      yAxisData: Object.keys(response.y_axis).map((key) => {
        return {
          key: key as CommonMetricField,
          values: response.y_axis[key as CommonMetricField],
        };
      }),
    });
  }

  public static fromProductsResponse(response: ProductsTimelineDTO): TimelineModel {
    const xAxisData = response.x_axis;
    const yAxisData = [];

    for (const key of Object.keys(response.y_axis)) {
      if (!Object.values(CommonMetricField).includes(key as CommonMetricField)) {
        continue;
      }
      yAxisData.push({ key: key as CommonMetricField, values: response.y_axis[key as CommonMetricField] });
    }

    for (const key of Object.keys(response.y_axis.seller_metrics)) {
      const sellerMetricKey = stringToSellerMetricFieldString(key);
      if (!Object.values(SellerMetricField).includes(sellerMetricKey as SellerMetricField)) {
        continue;
      }
      yAxisData.push({ key: sellerMetricKey as SellerMetricField, values: response.y_axis.seller_metrics[key as SellerMetricField] });
    }

    return new TimelineModel({
      xAxisData,
      yAxisData,
    });
  }

  public static fromResponseArray(responses: TimelineDTO[]): TimelineModel[] {
    return responses.map((response) => TimelineModel.fromResponse(response));
  }
}

interface TimelineCreateArguments {
  xAxisData: string[];
  yAxisData: {
    key: MetricField;
    values: number[];
  }[];
}
