import { FunctionComponent, memo, useMemo, useRef, useState } from 'react';
import { IDashboardWidget } from '../../../types/IDashboardWidget';
import { BarGraphWidgetConfiguration } from './BarGraphWidgetConfiguration';

import DashboardWidgetConfigurationButton from '../../dashboard-widget/DashboardWidgetConfigurationButton';
import DashboardWidgetConfigurationDrawer from '../../dashboard-widget/DashboardWidgetConfigurationDrawer';
import DashboardWidgetContent from '../../dashboard-widget/DashboardWidgetContent';
import DashboardWidgetProvider from '../../dashboard-widget/DashboardWidgetContextProvider';
import BarGraphWidgetConfigurationForm from './BarGraphWidgetConfigurationForm';

import { ReactECharts } from '@/components/chart/ReactEcharts';
import { METRIC_COLORS, getConfigForMetric } from '@/components/metrics/MetricsConfig';
import { CommonMetricField } from '@/components/metrics/types/MetricField';
import useFormatting from '@/hooks/useFormatting';
import { BarGraphWidgetDataDTO, WidgetDataDTO } from '@/modules/dashboards/api/dashboard/data/dashboard-data.contracts';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';
import { isBaseWidgetConfigurationEqual } from '@/modules/dashboards/lib/areWidgetsEqual';
import Color from 'colorjs.io';
import {
  BarSeriesOption,
  ECElementEvent,
  ECharts,
  EChartsOption,
  TooltipComponentFormatterCallbackParams,
  TooltipComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { isArray, isEmpty } from 'lodash-es';
import DashboardWidgetFilterIndicator from '../../dashboard-widget/DashboardWidgetFilterIndicator';
import DashboardWidgetTitle from '../../dashboard-widget/DashboardWidgetTitle';
import DashboardWidgetTopBar from '../../dashboard-widget/DashboardWidgetTopBar';
import { EntityType } from '../../forms/EntityTypeSelect';

interface BarGraphWidgetProps extends IDashboardWidget<BarGraphWidgetConfiguration> {
  data?: WidgetDataDTO;
  isFetchingDataForWidget?: boolean;
  configurationId: string;
}

const BarGraphWidget: FunctionComponent<BarGraphWidgetProps> = ({ configuration, id, data, isFetchingDataForWidget }) => {
  const hoveredSeries = useRef<string | undefined>();
  const hoveredPoint = useRef<number | undefined>();

  const dashboardCurrency = useDashboardContextValue((context) => context.dashboard?.dto.currency);

  const { getLongFormatterForMetricField, getShortFormatterForMetricField } = useFormatting();

  function getEchartsOptions(data: BarGraphWidgetDataDTO): EChartsOption {
    const series: (BarSeriesOption | BarSeriesOption)[] = [];
    const yAxis: YAXisComponentOption[] = [];

    const xAxisData = data.xAxis;
    const yAxisData = data.yAxis;

    if (!data || !xAxisData || !yAxisData) {
      return {};
    }

    configuration.selectedMetrics.forEach((selectedMetric, index: number) => {
      const metricConfig = getConfigForMetric(selectedMetric);
      if (!metricConfig) {
        return;
      }

      const color = METRIC_COLORS[index];

      const barSeries: BarSeriesOption = {
        id: `${selectedMetric}-current`,
        name: metricConfig.title,
        type: 'bar',
        yAxisIndex: index,
        data: yAxisData.map((item) => item[selectedMetric][0]),
        color: new Color(color).lighten(0.1).toString(),
        label: {
          show: false,
        },
        emphasis: {
          disabled: true,
          focus: 'none',
        },
        barWidth: '15%',
        datasetId: 'current',
      };
      series.push(barSeries);
      const previousBarSeries: BarSeriesOption = {
        id: `${selectedMetric}-previous`,
        name: metricConfig.title + ' (previous)',
        type: 'bar',
        yAxisIndex: index,
        data: yAxisData.map((item) => item[selectedMetric][1]),
        color: new Color(color).darken(0.1).toString(),
        label: {
          show: false,
        },

        emphasis: {
          disabled: true,
          focus: 'none',
        },
        // silent: true,
        barWidth: '5%',
        barGap: '260%',
        xAxisIndex: 1,
        datasetId: 'previous',
      };
      series.push(previousBarSeries);

      yAxis.push({
        name: metricConfig?.title,
        type: 'value',
        position: index === 0 ? 'left' : 'right',
        offset: index === 0 ? 0 : configuration.selectedMetrics.length === 2 ? 0 : 60 * (index - 1), // Conditional offset based on number of Y-axes
        nameTextStyle: {
          fontWeight: 'bold', // Make axis name bold
          fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
        },
        axisLabel: {
          fontWeight: 'bold', // Make axis labels bold
          fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
          formatter: (value: number) =>
            getShortFormatterForMetricField(selectedMetric)(value, {
              customCurrencyCode: dashboardCurrency,
            }),
        },
        axisPointer: {
          // Configure hover formatting for the Y-axis
          label: {
            show: true,
            formatter: (params) =>
              getShortFormatterForMetricField(selectedMetric)(params.value as number, {
                customCurrencyCode: dashboardCurrency,
              }),
          },
        },
        axisLine: {
          show: true,

          lineStyle: {
            color: color,
          },
        },
      });
    });

    const options: EChartsOption = {
      xAxis: [
        {
          type: 'category',
          data: xAxisData,
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            fontWeight: 'bolder',
            fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
          },
          splitLine: {
            show: false, // Hide vertical bars
          },
          axisPointer: {
            // Configure hover formatting for the X-axis
            label: {
              show: false,
            },
          },
        },
        {
          show: false,
          type: 'category',
          data: xAxisData,
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true,
            show: false,
          },
          axisPointer: {
            // Configure hover formatting for the X-axis
            label: {
              show: false,
            },
          },
          axisLabel: {
            show: false,
            opacity: 0,
            fontWeight: 'bolder',
            fontFamily: "-apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
          },
          splitLine: {
            show: false, // Hide vertical bars
          },
        },
      ],
      yAxis: yAxis,
      series: series,
      grid: {
        left: 65,
        right: 48 * configuration.selectedMetrics.length,
        top: 45,
        bottom: 45,
      },
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        axisPointer: {
          type: 'cross',
        },
        formatter: function (params: TooltipComponentFormatterCallbackParams): string {
          if (isArray(params)) {
            let result = `<b>${params[0].name}</b>` + '<br/>'; // Add the x-axis series name (date)

            const previousData = params.filter((p) => p.seriesId?.includes('previous'));

            params
              .filter((p) => p.seriesId?.includes('current'))
              .forEach((param, index) => {
                const formattedValue = getLongFormatterForMetricField(param.seriesId?.split('-')[0] as CommonMetricField)(
                  param.value as number,
                  {
                    customCurrencyCode: dashboardCurrency,
                  },
                );
                if (param.seriesName === hoveredSeries.current) {
                  result += `<b>${param.marker}${param.seriesName}: ${formattedValue}</b><br/>`;
                } else {
                  result += `${param.marker}${param.seriesName}: ${formattedValue}<br/>`;
                }

                const previousParam = previousData[index];
                if (previousParam) {
                  const previousFormattedValue = getLongFormatterForMetricField(param.seriesId?.split('-')[0] as CommonMetricField)(
                    previousParam.value as number,
                    {
                      customCurrencyCode: dashboardCurrency,
                    },
                  );
                  if (previousParam.seriesName === hoveredSeries.current) {
                    result += `<b>${previousParam.marker}${previousParam.seriesName}: ${previousFormattedValue}</b><br/>`;
                  } else {
                    result += `${previousParam.marker}${previousParam.seriesName}: ${previousFormattedValue}<br/>`;
                  }
                }
              });
            return result;
          } else {
            return '';
          }
        },
      } as TooltipComponentOption,
    };

    return options;
  }

  const echartsOptions = useMemo(() => {
    if (data) {
      return getEchartsOptions(data as BarGraphWidgetDataDTO);
    }
    return {};
  }, [data, configuration]);

  function onChartReady(instance: ECharts) {
    instance.on('mouseover', (params: ECElementEvent) => {
      hoveredSeries.current = params.seriesName;
      hoveredPoint.current = params.dataIndex;
    });
    instance.on('mouseout', () => {
      hoveredSeries.current = undefined;
      hoveredPoint.current = undefined;
    });
  }

  const hasFilters =
    (!isEmpty(configuration.campaignFilters) &&
      (configuration.entityType === EntityType.CAMPAIGN || configuration.entityType === EntityType.PROFILE)) ||
    (!isEmpty(configuration.targetsFilters) && configuration.entityType === EntityType.TARGET);

  const [isMouseOnWidget, setIsMouseOnWidget] = useState(false);

  return (
    <DashboardWidgetProvider widgetId={id ?? 'unknown'} isLoading={isFetchingDataForWidget} isConfigured={configuration?.isConfigured}>
      <DashboardWidgetContent onMouseChange={setIsMouseOnWidget}>
        <DashboardWidgetTopBar>
          <DashboardWidgetTitle>{configuration.title}</DashboardWidgetTitle>
          <DashboardWidgetFilterIndicator visible={hasFilters && !isMouseOnWidget} widgetId={id} />
        </DashboardWidgetTopBar>
        <DashboardWidgetConfigurationButton isMouseOnWidget={isMouseOnWidget}></DashboardWidgetConfigurationButton>

        <div className="h-full w-full flex-1 flex  ">
          {data && configuration.selectedMetrics.length > 0 && (
            <ReactECharts
              settings={{
                notMerge: true,
              }}
              onChartReady={(echarts) => {
                onChartReady(echarts);
              }}
              option={echartsOptions}
            />
          )}
        </div>
      </DashboardWidgetContent>

      <DashboardWidgetConfigurationDrawer widgetName={configuration.title}>
        <BarGraphWidgetConfigurationForm configuration={configuration} id={id} />
      </DashboardWidgetConfigurationDrawer>
    </DashboardWidgetProvider>
  );
};

export default memo(BarGraphWidget, isBaseWidgetConfigurationEqual);
