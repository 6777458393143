import { IDashboardWidgetCatalogItem } from '../../types/IDashboardWidgetCatalogItem';
import { BAR_GRAPH_WIDGET_CATALOG_ITEM } from './bar-graph-widget/BarGraphWidgetCatalogItem';
import { COMBO_GRAPH_WIDGET_CATALOG_ITEM } from './combo-graph-widget/ComboGraphWidgetCatalogItem';
import { HORIZONTAL_DIVIDER_WIDGET_CATALOG_ITEM } from './horizontal-divider-widget/HorizontalDividerWidgetCatalogItem';
import { LINE_GRAPH_WIDGET_CATALOG_ITEM } from './line-graph-widget/LineGraphWidgetCatalogItem';
import { METRIC_WIDGET_CATALOG_ITEM } from './metric-widget/MetricWidgetCatalogItem';
import { PIE_GRAPH_WIDGET_CATALOG_ITEM } from './pie-graph-widget/PieGraphWidgetCatalogItem';
import { TABLE_WIDGET_CATALOG_ITEM } from './table-widget/TableWidgetCatalogItem';
import { TEXT_WIDGET_CATALOG_ITEM } from './text-widget/TextWidgetCatalogItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WIDGET_CATALOG: IDashboardWidgetCatalogItem<any>[] = [
  COMBO_GRAPH_WIDGET_CATALOG_ITEM,
  METRIC_WIDGET_CATALOG_ITEM,
  TABLE_WIDGET_CATALOG_ITEM,
  BAR_GRAPH_WIDGET_CATALOG_ITEM,
  PIE_GRAPH_WIDGET_CATALOG_ITEM,
  HORIZONTAL_DIVIDER_WIDGET_CATALOG_ITEM,
  TEXT_WIDGET_CATALOG_ITEM,
  LINE_GRAPH_WIDGET_CATALOG_ITEM,
];
