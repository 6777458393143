import { DEFAULT_VISIBLE_METRICS, METRIC_COLORS, MetricColor, MetricSelectionColorIndex } from '@/components/metrics/MetricsConfig';
import { MetricField } from '@/components/metrics/types/MetricField';
import { UserSettingKey } from '@/modules/users';
import { useEffect, useState } from 'react';
import { useUserSetting } from './useUserSetting';

const useMetricsInContext = (settingKey: UserSettingKey) => {
  const { handleSettingStateChange: updateVisibleMetrics, settingState: visibleMetricsSetting } =
    useUserSetting<MetricSelectionColorIndex[]>(settingKey);

  // Legacy handling
  const { settingState: legacySelectedMetricsSetting } = useUserSetting<MetricField[]>(UserSettingKey.SELECTED_METRICS);
  const { settingState: legacySelectedMetricColorsSetting } = useUserSetting<MetricColor[]>(UserSettingKey.SELECTED_METRIC_COLORS);
  const { settingState: legacyVisibleMetricsSetting } = useUserSetting<MetricField[]>(UserSettingKey.VISIBLE_METRICS);

  function getSavedVisibleMetrics(): MetricSelectionColorIndex[] {
    if (visibleMetricsSetting) return visibleMetricsSetting;

    const convertedSettingFromLegacy = getLegacyMetricSetting();
    if (convertedSettingFromLegacy) return convertedSettingFromLegacy;

    return DEFAULT_VISIBLE_METRICS;
  }

  function getLegacyMetricSetting(): MetricSelectionColorIndex[] | null {
    if (!legacySelectedMetricsSetting || !legacySelectedMetricColorsSetting || !legacyVisibleMetricsSetting) {
      return null;
    }

    return legacyVisibleMetricsSetting.map((metric) => {
      function legacyColorToIndex(color: string): number {
        return METRIC_COLORS.indexOf(color);
      }

      const legacyColor = legacySelectedMetricColorsSetting.find((color) => color.key === metric)?.color;
      const colorIndex = legacyColor ? legacyColorToIndex(legacyColor) : -1;

      return {
        key: metric,
        isSelected: legacySelectedMetricsSetting.includes(metric),
        colorIndex: colorIndex,
      };
    });
  }

  const [visibleMetrics, setVisibleMetrics] = useState<MetricSelectionColorIndex[]>(() => getSavedVisibleMetrics());

  useEffect(() => {
    updateVisibleMetrics(visibleMetrics);
  }, [visibleMetrics]);
  return {
    visibleMetrics,
    setVisibleMetrics,
  };
};

export default useMetricsInContext;
