import { ColorModeContext } from '@/modules/application';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { CellValueChangedEvent, ColumnVisibleEvent, GridApi, GridReadyEvent, RowValueChangedEvent, SortDirection } from 'ag-grid-community';
import { ColDef, GridOptions, ProcessUnpinnedColumnsParams } from 'ag-grid-enterprise';
import { AgGridReact, CustomNoRowsOverlayProps } from 'ag-grid-react';
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { v4 } from 'uuid';
import LoadingBlock from '../feedback/LoadingBlock';
import { ColumnId } from './columns/columns.enum';
import DefaultHeaderRenderer from './headers/DefaultHeaderRenderer';

export const GRID_DEFAULT_SORTING_ORDER: SortDirection[] = ['desc', 'asc', null];

// eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-unused-vars
export interface AlGridContext<T = {}> {
  activeProfileCurrencyCode: CurrencyCode | undefined;
}

interface AlGridProps {
  colDefs: ColDef[];
  rowData?: unknown[];
  gridOptions?: GridOptions;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  isLoading?: boolean;
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
  onRowValueChanged?: (event: RowValueChangedEvent) => void;
  /**
   * If true, the grid will have no border radius on the top
   * @default false
   * */
  noTopBorderRadius?: boolean;
  /**
   * If true, the grid will have no borders at all
   * @default false
   * */
  noBorders?: boolean;
  fitToResizeEnabled?: boolean;
  /**
   * Add extra space after the last row so when scrolling to the end the action bar doesn't cover the last rows
   * */
  addExtraBottomPadding?: boolean;
}

export const DEFAULT_GRID_OPTIONS: GridOptions = {
  scrollbarWidth: 15,
  multiSortKey: 'ctrl',
  defaultColDef: {
    minWidth: 40,
    headerClass: 'text-only-header',
    resizable: true,
    sortable: true,
    cellClass: [],
    headerComponent: DefaultHeaderRenderer,
    menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    sortingOrder: GRID_DEFAULT_SORTING_ORDER,
  },
  alwaysShowVerticalScroll: true,
  alwaysShowHorizontalScroll: true,
  suppressHorizontalScroll: false,
  enableRangeSelection: true,
  stopEditingWhenCellsLoseFocus: true,
  animateRows: false,
  ///popupParent: document.body,
  localeText: {
    rowGroupColumnsEmptyMessage: 'Drag & drop column headers here to Group By',
  },
  reactiveCustomComponents: true,
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          // suppressColumnFilter: true,
          // suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  processUnpinnedColumns: (params: ProcessUnpinnedColumnsParams) => {
    const columns = params.api.getColumns() || []; // Fallback to empty array if null

    const leftPinned = columns.filter((col) => col.getPinned() === 'left');
    const rightPinned = columns.filter((col) => col.getPinned() === 'right');

    const columnsToUnpin = [...leftPinned.slice(-1), ...rightPinned.slice(0, 1)].filter((col) => col); // Filter out any null values

    // Return the array of columns to be unpinned
    return columnsToUnpin;
  },
  tooltipShowDelay: 500,
};

const AlGrid: FunctionComponent<AlGridProps> = ({
  colDefs,
  rowData,
  gridOptions,
  onGridReadyCallback,
  isLoading = false,
  onCellValueChanged,
  onRowValueChanged,
  noTopBorderRadius = false,
  fitToResizeEnabled = true,
  addExtraBottomPadding = false,
  noBorders = false,
}) => {
  const gridRef = useRef<AgGridReact | null>(null);
  const gridContextRef = useRef<AlGridContext | null>(null);
  const { mode } = useContext(ColorModeContext);
  const gridUuid = useRef(v4()).current;
  const { activeProfile } = useActiveTeamContext();

  if (gridOptions) {
    gridOptions.gridId = gridUuid;
    gridOptions.context = {
      ...gridOptions.context,
      activeProfileCurrencyCode: activeProfile?.currencyCode,
    } as AlGridContext;
  }

  useEffect(() => {
    if (!gridContextRef.current) return;

    gridContextRef.current.activeProfileCurrencyCode = activeProfile?.currencyCode;
  }, [activeProfile?.currencyCode]);

  const onGridReady = (params: GridReadyEvent) => {
    gridContextRef.current = params.context;

    if (fitToResizeEnabled) {
      params.api.sizeColumnsToFit();
    }

    if (onGridReadyCallback) {
      onGridReadyCallback(params);
    }
  };

  useEffect(() => {
    if (rowData?.length === 0) {
      gridRef.current?.api?.showNoRowsOverlay();
    } else {
      if (!isLoading) {
        handleColumnVisibility(gridRef.current?.api);
      }
    }
  }, [isLoading, rowData]);

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingOverlayComponent;
  }, []);

  const handleColumnVisibility = (api?: GridApi) => {
    if (!api || api.isDestroyed()) {
      return;
    }
    const visibleColumnsWithoutCheckbox = api.getAllDisplayedColumns()?.filter((column) => column.getColId() !== ColumnId.CHECKBOX);
    const displayedRowCount = api.getDisplayedRowCount();

    if ((visibleColumnsWithoutCheckbox && visibleColumnsWithoutCheckbox.length === 0) || displayedRowCount == 0) {
      api.showNoRowsOverlay();
    } else {
      api.hideOverlay();
    }
  };

  const onColumnVisible = useCallback((params: ColumnVisibleEvent) => {
    // When gridOptions also has onColumnVisible then call it, making this function a wrapper
    if (gridOptions?.onColumnVisible) {
      gridOptions.onColumnVisible(params);
    }

    handleColumnVisibility(params.api);
  }, []);

  const CustomNoRowsOverlay: FunctionComponent<
    CustomNoRowsOverlayProps & {
      noRowsComponentFunc: () => React.ReactNode;
    }
  > = (props) => {
    return <div className="ag-overlay-no-rows-center">{props.noRowsComponentFunc()}</div>;
  };

  const noRowsOverlayComponentParams = {
    noRowsComponentFunc: () => {
      if (
        gridRef.current?.api &&
        gridRef.current?.api.getAllDisplayedColumns().filter((column) => column.getColId() !== ColumnId.CHECKBOX).length == 0
      ) {
        return (
          <div className="p-2.5 text-center">
            No columns selected.
            <br />
            <br />
            Toggle column visibility from the “Columns” panel on the right
          </div>
        );
      }

      return <span>No Rows to Show</span>;
    },
  };

  const dynamicGridClasses = useMemo(() => {
    let classes = mode === 'dark' ? 'ag-theme-quartz-dark' : 'ag-theme-quartz';
    if (noBorders) {
      classes = classes.concat(' no-borders');
    }
    if (noTopBorderRadius) {
      classes = classes.concat(' bottom-rounded');
    }
    if (addExtraBottomPadding) {
      classes = classes.concat(' extra-padding');
    }
    return classes;
  }, [mode, noBorders, noTopBorderRadius, addExtraBottomPadding]);

  return (
    <div className="flex h-full flex-1 flex-shrink-0 flex-grow flex-row">
      <div className={`${dynamicGridClasses} flex-grow overflow-hidden relative`}>
        <AgGridReact
          ref={gridRef}
          key={gridUuid}
          gridId={gridUuid}
          loading={isLoading}
          columnDefs={colDefs}
          rowData={rowData}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          onCellValueChanged={onCellValueChanged}
          onRowValueChanged={onRowValueChanged}
          loadingOverlayComponent={loadingOverlayComponent}
          noRowsOverlayComponent={CustomNoRowsOverlay}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          onColumnVisible={onColumnVisible}
        />
      </div>
    </div>
  );
};

function CustomLoadingOverlayComponent() {
  return (
    // Added padding so wouldn't cover headers
    <div className="pt-10">
      <LoadingBlock />
    </div>
  );
}

export default AlGrid;
