import { DataGroupColumnId } from '@/modules/data-groups/models/DataGroup';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { CountryCode } from '@/modules/users/types/CountryCode';
import { ColDef } from 'ag-grid-enterprise';
import { SortByUnit } from '../buttons/SortByToggleButton';
import { StartEndDatePair } from '../filter-builder/hooks/useFilters';
import { MetricAggregates } from '../metrics/types/MetricField';
import { ColumnId } from './columns/columns.enum';

export interface AlColDef<T> extends ColDef<T> {
  colId: ColumnId | DataGroupColumnId;
  headerStyle?: React.CSSProperties;
  children?: AlColDef<T>[];
}

// TODO: rename to ExternalDataSourcesContext and move logic in table to hook, don't extend toggles, but make a union type
export interface ExpandedGridContext extends GridTogglesContext {
  metricColumnAggregates: MetricAggregates | undefined;
  campaignGroupIdToCampaignGroupMap?: Record<number, CampaignGroupModel> | undefined;
  isComparisonDataMissing: boolean;
}

export interface GridTogglesContext {
  comparisonUnit?: ComparisonUnit;
  // sortByUnit?: SortByUnit; // Not in context because handled in another way because grid doesn't yet have params access in comparator
  formatterType?: FormatterType;
}

export interface GridToggles {
  comparisonUnit?: ComparisonUnit;
  sortByUnit?: SortByUnit;
  formatterType?: FormatterType;
}

export type ComparisonUnit = 'nominal' | 'percent' | 'hidden';

export enum FormatterType {
  SHORT = 'short',
  LONG = 'long',
}

export interface WithFiltersGridContext {
  filterCurrentStartEndDate: StartEndDatePair | undefined;
}

export interface WithActiveProfileGridContext {
  countryCode: CountryCode | undefined;
}
